import React, { useState, useEffect, useRef, useCallback } from "react";
import { ToastContainer, toast } from 'react-toastify'
import "react-toastify/dist/ReactToastify.css";
import { Title } from 'utils/Title'
import PageMainHeader from 'components/PageMainHeader'
import { AuthContext } from "contexts/AuthContext"
import { SettingsContext } from 'contexts/SettingsContext'
import Banner from '@leafygreen-ui/banner'
import moment from "moment";
import socketIOClient from "socket.io-client";
import _ from "lodash";
import { createShippingLabel, getShipStationOrder, getWarehouse } from "utils/shipstation";
import { updatePackingItem, updateManyPackingItem, getPackingItemsByOrder } from "utils/packingItems";
import { getLocalOrder, updateOrder } from "utils/orders";
import { ModalPreloader } from "utils/Preloader";
import { errorHandler } from "utils/errorHandler";
import { PackingSlipTemplate } from "utils/PackingSlipTemplate";
import { useQueryClient, useMutation } from "react-query";
import { axiosInstance as axios } from "configs/axiosConfig";
import { getS3presign } from "utils/awsS3";
import { ShipmentDetail } from "./ShipmentDetail";
import PackingItemsUnderOrderId from "./PackingItemsUnderOrderId";
import { FaPassport } from "react-icons/fa";
import Button from "@leafygreen-ui/button";
import Checkbox from "@leafygreen-ui/checkbox";
import { GridLoader } from "react-spinners";
import { printOrderItemLabel } from "utils/printOrderItemLabel";

const title="Shipment"
const socket = socketIOClient(process.env.REACT_APP_SOCKET_ENDPOINT);

const updateBin = async (data) => {
  // console.log("data", data);
  axios.post(`/bins/update`, data)
    .then((res) => {
      Promise.resolve(res.data);
    })
    .catch((error) => Promise.reject(error));
};

const updateCheckoutQty = (
  { id, orderId, checkedOutQty, totalCheckedOutQty }) => {
    return new Promise((resolve, reject) => {
    updatePackingItem({
      condition: { id },
      update: { checkedOutQty },
    })
      .then(() => {
        console.log("** updated checkedOutQty on packing items");
        return updateOrder({
          condition: { orderId },
          update: { checkedOutQty: totalCheckedOutQty },
        })
        .then(async () => {
          console.log(
            "** updated checkedOutQty on orders"
          );
          resolve(true);
        })
        .catch((error) => {
          console.error("updateCheckoutQty error", error);
          reject(error);
        });
      })
  })
};

const createShipLabel = (order, orderNumber, testLabel) => {
    return new Promise(async (resolve, reject) => {
    try {
      const result = await createShippingLabel(
        order,
        orderNumber,
        testLabel
      );
      // console.log("createShipLabelHandler result", result);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

const createShipLabelErrHandler = (error) => {
    return new Promise((resolve, reject) => {
    let errorMsg;
    try {
      if (_.has(error.response.data, "ExceptionMessage")) {
        const exceptionMessage = error.response.data.ExceptionMessage;
        console.log("exceptionMessage", exceptionMessage);
        resolve({ message: exceptionMessage });
      } else {
        errorMsg = errorHandler(error);
        console.log("errorMsg", errorMsg);
        resolve({message: errorMsg})
      }
    } catch (error) {
      console.log("catch error", error);
      errorMsg = errorHandler(error);
      console.log("errorMsg", errorMsg);
      reject({ message: errorMsg });
    }
  })
}

const addFund = (carrierCode) => {
    return new Promise((resolve, reject) => {
    axios.post(`/ship-station/add-funds`, { carrierCode })
      .then((result) => {
        console.log("add funds result", result);
        resolve(result);
      })
      .catch((error) => {
        console.log("add funds error", error);
        reject(error)
      });
  })
}

export default function Shipment() {
  const { settings } = React.useContext(SettingsContext);
  const { user } = React.useContext(AuthContext);
  const queryClient = useQueryClient();
  // console.log(">> testLabel", settings?.testLabel);
  // console.log(">> printPackingSlip", settings?.printPackingSlip);
  // console.log(">> markShipped", settings?.markShipped);
  // console.log("- user", user);

  const [bin, setBin] = useState(null);
  const [loading, setLoading] = useState(false);
  const [shipProcessResult, setShipProcessResult] = useState(null);
  const [item, setItem] = useState(null);
  const [shipstationOrder, setShipstationOrder] = useState(null);
  const [localOrder, setLocalOrder] = useState(null);
  const [packingItemsUnderOrderId, setPackingItemsUnderOrderId] = useState(null);
  const [msg, setMsg] = useState(null);
  const [isDisabledCheckoutBtn, setIsDisabledCheckoutBtn] = useState(true);
  // const isFGRef = useRef({ status: false, areaCode: null});
  let [_checkOutQty, setCheckoutQty] = useState("");
  let [isCheckOutQtyManualEdited, setIsCheckOutQtyManualEdited] = useState(false);
  const [modalPreloaderIsOpen, setModalPreloaderIsOpen] = useState(false);
  const [shipFrom, setShipFrom] = useState(null);
  const isPackageFull = useRef(false);
  const checkedOutQtyRef = useRef();
  const checkoutBtnRef = useRef();
  const markShippedRef = useRef(false);
  const awaitItemsRef = useRef([]);
  const [graphicLinks, setGraphicLinks] = useState([])
  const [onErrorImg, setOnErrorImg] = useState(false)
  const [selectedPosition, setSelectedPosition] = useState(null)
  const [loadedArtwork, setLoadedArtwork] = useState(false)
  let sourceOrderRef = useRef('manual')
  let orderIdRef = useRef(null)
  let bannerVariantRef = useRef('info')

  const fetchPackingItemsByOrder = useMutation(async ({ orderId, code, orderItemId }) => {
    console.log("== fetchPackingItemsByOrder init");
    // console.log("orderId: ", orderId);
    // console.log("code: ", code);
    return await getPackingItemsByOrder({ orderId, isActive: true });
  }, {
    onError: (err) => {
      console.log("- getPackingItemsByOrder error", err);
      let retval = errorHandler(err);
      console.log("- retval error", retval);
      bannerVariantRef.current = 'danger';
      setMsg(retval);
    },
    onSuccess: (orderItems, variables) => {
      // console.log("- onSuccess variables: ", variables);
      console.log("- onSuccess orderItems: ", orderItems);
      if (!Boolean(orderItems.length)) {
        bannerVariantRef.current = 'danger';
        setMsg("Active order line item(s) not found. Check the order details.");
        return;
      };
      queryClient.setQueryData("packingItems", orderItems);
      setPackingItemsUnderOrderId(orderItems);

      const { code, orderItemId } = variables;
      console.log("- code: ", code);
      console.log("- orderItemId: ", orderItemId);

      let packingItem = orderItems.find((item) => {
        if(orderItemId) {
          return (item.sku === code && item.orderItemId === orderItemId)
        }
        return item.sku === code
      });

      // console.log("- packingItem", packingItem);
      setItem(packingItem);
      packingItem?._order?.bin && setBin(packingItem._order.bin);
      // console.log("checkedOutQtyRef", checkedOutQtyRef);
      if (checkedOutQtyRef?.current) {
        checkedOutQtyRef.current.value = packingItem?.checkedOutQty ? packingItem.checkedOutQty : 0;
      }
      awaitItemsRef.current = orderItems.filter(
        (item) => {
          // console.log(packingItem.orderItemId, item.sku, item.orderItemId, item.quantity)
          if(item?.orderItemId) {
            return item.orderItemId !== packingItem.orderItemId && item.quantity > 0
          }
          return item.sku !== packingItem.sku && item.quantity > 0
        }
      );
      console.log("* awaitItemsRef", awaitItemsRef);
      // graphic printed check-off
      // console.log("- is graphic printed length: ", packingItem?.printed?.length);
      console.log("- is multiple graphics", packingItem?._item?._graphics.length > 1);
      // console.log("- isFGRef", isFGRef);
      if (_.has(packingItem?._item, "_graphics") && packingItem?._item?._graphics.length > 1) {
        // console.log("- grahpics", packingItem?._item?._graphics);
        // console.log("- printed", packingItem?.printed);
        if (packingItem?._item?._graphics?.length > (packingItem?.printed ? packingItem.printed.length : 0)) {
          toast.error("Make sure all graphics are printed", {
            position: "bottom-right",
            autoClose: 2000
          });
        }
      }
      if (packingItem?.quantity <= packingItem?.checkedOutQty) {
        console.log("** checkedOutQty is greater or equal order qty");
        bannerVariantRef.current = 'danger';
        setMsg("Already checked out!");
        setIsDisabledCheckoutBtn(true);
        return;
      }
      //set isPackageFull
      isShipmentFull(packingItem, orderItems);
    }
  })

  const fetchLocalOrder = useMutation(
    async ({ orderId }) => {
      console.log("* fetchLocalOrder init");
      return await getLocalOrder(orderId);
    },
    {
      onError: (err) => {
        console.log("- fetchLocalOrder error", err);
        const retVal = errorHandler(err)
        console.log('- retVal', retVal)
        bannerVariantRef.current = 'danger';
        setMsg(retVal);
      },
      onSuccess: (result) => {
        console.log("- fetchLocalOrder result: ", result);
        setLocalOrder(result);
        if(result?.source) {
          sourceOrderRef.current = result.source
        } else {
          sourceOrderRef.current = 'shipstation'
        }

        if(result?.checkedOutQty < result?.quantity) {
          isDisabledCheckoutBtn && setIsDisabledCheckoutBtn(false);
        }
        
      },
    }
  );

  const fetchShipStationOrder = useMutation(async ({ orderId }) => {
    setLoading(true);
    console.log("==fetchShipStationOrder init");
    return await getShipStationOrder(orderId);
  }, {
    onError: (err) => {
      console.log("- getShipStationOrder error", err);
      let retval = errorHandler(err);
      console.log("- retval: ", retval);
      bannerVariantRef.current = 'danger'
      setMsg(retval);
      setLoading(false);
    },
    onSuccess: (ssOrder) => {
      // console.log("onSuccess ssOrder: ", ssOrder);
      setShipstationOrder(ssOrder);
      setLoading(false);
      if (!markShippedRef.current) {
        if (_.isNil(ssOrder.carrierCode) || _.isNil(ssOrder.serviceCode)) {
          toast.error("Service or Carrier code not found", {
            position: "bottom-right",
            autoClose: 2000,
          });
          return;
        }
      }

      isDisabledCheckoutBtn && setIsDisabledCheckoutBtn(false);

      if (ssOrder.orderStatus === "shipped") {
        setIsDisabledCheckoutBtn(true);
        bannerVariantRef.current = 'danger'
        setMsg("Already shipped order.");
        return;
      }

    }
  })

  // on packing scan event
  const onPackingScanned = useCallback(async () => {
    socket.on("on-packing-scanned", async (data, hostname) => {
    console.log("* on-packing-scanned", data, hostname);
    let dataArr = [];
    let delimiter = ";";
    if (_.includes(data, "*")) delimiter = "*";
    // console.log("- delimiter: ", delimiter);
    dataArr = data.split(delimiter);
    console.log("- dataArr", dataArr);
    const code = dataArr[0];
    const orderId = parseInt(dataArr[1]);
    const orderItemId = dataArr[2];
    console.log("- sku(code)", code);
    console.log("- orderId", orderId);
    console.log("- orderItemId", orderItemId, typeof orderItemId);
    
    orderIdRef.current = null;
    isPackageFull.current = false;
    sourceOrderRef.current = 'manual';

    if(orderId) orderIdRef.current = orderId
    console.log("- checkedOutQtyRef?.current?.value: ", checkedOutQtyRef?.current?.value);
    if (checkedOutQtyRef?.current?.value !== 0) {
      checkedOutQtyRef.current.value = 0;
    }

    //reset states
    setShipProcessResult(null);
    bannerVariantRef.current = 'info'
    setMsg(null);
    !isDisabledCheckoutBtn && setIsDisabledCheckoutBtn(true);
    setIsCheckOutQtyManualEdited(false);
    setCheckoutQty("");
    setBin(null);
    setItem(null);
    Boolean(graphicLinks.length) && setGraphicLinks([])
    setOnErrorImg(false);

    await fetchPackingItemsByOrder.mutate({ orderId, code, orderItemId });
    await fetchLocalOrder.mutate({orderId})

    });
    // eslint-disable-next-line
  }, []);

  const isShipmentFull = (item, packingItemsUnderOrderId) => {
    console.log("** isShipmentFull init");
    let isFull = false;
    // const orderNumber = item.orderNumber;
    const awaitingCheckoutItems = packingItemsUnderOrderId.filter(
      (pi) => (pi?.checkedOutQty !== pi?.quantity)
    );
    // console.log("awaitingCheckoutItems: ", awaitingCheckoutItems);
    let totalOrderQty = _.sumBy(packingItemsUnderOrderId, "quantity");
    console.log(" - totalOrderQty", totalOrderQty);
    if (awaitingCheckoutItems.length === 1) {
      let lastItem = awaitingCheckoutItems[0];
      // console.log("lastItem", lastItem);
      console.log(
        "? lastItem(lastItem.quantity - lastItem.checkedOutQty = 1)",
        (lastItem?.quantity - lastItem?.checkedOutQty) === 1
      );
      if (lastItem?.quantity - lastItem?.checkedOutQty === 1) {
        isFull = true;
        bannerVariantRef.current = 'info'
        setTimeout(() => {
          item._order?.bin && setMsg(`${item._order.bin} FULL:: ${totalOrderQty} pcs total`);
        }, 500);
      } else {
        if (isCheckOutQtyManualEdited) {
          console.log(
            "- checkedOutQtyRef.current.value in isShipmentFull",
            checkedOutQtyRef.current.value,
            typeof checkedOutQtyRef.current.value
          );
          if (lastItem.quantity === parseInt(checkedOutQtyRef.current.value)) {
            isFull = true;
            bannerVariantRef.current = 'info'
            setTimeout(() => {
              item._order?.bin &&
                setMsg(`${item._order.bin} FULL:: ${totalOrderQty} pcs total`);
            }, 500);
          }
        }
      }
    }
    console.log("** isFull result", isFull);
    if (isFull) {
      isPackageFull.current = true;
    }
  }

  const handleCheckout = async () => {
    console.log("** handleCheckoutQty init");
    console.log(" - selected packingItem: ", item);
    console.log(" - packingItemsUnderOrderId: ", packingItemsUnderOrderId);
    console.log(" - shipstationOrder", shipstationOrder);
    let checkedOutQty;
    let totalCheckedOutQty;

    if (item && packingItemsUnderOrderId) {
      setModalPreloaderIsOpen(true);
      const { orderId, orderNumber, _id } = item;
      let carrierCode, serviceCode, confirmation, weight;

      if(shipstationOrder) {
        carrierCode = shipstationOrder.carrierCode
        serviceCode = shipstationOrder.serviceCode
        confirmation = shipstationOrder.confirmation
        weight = shipstationOrder.weight
      }
      
      // console.log(" - orderId", orderId);
      // console.log(" - orderNumber", orderNumber);
      // console.log(" - _id", _id);
      // console.log(
      //   " - checkedOutQtyRef value",
      //   checkedOutQtyRef?.current?.value
      // );
      // console.log(
      //   " - isCheckOutQtyManualEdited: ",
      //   isCheckOutQtyManualEdited
      // );
      // console.log(
      //   `- checkedOutQty: ${checkedOutQty}, item.quantity: ${item.quantity}, checkedOutQtyRef value: ${checkedOutQtyRef?.current?.value}`
      // );
      if (isCheckOutQtyManualEdited) {
        checkedOutQty = parseInt(checkedOutQtyRef?.current?.value);
      } else {
        checkedOutQty = parseInt(item?.checkedOutQty);

        if (checkedOutQty === item.quantity) {
          toast.info('Already checked-out', {
            position: 'bottom-right',
            onClose: () => setIsDisabledCheckoutBtn(true)
          })
          setModalPreloaderIsOpen(false);
          return
        }
        checkedOutQty = parseInt(item?.checkedOutQty) + 1;
      }
      console.log(
        `- checkedOutQty: ${checkedOutQty}, \r\n item.quantity: ${item?.quantity}, \r\n checkedOutQtyRef value: ${checkedOutQtyRef.current.value}`
      );
      if (checkedOutQty > item?.quantity) checkedOutQty = item?.quantity;
      item.checkedOutQty = checkedOutQty;
      totalCheckedOutQty = _.sumBy(packingItemsUnderOrderId, "checkedOutQty");
      console.log("-- totalCheckedOutQty", totalCheckedOutQty);
      console.log(" * isPackageFull in handleCheckoutQty", isPackageFull);
      // if (Boolean(awaitingCheckoutItems.length)) {
      if (!isPackageFull.current) {
        await updateCheckoutQty({
          id: item._id,
          orderId,
          checkedOutQty,
          totalCheckedOutQty,
        })
          .then(() => {
            checkedOutQtyRef.current.value = checkedOutQty;
            setIsDisabledCheckoutBtn(true);
            bannerVariantRef.current = 'info'
            setMsg("Checked-out, waiting next scanning..");
            setModalPreloaderIsOpen(false);
          });
      } else {
        let confirm = true;
        if (Boolean(awaitItemsRef.current.length)) {
          // console.log(" ** when multiple items in an order then getting a confirmation to create a shipping label")
          confirm = window.confirm(
            "Package is full!, Confirm to create a shipping label?"
          );
          // console.log("confirm to creating a shipping label", confirm);
          if (confirm) {
            handleCreatShipment({
              carrierCode,
              serviceCode,
              confirmation,
              weight,
              orderId,
              orderNumber,
              _id,
              checkedOutQty,
              totalCheckedOutQty,
            });
          } else {
            // console.log("- canceled creating shipment label");
            setModalPreloaderIsOpen(false);
            toast.error("Canceled creating a shipment label ", {
              position: "bottom-right",
            });
          }
        } else {
          if(item?.quantity > 1) {
            confirm = window.confirm(
              "Package is full!, Confirm to create a shipping label?"
            );
          }
          // console.log("confirm to creating a shipping label", confirm);
          if (confirm) {
            handleCreatShipment({
              carrierCode,
              serviceCode,
              confirmation,
              weight,
              orderId,
              orderNumber,
              _id,
              checkedOutQty,
              totalCheckedOutQty
            });
          } else {
            // console.log("- canceled creating shipment label");
            setModalPreloaderIsOpen(false);
            toast.error("Canceled creating a shipment label ", {
              position: "bottom-right"
            });
          }
        }
      }
    } else {
      bannerVariantRef.current = 'danger'
      setMsg("Item/Packing Items not found.");
      setIsDisabledCheckoutBtn(true);
    }
  };

  const handleCreatShipment = async (
    { carrierCode,
      serviceCode,
      confirmation,
      weight,
      orderId,
      orderNumber,
      _id,
      checkedOutQty,
      totalCheckedOutQty }
  ) => {
    console.log(" ** handleCreatShipment init");

    if (serviceCode && carrierCode) {
      const order = {
        orderId,
        carrierCode,
        serviceCode,
        confirmation,
        weight,
        shipDate: moment().format("YYYY-MM-DD HH:mm:ss"),
      };
      if (!_.isNil(shipstationOrder.dimensions)) order["dimensions"] = shipstationOrder.dimensions;
      await createShipLabel(order, orderNumber, settings?.testLabel)
        .then((result) => {
          // console.log("- createShipLabel result", result);
          const { labelData } = result;
          let shippingLabelName = `${orderNumber}.pdf`;
          socket.emit("on-shipping-label-print", shippingLabelName, labelData);
          return handleShipProcess(result, orderNumber);
        })
        .then(async () => {
          setIsDisabledCheckoutBtn(true);
          await updateCheckoutQty({
            id: item._id,
            orderId,
            checkedOutQty,
            totalCheckedOutQty,
          })
            .then(() => {
              checkedOutQtyRef.current.value = checkedOutQty;
              if (!settings?.testLabel) {
                return finishShipment(orderId);
              }
              return Promise.resolve(true);
            })

            .then(() => {
              bannerVariantRef.current = 'info'
              setMsg("** Done creating a shipment");
              setModalPreloaderIsOpen(false);
              settings?.printPackingSlip && handlePrintPackingSlip();
              setTimeout(() => {
                setMsg("Checked-out, waiting next scanning..");
              }, 2000);
              return;
            });
        })
        .catch(async (error) => {
          console.error("* createShipLabel error", error);
          console.log(
            "- restore item's checkedOutQty as checkedOutQtyRef.current.value"
          );
          console.log(
            "- checkedOutQtyRef.current.value: ",
            checkedOutQtyRef.current.value
          );
          setItem((current) => ({
            ...current,
            checkedOutQty: parseInt(checkedOutQtyRef.current.value),
          }));

          let errResponse = await createShipLabelErrHandler(error);
          console.log("- errResponse", errResponse);

          if (errResponse?.message) {
            bannerVariantRef.current = 'danger'
            setMsg(errResponse.message);
            if (/insufficient/i.test(errResponse.message)) {
              let carrierCode = shipstationOrder?.carrierCode;
              await addFund(carrierCode).then((result) => {
                console.log("addFund result", result);
                setModalPreloaderIsOpen(false);
                toast.success("Added funds successfully.", {
                  position: "bottom-right",
                });
                return;
              });
            } else {
              setModalPreloaderIsOpen(false);
              return;
            }
          }
        });
    } else {
      if (markShippedRef.current) {
        const confirm = window.confirm("Mark an order as shipped?");
        // console.log("confirm", confirm);
        if (confirm) {
          await handleMarkShipped(
            _id,
            orderId,
            checkedOutQty,
            totalCheckedOutQty
          )
            .then(() => {
              setModalPreloaderIsOpen(false);
            })
            .catch((error) => {
              let errorMsg = errorHandler(error);
              console.log("errorMsg", errorMsg);
              bannerVariantRef.current = 'danger'
              setMsg(errorMsg);
              setModalPreloaderIsOpen(false);
            });
        } else {
          setModalPreloaderIsOpen(false);
        }
      }
      else
      if(sourceOrderRef.current==="manual") {
        console.log('- case of manual order')
        await updateCheckoutQty({
            id: _id,
            orderId,
            checkedOutQty,
            totalCheckedOutQty,
          })
            .then(() => {
              checkedOutQtyRef.current.value = checkedOutQty;
              if (!settings?.testLabel) {
                return finishShipment(orderId);
              }
              return Promise.resolve(true);
            })
            .then(() => {
              setMsg("** Done creating a shipment");
              setModalPreloaderIsOpen(false);
              settings?.printPackingSlip && handlePrintPackingSlip();
              setTimeout(() => {
                setMsg("Checked-out, waiting next scanning..");
              }, 2000);
              return;
            });

      }
      else {
        setModalPreloaderIsOpen(false);
        setMsg('Service and carrier code are required.')
      }
    }
  };

  const handleShipProcess = async (result, orderNumber) => {
    return new Promise((resolve) => {
      let shippingLabelName = `${orderNumber}.pdf`;
      let { shipmentCost, trackingNumber } = result;
      setShipProcessResult({
        shipmentCost,
        trackingNumber,
        shippingLabelName,
      });
      resolve(true);
    })
  };

  const onPrintingLabel = () => {
    socket.on("on-printed-shipping-label", async (data) => {
      // console.log("on-printed-shipping-label data", data);
      let m = data?.message

      m && toast.info(data.message, {
        position: "bottom-right",
        pauseOnFocusLoss: false
      })
    });
  };

  const handleMarkShipped = (_id, orderId, checkedOutQty, totalCheckedOutQty) => {
    return new Promise(async (resolve, reject) => {
      console.log("** handleMarkShipped init");
      console.log(_id, orderId, checkedOutQty, totalCheckedOutQty);
      const url = `/ship-station/markasshipped`;
      if (!settings?.testLabel) {
        await axios.post(url, { orderId, carrierCode: "usps" })
          .then(async (result) => {
            console.log("handleMarkShipped result", result.data);
            await updateCheckoutQty({
              id: _id,
              orderId,
              checkedOutQty,
              totalCheckedOutQty,
            })
              .then(() => {
                checkedOutQtyRef.current.value = checkedOutQty;
                return finishShipment(orderId);
              })
              // .then(() => {
              //   return updateInvTransactions(packingItemsUnderOrderId, user?.username);
              // })
              .then(() => {
                resolve(true);
              });
          })
          .catch((error) => {
            console.log("markShipped error", error);
            if (_.has(error.response, "data")) {
              toast.error(error.response.data.Message);
            }
            reject(false);
          });
      } else {
        resolve(true);
      }
    });
  };

  const finishShipment = (orderId) => {
    console.log("* finishShipment init");
    return new Promise((resolve, reject) => {

      if (!settings?.testLabel) {
        updateOrder({
          condition: { orderId },
          update: { orderStatus: "shipped", modifiedAt: new Date()},
        })
          .then(() => {
            return updateManyPackingItem({
              condition: { orderId },
              update: { $set: { isActive: false, modifiedAt: new Date(), shippedBy: user?.username } },
            });
          })
          .then(async () => {
            if (!_.isNil(item._order.bin)) {
              await updateBin({
                condition: { binCode: item._order.bin },
                update: { $set: { isActive: false, modifiedAt: new Date() } },
              });
              resolve(true);
              return;
            }
            resolve(true);
          })
          .catch((error) => {
            console.error("finishShipment error", error);
            reject(error);
          });
      }
    })
  }

  const handlePrintPackingSlip = async () => {
    console.log("* handlePrintPackingSlip start");
    // setLoading(true);
    // console.log("- shipstationOrder: ", shipstationOrder);
    let warehouseId;
    if(shipstationOrder) warehouseId = shipstationOrder?.advancedOptions?.warehouseId;
    console.log("- warehouseId", warehouseId);
    if (warehouseId) {
      try {
        let warehouse = await getWarehouse(warehouseId);
        // console.log("- warehouse result", warehouse);
        setShipFrom(warehouse.originAddress);
        // setLoading(false);
      } catch (err) {
        console.log("- packingListTemplate err", err);
        const retVal = errorHandler(err)
        // setLoading(false);
        bannerVariantRef.current = 'danger'
        setMsg(retVal)
      }
    }
    const printContent = document.getElementById("print-section")?.innerHTML;
    const frame1 = document.createElement("iframe");
    frame1.name = "frame3";
    frame1.style.position = "absolute";
    frame1.style.top = "-100000000px";
    document.body.appendChild(frame1);
    const frameDoc = frame1.contentWindow
      ? frame1.contentWindow
      : frame1.contentDocument.document
      ? frame1.contentDocument.document
      : frame1.contentDocument;
    frameDoc.document.open();
    frameDoc.document.write("<html><head><title>Print Picking Slip</title>");
    frameDoc.document.write("<style>");
    frameDoc.document.write(
      "body,table{font-family:Arial,Helvetica,sans-serif;font-size:7pt}"
    );
    frameDoc.document.write(
      ".text-center{text-align:center}.text-right{text-align:right}"
    );
    frameDoc.document.write("table th{text-align:center}");
    frameDoc.document.write(
      "table{border-collapse:collapse;border:none;width:100%}"
    );
    frameDoc.document.write(
      "table thead th{border:#666 .5px solid;padding:5px;}"
    );
    frameDoc.document.write("table thead td{border:none;padding:7px;}");
    frameDoc.document.write(".print-section_items{margin-top:10px;}");
    frameDoc.document.write("</style>");
    frameDoc.document.write('</head><body onafterprint="window.close()">');
    frameDoc.document.write(printContent);
    frameDoc.document.write("</body></html>");
    frameDoc.document.close();
    setTimeout(() => {
      window.frames["frame3"].focus();
      window.frames["frame3"].print();
      document.body.removeChild(frame1);
    }, 1000);
  };

  useEffect(() => {
    onPackingScanned();
    return () => {
      socket.removeAllListeners(["on-packing-scanned"]);
    };
    // eslint-disable-next-line
  }, [onPackingScanned]);

  useEffect(() => {
    onPrintingLabel();
    return () => {
      socket.removeAllListeners(["on-printed-shipping-label"]);
    };
  }, []);

  useEffect(() => {
    console.log('* orderIdRef && sourceOrderRef hook init')
    console.log('- orderIdRef.current: ', orderIdRef.current)
    console.log('- sourceOrderRef.current: ', sourceOrderRef.current)
    if(orderIdRef.current && !sourceOrderRef.current.includes('manual')) {
      fetchShipStationOrder.mutate({ orderId: orderIdRef.current })
    } 
    // eslint-disable-next-line
  }, [orderIdRef.current, sourceOrderRef.current])

  useEffect(() => {
    console.log("_checkOutQty effect hook", _checkOutQty);
    console.log("_checkOutQty effect item", item);
    if (_checkOutQty) {
      setIsCheckOutQtyManualEdited(true);
      if (_checkOutQty < item.quantity) {
        alert("Manual input of checked-out quantity must same as ordered");
        checkedOutQtyRef.current.value = item.quantity;
        return;
      } else {
        checkedOutQtyRef.current.value = item.quantity;
      }
    }
    // _checkOutQty && isShipmentFull(item, packingItemsUnderOrderId);
    // eslint-disable-next-line
  }, [_checkOutQty]);

  useEffect(() => {
    console.log("* isDisabledCheckoutBtn hook init");
    console.log('- isDisabledCheckoutBtn: ', isDisabledCheckoutBtn);
    checkoutBtnRef.current = isDisabledCheckoutBtn;
  }, [isDisabledCheckoutBtn]);

  useEffect(() => {
    console.log("* isCheckOutQtyManualEdited hook init");
    console.log("- isCheckOutQtyManualEdited: ", isCheckOutQtyManualEdited);
    isCheckOutQtyManualEdited && isShipmentFull(item, packingItemsUnderOrderId);
    if (isCheckOutQtyManualEdited && isDisabledCheckoutBtn) {
      setIsDisabledCheckoutBtn(false);
    }
    // eslint-disable-next-line
  }, [isCheckOutQtyManualEdited])

  useEffect(() => {
    console.log("* settings hook init")
    if(settings) {
      if(settings?.markShipped) {
        markShippedRef.current = settings?.markShipped;
      }
    }
    // eslint-disable-next-line
  }, [settings]);

  useEffect(() => {
    console.log('* item hooks init')
    if(item){
      let graphics = Boolean(item?._item?._graphics.length) ? item._item._graphics : undefined;
      if (graphics) fetchGraphicLinks(graphics)
    }
    // eslint-disable-next-line
  }, [item])


  async function fetchGraphicLinks(graphics) {
    console.log('* fetchGraphicLinks init')
    let graphicPositions =[];
    let graphicsKeys = graphics.map(g => {
      graphicPositions.push(g?.graphicPosition)
      const key = `${settings?.aws?.graphics}/${g.graphicFileName}`;
      return key;
    })
    // console.log('- graphicsKeys: ', graphicsKeys)
    let promises = graphicsKeys.map(key => getS3presign({ key }))

    try {
      let results = await Promise.all(promises)
      // console.log('- results(s3 presign): ', results)
      if(results) {
        let urls = results.map((r, index) => {
          return {url: r.url, graphicPosition: graphicPositions[index]}
        })
        // console.log('- urls: ', urls)

        setGraphicLinks(urls)
        setSelectedPosition(urls[0])
        // setIsGraphicModalOpen(true)
      }
    } catch (error) {
      console.log('- error(getS3presign): ', error)
    }


    // let { REACT_APP_SOCKET_ENDPOINT } = process.env;
    // if (/^true$/.test(settings?.useLocalRepository)) {

    //   setPreSignedUrl(
    //     `${REACT_APP_SOCKET_ENDPOINT}/graphics/${selectedPosition.graphicFileName}`
    //   );
    // } else {
    //   const key = `${settings?.aws?.graphics}/${selectedPosition.graphicFileName}`;
    //   getS3presign({ key }).then((response) => {
    //     // console.log("- getS3presign response:", response);
    //     setPreSignedUrl(() => response?.url);
    //   });
    // }

  }

  return (
    <>
      <Title title={title} />
      <PageMainHeader
        title={title}
        user={user}
        settings={settings}
      />
      <ToastContainer theme='dark'/>
      <section className="primary shipment">
        <div className="shipment-info">
          <div className="scanned-barcode-wrapper">
            <span className="absolute-postion">Bin</span>
            <span className="scanned-barcode text-center">{bin}</span>
          </div>
          <div className="item-image">
              {
                !onErrorImg
                ? (
                  <>
                    <span className="absolute-postion">Mockup</span>
                    <img
                      src={item && item._item.imageUrl}
                      alt={item && item.sku}
                      className="responsive-img"
                      onError={(e) => {
                        setOnErrorImg(true)
                        e.target.src = `${process.env.REACT_APP_S3_NOIMG}/300.png`
                      }}
                    />
                  </>
                  )
                : (
                  Boolean(graphicLinks.length) && (
                    <>
                      <span className="absolute-postion">Artwork</span>
                      <img
                        src={selectedPosition?.url}
                        alt={item && item.sku}
                        className="responsive-img"
                        onError={(e) => {
                          e.target.src = `${process.env.REACT_APP_S3_NOIMG}/300.png`
                        }}
                        onLoad={() => {
                          setLoadedArtwork(true)
                        }}
                        style={{display: !loadedArtwork ? 'none' : 'block'}}
                      />

                      {!loadedArtwork && <GridLoader color={"#09804C"}/>}

                      {graphicLinks && Boolean(graphicLinks.length > 1) && (
                        <div className="checkbox-wrapper">
                          {graphicLinks.map((g, index) => {
                            return (
                              <Checkbox
                                key={index}
                                label={g.graphicPosition}
                                checked={
                                  selectedPosition &&
                                  selectedPosition.graphicPosition ===
                                    g.graphicPosition
                                    ? true
                                    : false
                                }
                                onChange={() => {
                                  loadedArtwork && setLoadedArtwork(false)
                                  setSelectedPosition(g);
                                }}
                              />
                            )
                          })}
                        </div>

                      )}
                    </>

                  )
                )
            }

          </div>
          <ShipmentDetail
            item={item}
            shipstationOrder={shipstationOrder}
            localOrder={localOrder}
            shipProcessResult={shipProcessResult}
            msg={msg}
            checkedOutQtyRef={checkedOutQtyRef}
            socket={socket}
            setCheckoutQty={setCheckoutQty}
            _checkOutQty={_checkOutQty}
            loading={loading}
            settings={settings}
            handlePrintOrderItemLabel={printOrderItemLabel}
          />
        </div>

        <div className="shipment-msg-checkout-wrapper">
          <div className="msg-wrapper">
            {msg && <Banner variant={bannerVariantRef.current}>{msg}</Banner>}
          </div>
          <Button
            variant="primary"
            className="swing-icon"
            onClick={handleCheckout}
            disabled={isDisabledCheckoutBtn}
            ref={checkoutBtnRef}
            leftGlyph={<FaPassport />}
            size="large"
          >
            Pass QC & Check-out
          </Button>
        </div>

        {packingItemsUnderOrderId && item && (
          <PackingItemsUnderOrderId
            awaitItems={awaitItemsRef.current}
          />
        )}
        {/* <pre>{JSON.stringify(graphicLinks, 2, null)}</pre> */}
      </section>

      {packingItemsUnderOrderId && (
        <PackingSlipTemplate
          shipFrom={shipFrom}
          ssOrder={shipstationOrder}
          localOrder={localOrder}
          orderItems={packingItemsUnderOrderId}
          settings={settings}
        />
      )}
      <ModalPreloader modalPreloaderIsOpen={modalPreloaderIsOpen} />
    </>

  )
}
