import { axiosInstance as axios } from "configs/axiosConfig";
import React, { createContext, useState } from "react";
import { useHistory } from "react-router-dom";

export const AuthContext = createContext();

const AuthContextProvider = (props) => {
  const [authError, setAuthError] = useState(null);
  const [user, setUser] = useState(() => {
    const storedUser = window.localStorage.getItem("_auth");
    // console.log("storedUser", storedUser);
    if (storedUser) {
      return JSON.parse(storedUser);
    } else {
      return null;
    }
  });

  const history = useHistory();

  const login = (username, password) => {
    const url = `auth/authenticate`;
    return axios
      .post(url, { username, password })
      .then((res) => {
        setUser(res.data);
        window.localStorage.setItem("_auth", JSON.stringify(res.data));
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        return setAuthError(error.response.data.message);
        //  Promise.reject(error);
      });
  };

  const logout = () => {
    window.localStorage.clear();
    setUser(null);
    history.push("/login");
    return Promise.resolve(true);
  };

  return (
    <AuthContext.Provider
      value={{ login, logout, authError, setAuthError, user }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
