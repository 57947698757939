import React from "react";
import _ from "lodash";
import { FaEdit, FaTimes, FaSave } from "react-icons/fa";
import Tooltip from "@leafygreen-ui/tooltip";
import IconButton from "@leafygreen-ui/icon-button";
import { deleteGraphic, submitGraphic } from "utils/graphics";

const initialGraphic = {
  graphicFileName: "",
  graphicPosition: "",
  imageUrl: "",
  platen: "",
  media: "",
  size: { width: "", height: "" },
  sku: "",
};

export default function MappedGraphicsTable({
  item,
  useARXfile,
  platens,
  media,
  usePolarisRecipe,
  usePrintMode
}) {
  // console.log('* MappedGraphicsTable init')
  // console.log('- useARXfile: ', useARXfile)
  // console.log("- platens: ", platens);
  // console.log("- media: ", media);
  // console.log("- usePolarisRecipe: ", usePolarisRecipe);
  // console.log("- usePrintMode: ", usePrintMode);
  const [graphics, setGraphics] = React.useState([]);

  React.useEffect(() => {
    // console.log('- item: ', item)
    if (item?._graphics && Boolean(item?._graphics.length)) {
      setGraphics(item._graphics);
    }
  }, [item]);

  const onChangeHandler = ({ id, value, index }) => {
    // console.log("== onChangeHandler init");
    // console.log('- id: ', id);
    // console.log('- value: ', value);
    // console.log('- index ', index );
    setGraphics((_graphics) => {
      // let _graphics = item && _.cloneDeep(item._graphics);
      // console.log('- _graphics: ', _graphics)
      if (_.includes(id, "size")) {
        const sizeArray = id.split(".");
        _graphics[index][sizeArray[0]][sizeArray[1]] = value;
      } else {
        _graphics[index][id] = value;
      }

      return [..._graphics];
    });
  };

  const deleteGraphicHandler = (graphic, rowId) => {
    console.log("* deleteGraphicHandler init");
    // console.log('- graphic: ', graphic)
    console.log("- rowId: ", rowId);

    function removeRow(rowId) {
      let rowElem = document.getElementById(rowId);
      // console.log('-rowElem: ', rowElem)
      rowElem.remove();

      // let temp = _.cloneDeep(graphics)
      // let filtered = _.filter(temp, (t) =>  t.graphicPosition !== graphic.graphicPosition)
      // console.log('-filtered: ', filtered)
      // setGraphics([...filtered]);
      let rowElems = document.querySelectorAll(".graphics-row");
      // console.log('-rowElems.length: ', rowElems.length);
      if (rowElems.length === 0) setGraphics([]);
    }

    if (!graphic?._id) {
      removeRow(rowId);
    } else {
      const confirm = window.confirm("Confirm to delete the graphic!");
      if (confirm) {
        // let currentGraphics = [...graphics]
        // currentGraphics.splice(index, 1)
        // setGraphics([...currentGraphics])

        deleteGraphic(graphic?._id)
          .then((result) => {
            console.log("- deleteGraphic result: ", result);
            removeRow(rowId);
          })
          .catch((error) => console.error("- deleteGraphic error: ", error));
      }
    }
  };

  const submitHandler = async (index) => {
    console.log("* submitHandler init");
    console.log("- index", index);
    const graphic = graphics[index];
    // console.log("- graphic", graphic);

    const { sku, graphicPosition } = graphic;
    await submitGraphic({
      condition: {
        sku,
        graphicPosition,
      },
      update: graphic,
    })
      .then((result) => {
        // console.log("- submitGraphic result: ", result);
        setGraphics((graphics) => {
          let temp = _.cloneDeep(graphics);
          let idx = _.findIndex(temp, {
            graphicPosition: result.graphicPosition,
          });
          console.log("-idx: ", idx);
          if (idx === -1) {
            temp.push(result);
          } else {
            temp[idx] = result;
          }
          setGraphics([...temp]);
        });
      })
      .catch((error) => console.error("- submitGraphic error ", error));
  };

  return (
    <div className="mb-10">
      <div className="d-flex align-items-center">
        <span className="block font-weight-bold font-size-sm mr-10">
          Graphics
        </span>
        <Tooltip
          align="right"
          justify="start"
          triggerEvent="hover"
          aria-labelledby="Add a graphic"
          darkMode={true}
          className="lg-tooltip-container"
          popoverZIndex={99999}
          trigger={
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                setGraphics((current) => [
                  ...current,
                  { ...initialGraphic, sku: item.sku },
                ]);
              }}
              className="swing-icon"
              aria-labelledby="Add a graphic icon"
            >
              <FaEdit className="m-0" />
            </IconButton>
          }
        >
          Add a graphic
        </Tooltip>

        {/* {sku && <Link to={{pathname:"/graphics", search:`?sku=${sku}`}} className="mb-10" title="Edit graphics">
          <faPlus />
        </Link>} */}
      </div>
      {Boolean(graphics.length) ? (
        <table className="graphics-table">
          <thead>
            <tr>
              <th className="text-left">Position</th>
              <th className="text-left">Graphic File</th>
              {useARXfile && <th>ARX File</th>}
              <th className="text-left">Platen</th>
              {!useARXfile && (
                <>
                  <th className="text-left">Size(width/height)</th>
                  <th className="text-left">Rip Env.</th>
                  {(media && Boolean(media.length)) && <th className="text-left">Media<small>(CADlink)</small></th>}
                  {usePrintMode && <th className="text-left">Print Mode<small>(CADlink)</small></th>}

                  {usePolarisRecipe && (
                    <th className="text-left">Recipe(Polaris)</th>
                  )}
                </>
              )}
              <th className="text-left">Action</th>
            </tr>
          </thead>
          <tbody>
            {graphics.map((graphic, index) => {
              return (
                <tr
                  key={index}
                  className="graphics-row"
                  id={`graphic-row-${index}`}
                >
                  <td style={{ maxWidth: 70 }}>
                    <input
                      type="text"
                      id="graphicPosition"
                      defaultValue={
                        graphic?.graphicPosition ? graphic.graphicPosition : ""
                      }
                      onChange={(e) =>
                        onChangeHandler({
                          id: e.target.id,
                          value: e.target.value,
                          index,
                        })
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      id="graphicFileName"
                      defaultValue={
                        graphic?.graphicFileName ? graphic.graphicFileName : ""
                      }
                      onChange={(e) =>
                        onChangeHandler({
                          id: e.target.id,
                          value: e.target.value,
                          index,
                        })
                      }
                    />
                  </td>
                  {useARXfile && (
                    <td>
                      <input
                        type="text"
                        id="arxFileName"
                        defaultValue={graphic?.arxFileName}
                        onChange={(e) =>
                          onChangeHandler({
                            id: e.target.id,
                            value: e.target.value,
                            index,
                          })
                        }
                      />
                    </td>
                  )}
                  <td style={{ maxWidth: 100 }}>
                    <select
                      id="platen"
                      value={graphic?.platen ? graphic.platen : ""}
                      onChange={(e) =>
                        onChangeHandler({
                          id: e.target.id,
                          value: e.target.value,
                          index,
                        })
                      }
                    >
                      <option value="">-- Choose a platen --</option>
                      {platens &&
                        platens.map((p) => {
                          return (
                            <option value={p.code} key={p.code}>
                              {p.name} ({p.code} inch) : {p.printers.join(", ")}
                            </option>
                          );
                        })}
                    </select>
                    {/* <input
                      type="text"
                      id="platen"
                      defaultValue={graphic?.platen}
                      onChange={(e) =>
                        onChangeHandler({
                          id: e.target.id,
                          value: e.target.value,
                          index,
                        })
                      }
                    /> */}
                  </td>

                  {!useARXfile && (
                    <>
                      <td className="d-flex" style={{ minWidth: 130 }}>
                        <input
                          type="text"
                          id="size.width"
                          defaultValue={
                            _.has(graphic, "size") &&
                            !_.isNil(graphic.size.width)
                              ? graphic.size.width
                              : ""
                          }
                          style={{ maxWidth: 60 }}
                          placeholder="width"
                          onChange={(e) =>
                            onChangeHandler({
                              id: e.target.id,
                              value: e.target.value,
                              index,
                            })
                          }
                        />
                        <input
                          type="text"
                          id="size.height"
                          defaultValue={
                            _.has(graphic, "size") &&
                            !_.isNil(graphic.size.height)
                              ? graphic.size.height
                              : ""
                          }
                          style={{ maxWidth: 60, marginLeft: 10 }}
                          placeholder="height"
                          onChange={(e) =>
                            onChangeHandler({
                              id: e.target.id,
                              value: e.target.value,
                              index,
                            })
                          }
                        />
                      </td>
                      <td style={{ maxWidth: 60 }}>
                        <input
                          type="text"
                          id="ripEnv"
                          defaultValue={graphic?.ripEnv ? graphic.ripEnv : ""}
                          onChange={(e) =>
                            onChangeHandler({
                              id: e.target.id,
                              value: e.target.value,
                              index,
                            })
                          }
                        />
                      </td>
                      { (media && Boolean(media.length)) &&
                        <td style={{ maxWidth: 100 }}>
                          <select
                            id="media"
                            value={graphic?.media ? graphic.media : ""}
                            onChange={(e) =>
                              onChangeHandler({
                                id: e.target.id,
                                value: e.target.value,
                                index,
                              })
                            }
                          >
                            <option value="">-- Choose media --</option>
                            {media.map((p) => {
                              return (
                                <option value={p.code} key={p.code}>
                                  {p.name}({p.code})
                                </option>
                              );
                            })}
                          </select>
                        </td>
                      }
                      {usePrintMode && (
                        <td style={{ maxWidth: 60 }}>
                          <input
                            type="text"
                            id="printMode"
                            defaultValue={graphic?.printMode ? graphic.printMode : ""}
                            onChange={(e) =>
                              onChangeHandler({
                                id: e.target.id,
                                value: e.target.value,
                                index,
                              })
                            }
                          />
                        </td>
                      )}
                      {usePolarisRecipe && (
                        <td style={{ maxWidth: 60 }}>
                          <input
                            type="text"
                            id="polarisRecipe"
                            defaultValue={graphic?.polarisRecipe ? graphic.polarisRecipe : ""}
                            onChange={(e) =>
                              onChangeHandler({
                                id: e.target.id,
                                value: e.target.value,
                                index,
                              })
                            }
                          />
                        </td>
                      )}
                    </>
                  )}
                  <td style={{ maxWidth: 70 }} className="text-center">
                    <Tooltip
                      align="right"
                      justify="start"
                      triggerEvent="hover"
                      aria-labelledby="Save a graphic"
                      darkMode={true}
                      className="lg-tooltip-container"
                      popoverZIndex={99999}
                      trigger={
                        <IconButton
                          onClick={(e) => {
                            e.preventDefault();
                            submitHandler(index);
                          }}
                          className="swing-icon"
                          aria-labelledby="Save a graphic icon"
                        >
                          <FaSave className="m-0" />
                        </IconButton>
                      }
                    >
                      Save
                    </Tooltip>
                    <Tooltip
                      align="right"
                      justify="start"
                      triggerEvent="hover"
                      aria-labelledby="Delete a graphic"
                      darkMode={true}
                      className="lg-tooltip-container"
                      popoverZIndex={99999}
                      trigger={
                        <IconButton
                          onClick={(e) => {
                            e.preventDefault();
                            deleteGraphicHandler(
                              graphic,
                              `graphic-row-${index}`
                            );
                          }}
                          className="swing-icon"
                          aria-labelledby="Delete a graphic icon"
                        >
                          <FaTimes className="m-0" />
                        </IconButton>
                      }
                    >
                      Delete
                    </Tooltip>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <span className="font-size-sm">- No graphic found</span>
      )}

      {/* <pre>{JSON.stringify(graphics, null, 2)}</pre> */}
    </div>
  );
}
