import React, { useState, useCallback, useEffect } from "react";
import socketIOClient from "socket.io-client";
import { ToastContainer, toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import { Title } from 'utils/Title'
import PageMainHeader from 'components/PageMainHeader'
import { AuthContext } from "contexts/AuthContext"
import { SettingsContext } from 'contexts/SettingsContext'
import Banner from '@leafygreen-ui/banner'
import PageMenuHeader from 'components/PageMenuHeader'
import _ from "lodash";
import moment from "moment";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { axiosInstance as axios } from "configs/axiosConfig";
import { errorHandler } from "utils/errorHandler";
import { getItemDoc } from "utils/items";
import Search from "components/Search";
import { FaBackspace, FaList, FaRecycle } from "react-icons/fa";
import Button from "@leafygreen-ui/button";
import {Table, TableHeader, Row, Cell} from "@leafygreen-ui/table";
import Tooltip from "@leafygreen-ui/tooltip";
import IconButton from "@leafygreen-ui/icon-button";
import { ModalPreloader } from "utils/Preloader";
import ScrollTop from "utils/ScrollTop";
import { addTransaction } from "utils/inventoryTransactions";

const title="Defect Handling"

const socket = socketIOClient(process.env.REACT_APP_SOCKET_ENDPOINT);

const getAllDefects = () => {
  return new Promise((resolve, reject) => {
    const url = `/defect`;
    setTimeout(() => {
      axios.get(url)
        .then((res) => {
          resolve(res.data)
        })
        .catch((error) => {
          reject(error);
        });
    }, 2000);

  })
};

const reasons = ["Print defect", "Material defect", "Pre-treatment defect", "Inventory defect", "Other defect"];

export default function DefectProcess() {
  const { settings } = React.useContext(SettingsContext);
  const { user } = React.useContext(AuthContext);
  const [sku, setSKU] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState(null);
  const [feedback, setFeedback] = useState(null);
  const [searchTxt, setSearchTxt] = useState("");
  const [disableDispose, setDisableDispose] = useState(true);
  const queryClient = useQueryClient();

  const { data: items, isLoading, isError, error } = useQuery("defects",
    async () => await getAllDefects(),
    {refetchOnWindowFocus: false}
  )

  const defectHandler = useMutation(({ action, payload }) => {
    console.log('* defectHandler init')
    const url = `/defect/${action}`;
    return axios.post(url, payload);
  }, {
    onMutate: () => queryClient.cancelQueries("defects"),
    onSuccess: (result, variables) => {
      // console.log("- result: ", result);
      // console.log("- variables: ", variables);
      const { action, payload } = variables;
      console.log('- action: ', action)
      switch (action) {
        case "create":
          queryClient.setQueriesData("defects", old => ([...old, result.data]))
          break;
        case "delete":
          sku && setSKU(null)
          selectedItem && setSelectedItem(null)
          queryClient.setQueriesData("defects", old => {
            return old.filter(i => i._id !== payload.id)
          })
          break;
        case "update":
          queryClient.setQueriesData("defects", old => {
            let idx = _.findIndex(old, { _id: payload?.condition.id });
            old[idx] = result.data;
            return old;
          })
          break;
        default:
          break;
      }
    }
  });

  const onDefectScanned = useCallback(async () => {
    socket.on("on-barcode-scanned", async (data) => {
      console.log("* on-barcode-scanned init")
      // console.log('- data: ', data)
      let dataArr = [];
      let delimiter = ";";
      if (_.includes(data, "*")) delimiter = "*";
      console.log("- delimiter: ", delimiter);
      dataArr = data.split(delimiter);
      const code = dataArr[0];
      // console.log("code", code);
      setLoading(true)
      msg && setMsg(null);
      selectedItem && setSelectedItem(null);
      searchTxt && setSearchTxt("");
      sku && setSKU(null);

      setSKU(code);

      let production, payload;

      try {
        // get a production info from items
        production = await getItemDoc(code);
        if (production) {
          // console.log("- production", production);

          setSelectedItem(production);
          const component = production?._component;
          if (!_.isNil(component) && !_.isEmpty(component)) {
            payload = {
              sku: component.sku,
              inventoryArea: component.inventoryArea,
              reason: reasons[0],
            };
            setDisableDispose(false);
          } else {
            toast.error(`Component information not found`, {
              position: "bottom-right"
            })
            setLoading(false)
            return
          }
        } else {
          toast.error(`${code} not found!`, {
            position: "bottom-right",
          })
          setLoading(false)
          return
        }
      } catch (error) {
        const retval = errorHandler(error);
        setMsg(retval)
        setLoading(false)
        return
      }

      if (payload) {
        setLoading(false)
        defectHandler.mutate({ action: "create", payload });
      }
      

    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTimeout(() => {
      onDefectScanned();
    }, 1000);

    return () => {
      socket.removeAllListeners(["on-barcode-scanned"]);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(items) {
      if(Boolean(items.length)) {
        setDisableDispose(false);
      } else {
        setDisableDispose(true);
      }
    } 
  }, [items])


  const onChangeHandler = (e, item) => {
    console.log('* onChangeHandler init')
    console.log("value: ", e.target.value);
    console.log("name: ", e.target.name);
    defectHandler.mutate({ action: "update", payload: { condition: { id: item._id }, update: { [e.target.name]: e.target.value } } });
  };

  const deleteDefectHandler = (item) => {
    defectHandler.mutate({ action: "delete", payload: { id: item._id } });
  };

  const handlingDefect = async () => {
    console.log("defect items", items);
    setDisableDispose(true)
    setLoading(true)
    let len = items.length;
    let i = len - 1;

    async function loop(i) {
      let item = items[i];
      // console.log(i, item);
      // console.log("reason", item.reason);
      let transaction = {
        sku: item.sku,
        description: item.reason,
        quantity: item.quantity * -1,
        date: new Date(),
        inventoryArea: item?.inventoryArea,
        user: user.username,
        type: "component"
      };
      try {
        await addTransaction({transaction});
        // console.log("handlingDefect result", result);
        // console.log('- i: ', i)
        setFeedback(`${i+1}/${len} ${item.sku} disposed.`)
       
      } catch (error) {
        console.log('- handlingDefect error: ', error)
        setLoading(false)
        setDisableDispose(false)
        const retval = errorHandler(error)
        console.log('- retval: ', retval)
        setMsg(retval)
        return
      }

      i--;
      if (i >= 0) loop(i);
      if (i === 0) {
        console.log(`- reach out the last loop: i: ${i}`)
        setTimeout(() => {
          setLoading(false)
          setDisableDispose(false)
          setFeedback(null);
          toast.info(`Defect handling done! reloading...`, {
            position: "bottom-right",
            autoClose: 2000,
            onClose: async () => {
              await axios.delete(
                `/defect/delete-all/`
              ).then(() => {
                // console.log("delete all result", res);
                window.location.reload();
              });
            },
          });
        }, 3000);
        
      }
    }

    if (i >= 0) {
      loop(i);
    }
  };

  const handleSearchComponent = async (searchTxt) => {
    console.log('* handleSearchComponent init')
    console.log("- searchTxt: ", searchTxt);
    let component, payload;
    if (searchTxt) {
      const searchTxtUpper = searchTxt.toUpperCase();
      setSearchTxt(searchTxtUpper);
      component = await getItemDoc(searchTxtUpper)
      console.log("- component", component)
      if (!_.isNil(component) && !_.isEmpty(component)) {
        payload = {
          sku: component.sku,
          inventoryArea: component.inventoryArea,
          reason: reasons[0],
        };

        if (payload) {
          defectHandler.mutate({ action: "create", payload });
        }

      } else {
        toast.error(`Component information not found`, {
          position: "bottom-right"
        })
        return
      }
    }

  }

  return <>
      <Title title={title} />
      <PageMainHeader 
        title={title} 
        user={user} 
        settings={settings} 
      />
      <ToastContainer theme='dark'/>
      <section className="primary">
        {isLoading ? (
          <Skeleton count={20} height={50} circle={true} />
        ) : isError ? (
          <Banner variant='danger'>{error?.message ? error.message : error}</Banner>
        ) : (
          null
        )}
        <PageMenuHeader>
          <div className='btn-menu-list_container'>
            <div className='btn-menu-list'>
              <Button
                variant="primary"
                href="/defect-transactions"
                leftGlyph={<FaList/>}
              >
                View defect transactions
              </Button>
              {items && (
                <Button
                  variant="danger"
                  className="swing-icon"
                  onClick={handlingDefect}
                  disabled={disableDispose}
                  leftGlyph={<FaRecycle />}
                >
                  Dispose all
                </Button>
              )}
            </div>
            <div className="align-right_container">
              <Search handleSearch={handleSearchComponent} placeholderText="Search by component" />
            </div>
          </div>
        </PageMenuHeader>
        {msg && <Banner>{msg}</Banner>}
        {feedback && <Banner variant="success">{feedback}</Banner>}
        
        { (sku && selectedItem) && (
          <div className="d-flex card mb-10 p-10">
            <div className="title-content-list" style={{width: "100%", justifyContent: "space-between"}}>
              <div className="title-content">
                <span className="title">SKU</span>
                {sku && (
                  <span className="d-block content">{sku}</span>
                )}
              </div>
            
              <div className="title-content">
                <span className="title">Component</span>
                <span className="content">{selectedItem?.component}</span>
              </div>
              <div className="title-content">
                <span className="title">Stock</span>
                <span className="content">
                  {selectedItem?._component?.stock}
                </span>
              </div>
              <div className="title-content">
                <span className="title">Inventory Location</span>
                <span className="content">
                  {selectedItem?._component?.inventoryArea}
                </span>
              </div>
              <div className="title-content">

                {!selectedItem?.imageUrl && (
                  <span className="title">Mockup</span>
                )}
                {selectedItem?.imageUrl && (
                  <img
                    src={selectedItem?.imageUrl}
                    alt={`${selectedItem?.sku}`}
                    className="responsive-img"
                    onError={(e) =>
                      (e.target.src = `${process.env.REACT_APP_S3_NOIMG}/300.png`)
                    }
                    style={{maxWidth: 80}}
                  />
                )}
              </div>
            </div>
          </div>
        )}        

        {items && (Boolean(items.length) ? (
          <Table
            data={items}
            columns={[
              <TableHeader label="Date" dataType="date"/>,
              <TableHeader label="SKU" />,
              <TableHeader label="Quantity" dataType="number"/>,
              <TableHeader label="Reason" />,
              <TableHeader label="Action" />,
            ]}
          >
            {({datum}) => (
            <Row key={datum._id}>
              <Cell>
                {moment(datum.date).local().format("MM-DD-YYYY")}
              </Cell>
              <Cell>{datum.sku}</Cell>
              <Cell>
                <input type="text" value={datum?.quantity}
                  onChange={(e) => onChangeHandler(e, datum)}
                  name="quantity"
                  className="browser-default center-align"
                  style={{maxWidth: 50}}
                />
              </Cell>
              <Cell>
                <select
                  className="browser-default"
                  name="reason"
                  onChange={(e) => onChangeHandler(e, datum)}
                  style={{ maxWidth: "250px" }}
                  value={datum.reason}
                >
                  <option value="">= Select a defect reason =</option>
                  {reasons.map((r, index) => {
                    return (
                      <option key={index} value={r}>
                        {r}
                      </option>
                    );
                  })}
                </select>
              </Cell>

              <Cell>
                <Tooltip
                  className="lg-tooltip-container"
                  darkMode={true}
                  trigger={
                    <IconButton
                      className="swing-icon"
                      onClick={() => deleteDefectHandler(datum)}
                      aria-label="delete the line item"
                    >
                      <FaBackspace /> 
                    </IconButton>
                  }
                >
                  Delete the line item
                </Tooltip>
              </Cell>
            </Row>
            )}
          </Table>
          ) : (
            <span>Not found item.</span>
          ))
        }
        <ScrollTop />
      </section>
      <ModalPreloader modalPreloaderIsOpen={loading}/>
    </>
}
