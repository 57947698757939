import ModalTemplate from 'components/modals/ModalTemplate';
import React from 'react'
import { FaSave, FaTrashAlt } from 'react-icons/fa';
import Button from "@leafygreen-ui/button"

export default function ModalFinishedGoodsInventory({
  modalIsOpen,
  setModalIsOpen,
  title,
  mode,
  item,
  setItem,
  submitHandler,
  handleDelete,
  user
}) {
  const formRef = React.useRef();
  // console.log("formRef", formRef);
  // console.log("- item: ", item);

  const handleSubmit = (e) => {
    e.preventDefault();
    formRef.current.reportValidity();
    if (formRef.current.checkValidity()) submitHandler();
  };

  const onChangeHandler = (e) => {
    // console.log('* onChangeHandler init')
    // console.log(e.target.id, e.target.value);
    setItem({ ...item, [e.target.id]: e.target.value.trim() });
  };

  return (
    <ModalTemplate
      title={title}
      subTitle={mode}
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}
      modalSize={{ width: "400px", height: "auto" }}
    >
      <form ref={formRef} className="card modal-form" >
        <div className="input-field">
          <label htmlFor="sku">
            SKU
          </label>
          <input
            id="sku"
            type="text"
            value={item.sku ? item.sku : ""}
            onChange={onChangeHandler}
            placeholder="SKU"
            required={true}
            autoFocus
          />
        </div>
        <div className="input-field">
          <label htmlFor="areaCode">
            Inventory Location Code
          </label>
          <input
            id="areaCode"
            type="text"
            value={item.areaCode ? item.areaCode : ""}
            onChange={onChangeHandler}
            placeholder="Inventory Location Code"
            required={true}
          />
        </div>
        <div className="input-field">
          <label htmlFor="stock">
            Stock Quantity
          </label>
          <input
            id="stock"
            type="number"
            value={item.stock ? item.stock : ""}
            onChange={onChangeHandler}
            placeholder="Stock Qty"
            min="0"
            required={true}
          />
        </div>
        {item?.imageUrl &&
          <div className="card mockup-img">
            <img
              className="responsive-img"
              src={item?.imageUrl}
              style={{ maxHeight: 150 }}
              alt={`${item?.sku}`}
            />
          </div>
        }

        <div className="modal-footer">
          {(mode === 'update' && (user && user.role==="admin")) && (
            <Button
              variant="danger"
              className="swing-icon"
              onClick={handleDelete}
              leftGlyph={<FaTrashAlt />}
            >
              Delete
            </Button>
          )}
              
          <Button variant="primary" onClick={handleSubmit} className="swing-icon" leftGlyph={<FaSave />}>
            Submit
          </Button>

        </div>
      </form>
    </ModalTemplate>
  )
}
