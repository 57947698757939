import BatchDelete from "components/BatchDelete";
import FileImportExport from "components/FileImportExport";
import React from "react";
import { FaBolt, FaPlus } from "react-icons/fa";
import Button from "@leafygreen-ui/button";

const ItemsHeader = ({ showModal, importHandler, exportHandler, selectedExportMode, setSelectedExportMode, batchDeleteHandler}) => {
  return (
    <>
      <div className='btn-menu-list_container'>
        <div className="btn-menu-list">
          <Button
            variant="primary"
            className="swing-icon"
            title="Reload"
            leftGlyph={<FaBolt />}
            onClick={() => {
              window.location.reload();
            }}
          >
            Refresh
          </Button>

          <Button
            variant="primary"
            className="swing-icon"
            leftGlyph={<FaPlus />}

            onClick={() => {
              showModal("create");
            }}
          >
            Add
          </Button>

          <FileImportExport
            importHandler={importHandler}
            exportHandler={exportHandler}
            selectedExportMode={selectedExportMode}
            setSelectedExportMode={setSelectedExportMode}
          />

          <BatchDelete batchDeleteHandler={batchDeleteHandler} />
        </div>
      </div>
    </>
  );
};

export default ItemsHeader;