import { axiosInstance as axios } from "configs/axiosConfig";
import { errorHandler } from "./errorHandler";
import _ from "lodash"

let pmtConstruct = {
  nozzle: "1111",
  sprayStart: "00",
  sprayEnd: "",
  speed: "",
  unit: "1",
  doubleSpray: "1",
  table:"0"
}

export const generatePMTHandler = (item,settings) => {
  console.log("* generatePMTBarcode init");
  // console.log("- item: ", item);
  return new Promise(async (resolve, reject) => {
    //get graphic size
    console.log("- exists graphics: ", Boolean(item?._graphics.length));
    if (Boolean(item?._graphics.length)) {
      let color = item?._component?.color
        ? item._component.color
        : item?.color
        ? item.color
        : item.sku.substring(0, item.sku.indexOf("-"));
      console.log("- color: ", color)
      let { graphicFileName, platen } = item._graphics[0];
      // console.log("- graphicFileName: ", graphicFileName)
      // console.log("- platen: ", platen)
      
      let key = settings?.aws?.graphics
        ? `${settings.aws.graphics}/${graphicFileName}`
        : `graphics/${graphicFileName}`;
      // console.log("- key: ", key)

      const { speedColor } = settings?.pretreatmentConfigures;
      // console.log("- speedColor: ", speedColor)

      let speedDefault = 110;
      let speedTemp = _.find(speedColor, (item) => {
        // console.log('- item: ', item)
        let regex = new RegExp(color, "i");
        return regex.test(item.color) && item;
      });

      console.log('- speedTemp: ', speedTemp)
      const speed = speedTemp?.speed ? speedTemp.speed : speedDefault;
      console.log('- speed: ', speed)

      try {
        let result = await generatePMTBarcode({ key, platen, speed });
        // console.log('- result: ', result)
        result && resolve(result);
      } catch (error) {
        reject(error);
      }
    } else {
      resolve({ message: "Not found graphics" });
    }
  });
};

const generatePMTBarcode = ({ key, platen, speed }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let { height, width } = await getGraphicSize({ key, platen });
      console.log("- height: ", height);
      console.log("- width(inch): ", width, typeof width);
      const maxH = 40;
      const criteriaInchW = 9
      // set sprayEnd
      let h = height + 3 > maxH ? maxH : height + 3;
      let sprayEnd = h < 10 ? `0${h}` : `${h}` 
      pmtConstruct.sprayEnd = sprayEnd
      // set speed
      pmtConstruct.speed = `${speed}`
      console.log(`- width(${width}) < criteriaInchW(${criteriaInchW}):  ${width < criteriaInchW}`);
      if (width < criteriaInchW) {
        pmtConstruct.nozzle = '0110'
      } else {
        pmtConstruct.nozzle = "1111";
      }
      console.log('- values: ', Object.values(pmtConstruct))
      let values = Object.values(pmtConstruct).join('');
      resolve({ pretreatmentBarcode: values });
    } catch (error) {
      reject(error);
    }
  });
}

const getGraphicSize = ({ key, platen }) => {
  return new Promise((resolve, reject) => {
    axios.post('/graphics/get-size', { key, platen })
      .then(response => {
        // console.log('- response: ', response)
        let {height: h, width} = response.data;
        console.log(`- h: ${h}, width: ${width}`)
        const s = 2.54
        let roundedH = Math.round(h * s)
        console.log('- roundedH: ', roundedH)
        
        resolve({height: roundedH, width})
      })
      .catch(error => {
        let retval = errorHandler(error)
        reject(retval)
      })
  })
}
