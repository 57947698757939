import { labelTemplateQR } from "./labelTemplates";
import socketIOClient from "socket.io-client";
const socket = socketIOClient(process.env.REACT_APP_SOCKET_ENDPOINT);

export const printOrderItemLabel = ({ item, settings }) => {
  console.log("* handlePrintOrderItemLabel init");
  // console.log("- item: ", item);
  // console.log("- settings: ", settings);
  const { platens } = settings || [];
  // console.log("- platens: ", platens);

  let zpl;
  if (!settings.printLabelByGraphicPositions) {
    if (item?._item?._graphics && Boolean(item?._item?._graphics.length)) {
      let tempGraphicPositionArr = [];
      for (let g of item?._item?._graphics) {
        if (g?.graphicPosition) tempGraphicPositionArr.push(g.graphicPosition);
      }
      if (Boolean(tempGraphicPositionArr.length)) {
        item._item.graphicPosition = tempGraphicPositionArr.join(",");
      }
    }
    zpl = labelTemplateQR({ item, platens, settings });
    zpl && socket.emit("on-sku-print", zpl);
  } else {
    if (item?._item?._graphics.length > 1) {
      for (let graphic of item._item._graphics) {
        // console.log('- graphic: ', graphic)
        if (item?._item?.graphicPosition)
          item._item.graphicPosition = graphic.graphicPosition;
        zpl = labelTemplateQR({ item, platens, settings });
        zpl && socket.emit("on-sku-print", zpl);
      }
    } else {
      if (item?._item?._graphics.length === 1) {
        item._item.graphicPosition = item._item._graphics[0]?.graphicPosition;
      }

      zpl = labelTemplateQR({ item, platens, settings });
      zpl && socket.emit("on-sku-print", zpl);
    }
  }
};
