import React, { useState, useEffect, useContext, useCallback } from "react";
import WorkOrderList from "./WorkOrderList";
import WorkOrdersHeader from "./WorkOrdersHeader";
import socketIOClinet from "socket.io-client";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Skeleton from "react-loading-skeleton";
import log from "utils/log";
import { Title } from "utils/Title";
import { AuthContext } from "contexts/AuthContext";
import { SettingsContext } from "contexts/SettingsContext";
import { axiosInstance as axios } from "configs/axiosConfig";
import { ModalPreloader } from "utils/Preloader";
import Pagination from "utils/Pagination";
import Msg from "components/Msg";
import PageMainHeader from "components/PageMainHeader";
import _ from "lodash";
import { FaBellSlash } from "react-icons/fa";
import Banner from "@leafygreen-ui/banner";
import ConfirmationModal from "@leafygreen-ui/confirmation-modal";
import PageMenuHeader from "components/PageMenuHeader";
import Search from "components/Search";
import { useDebouncedCallback } from "use-debounce/lib";
import { createOrder, deleteLocalOrder, fetchShipStationOrders, getOrders, updateManyOrder, updateOrder } from "utils/orders";
import { searchByOrderNumber } from "utils/search";
import ScrollTop from "utils/ScrollTop";
import { Tabs, Tab } from "@leafygreen-ui/tabs";
import Button from "@leafygreen-ui/button";
import Icon from "@leafygreen-ui/icon";
import ManualOrderList from "./ManualOrderList";
import ModalManualOrder from "components/modals/ModalManualOrder";
import { errorHandler } from "utils/errorHandler";
import FileImportExport from "components/FileImportExport";
import { jsonToCSV } from "react-papaparse";
import moment from "moment";
import { deletePackingItem } from "utils/packingItems";

const title = "Work Orders";

const socket = socketIOClinet(process.env.REACT_APP_SOCKET_ENDPOINT);

const resetData = async () => {
  const confirm = window.confirm("All reference data will be initialized");
  if (confirm) {
    console.log("== init reset data ==");
    await updateManyOrder({
      condition: {
        orderStatus: { $in: ["awaiting_shipment", "awaiting_payment"] },
      },
      update: {
        checkedOutQty: 0,
        printedQty: 0,
        pickedQty: 0,
      },
    });

    await axios.post(`/packing-items/update-many`, {
      condition: { isActive: true },
      update: {
        checkedOutQty: 0,
        pickedQty: 0,
        printed: [],
      },
    });

    window.location.reload();
  }
};

const pageSize = 50;
const manualOrderLimit = 50;

const initialOrder = {
  orderId: null,
  orderNumber: null,
  orderDate: new Date(),
  shipTo: {name: null},
  quantity: 0,
  pickedQty: 0,
  printedQty: 0,
  checkedOutQty: 0,
  source: 'manual',
}

const queryOptions = {
  keepPreviousData: true,
  refetchOnWindowFocus: false,
  staleTime: 1000 * 60 * 5,
}

const WorkOrders = ({ history }) => {
  // console.info("* Orders page init");

  const [page, setPage] = useState(1);
  const [pageManualOrders, setPageManualOrders] = useState(1);
  const [openConfirmSyncBanner, setOpenConfirmSyncBanner] = useState(false);
  const [openConfirmAddItemBanner, setOpenConfirmAddItemBanner] = useState(false);
  const queryClient = useQueryClient();
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [order, setOrder] = useState(initialOrder)
  const [modalManualOrderIsOpen, setModalManualOrderIsOpen] = useState(false)
  const [mode, setMode] = useState('create')
  const [selectedExportMode, setSelectedExportMode] = useState("retrieved");
  const [msg, setMsg] = useState(null);

  const {
    data: shipstation_orders,
    isLoading,
    isFetching,
    isError,
    error,
    refetch: refetchShipstationOrders,
  } = useQuery(["shipstation_orders", page], () => fetchShipStationOrders(page, pageSize), {
    ...queryOptions,
    enabled: false
    // refetchInterval: 1000 * 10,
  });

  const {
    data: manual_orders,
    refetch: manulalOrderRefetch
  } = useQuery(["manual_orders", pageManualOrders], async () => await getOrders({page: pageManualOrders, limit: manualOrderLimit}), {
    ...queryOptions,
  });

  // console.log(
  //   `isLoading: ${isLoading}, isError: ${isError}, isFetching: ${isFetching}`
  // );
  // console.log("error", error);
  // console.log("- workorders: ", workorders);
  // console.log("- manual_orders: ", manual_orders);
  const { settings } = useContext(SettingsContext);
  const { user } = useContext(AuthContext);
  // console.log("user: ", user);
  // console.log("settings: ", settings);
  const [disableSyncBtn, setDisableSyncBtn] = useState(false);
  const [modalPreloaderIsOpen, setModalPreloaderIsOpen] = useState(false);

  useEffect(() => {
    console.log('* settings hook init')
    if(settings) {
      // console.log('- settings: ', settings)
      if(settings?.defaultOrderManagement === 'manual') {
        setSelectedTab(1)
      } else {
        setSelectedTab(0)
        refetchShipstationOrders()
      }
    }
    // eslint-disable-next-line
  }, [settings])

  useEffect(() => {
    console.log("* orders hook init");
    console.log("- page: ", page);
    // console.log("- shipstation_orders: ", shipstation_orders);
    let cachedData;

    if(settings && settings?.defaultOrderManagement==='shipstation') {
      cachedData = queryClient.getQueryData(['shipstation_orders', page]);
      console.log('- cachedData: ', cachedData);
      if(_.isNil(cachedData)) {
        refetchShipstationOrders();
      } else {
        if (shipstation_orders?.pages > page) {
          queryClient.prefetchQuery(
            ["shipstation_orders", page + 1],
            () => fetchShipStationOrders(page + 1),
            queryOptions
          );
        }
      }
    };

    if(settings && settings?.defaultOrderManagement==='manual') {
      cachedData = queryClient.getQueryData(['manual_orders', page]);
      console.log('- cachedData: ', cachedData);
      if(_.isNil(cachedData)) {
        manulalOrderRefetch();
      }
    }
    
    // eslint-disable-next-line
  }, [page, shipstation_orders, queryClient, settings]);

  useEffect(() => {
    console.log("* manual_orders hook init");
    if(settings?.defaultOrderManagement==='manual' || settings?.useManualOrderCreation) {
      // console.log("- pageManualOrders: ", pageManualOrders);
      msg && setMsg(null)
      // console.log("- manual_orders: ", manual_orders);
      if (manual_orders?.totalPages > pageManualOrders) {
        queryClient.prefetchQuery(
          ["manual_orders", pageManualOrders + 1],
          () => getOrders({page: pageManualOrders + 1, limit: manualOrderLimit}),
          queryOptions
        );
      }

      if(!Boolean(manual_orders?.docs.length)) setMsg('Orders not found')
    }
    // eslint-disable-next-line
  }, [pageManualOrders, manual_orders, queryClient]);

  const onBarcodeScanned = useCallback(() => {
    socket.on("on-barcode-scanned", async (data) => {
      console.log("- on-barcode-scanned: ", data);
      let dataArr = [];
      let delimiter = ";";
      if (_.includes(data, "*")) delimiter = "*";
      console.log("- delimiter: ", delimiter);
      dataArr = data.split(delimiter);
      const orderId = parseInt(dataArr[1]);
      console.log("- orderId", orderId);
      orderId && history.replace(`/workorder-details/${orderId}`);
    });
    // eslint-disable-next-line
  }, []);

  //onBarcodeScanned hook
  useEffect(() => {
    setTimeout(() => {
      onBarcodeScanned();
    }, 1000);

    return () => {
      socket.removeAllListeners(["on-barcode-scanned"]);
    };
    // eslint-disable-next-line
  }, []);

  const onSyncOrderResultCallback = useCallback(() => {
    socket.on("on-sync-order-result", (result) => {
      console.log("on-sync-order-result", result);
      setModalPreloaderIsOpen(false);
      setDisableSyncBtn(false);
      let msg = result?.message;
      toast.info(msg, {
        position: "bottom-right",
        autoClose: false,
        onClose: async () => {
          window.location.reload();
        },
      });
    });
    // eslint-disable-next-line
  }, []);

  //onSyncOrderResultCallback
  useEffect(() => {
    onSyncOrderResultCallback();

    return () => {
      socket.removeAllListeners(["on-sync-order-result"]);
    };
    // eslint-disable-next-line
  }, []);

  const syncOrders = () => {
    console.log("* syncOrders init");
    console.log("- socket.connected: ", socket.connected);
    setOpenConfirmSyncBanner(false);

    if (socket.connected) {
      socket.emit("on-sync-order");
      log.info(`sync orders init at ${new Date()}`);
      setDisableSyncBtn(true);
      setModalPreloaderIsOpen(true);
    } else {
      alert(
        "Sync orders can only be executed on stations that have a Printflo license assigned"
      );
    }
  };

  const handleOnPageChange = ({ selected }) => {
    console.log("- handleOnPageChange init");
    console.log("- selected: ", selected);
    setPage(selected + 1);
  };

  const handleOnPageChangeManualOrders = ({ selected }) => {
    console.log("- handleOnPageChangeManualOrders init");
    console.log("- selected: ", selected);
    setPageManualOrders(selected + 1);
  };

  const handleSearch = async (searchTxt) => {
    console.log("* handleSearch init");
    console.log("- searchTxt", searchTxt);
    if (!_.isEmpty(searchTxt)) {
      setModalPreloaderIsOpen(true);
      await searchByOrderNumber(searchTxt)
        .then((result) => {
          console.log("- result: ", result);
          setModalPreloaderIsOpen(false);

          if (result?.orderId) {
            toast.info(`Moving the order details: ${searchTxt}`, {
              position: "bottom-right",
              onClose: () =>
                history.replace(`/workorder-details/${result.orderId}`),
              autoClose: 2000,
            });
          }
        })
        .catch((error) => {
          console.log("- error: ", error);
          setModalPreloaderIsOpen(false);
          toast.error("Not found!", {
            position: "bottom-right",
            pauseOnFocusLoss: false,
            // onClose: () => setSearchTxt("")
          });
          return false;
        });
    }
  };

  const updateLocaOrder = useMutation(
    async ({ condition, update }) => {
      return await updateOrder({ condition, update });
    },
    {
      onSuccess: (data, variables) => {
        console.log("updateLocaOrder onSuccess data: ", data);
        manulalOrderRefetch();
        
        if(settings?.defaultOrderManagement==='shipstation'){
          refetchShipstationOrders();
        }
      },
    }
  );

  const workOrderStatusHandler = useDebouncedCallback(
    ({ orderId, value }) => {
      console.log("* workOrderStatusHandler");
      console.log("- orderId: ", orderId);
      console.log("- value: ", value);
      let payload = {
        condition: { orderId },
        update: { workOrderStatus: value },
      };
      updateLocaOrder.mutate(payload);
    },
    [100]
  );

  const openModalManualOrder = ({flag, order}) => {
    console.log('* openModalManualOrder init')
    console.log('- flag: ', flag)
    console.log('- order: ', order)
    setMode(flag)
    msg && setMsg(null);

    if(_.isNil(flag)) {
      setMode('create')
      setOrder(initialOrder)
    } 
    else {
      setOrder(order)  
    }
    setModalManualOrderIsOpen(true)
  }

  const submitManualOrderHandler = async () => {
    console.log('* createManualOrderHandler')
    // console.log(order)
    // console.log(mode)
    if(mode==='create') {
      try {
        const result = await createOrder(order)
        console.log(result)
        setOrder(result)
        setOpenConfirmAddItemBanner(true)
      } catch (error) {
        const retVal = errorHandler(error)
        console.error(retVal)
        setMsg(retVal)
      }

    } else {
      let payload = {
        condition: { orderId: order.orderId },
        update: order
      }
      updateLocaOrder.mutate(payload);
    }

    manulalOrderRefetch()
    setModalManualOrderIsOpen(false)
  }

  const deleteManualOrderHandler = async (order) => {
    console.log('* deleteManualOrderHandler')
    // console.log("- order: ", order);
    const {orderId} = order;
    // console.log("- orderId", orderId);
    const confirm = window.confirm(
      "Confirm to remove this order. Restoring data is not available after removing an order"
    );
    if (confirm) {
      setModalManualOrderIsOpen(false)
  
      await deleteLocalOrder(orderId)
        .then(async () => {
          return await deletePackingItem(orderId);
        })
        .then(async () => {
          if (order?.bin) {
            const url = `bins/update`;
            await axios.post(url, {
              condition: { binCode: order.bin },
              update: { $set: { isActive: false } },
            });
          }
          manulalOrderRefetch()
          setModalManualOrderIsOpen(false);
        })
        .catch(error => {
          const retVal = errorHandler(error)
          console.error('- deleteManualOrderHandler error: ', retVal)
          setModalManualOrderIsOpen(false);
          setMsg(retVal)
        })
    }
  }

  const exportManualOrderHandler = async () => {
    console.log("* exportManualOrderHandler init");
    console.log("- selectedExportMode: ", selectedExportMode);

    let exportOrders;

    if (selectedExportMode === "all") {
      setSelectedExportMode("retrieved");
      await getOrders({pagination: false})
        .then(results => {
          exportOrders = results?.docs.map(order => {
            const shipTo = order.shipTo?.name
            return {...order, shipTo, orderDate: moment(order.orderDate).format('MM-DD-yyyy')}
          });
        })
    } else {
      exportOrders = _.cloneDeep(manual_orders?.docs).map(order => {
        const shipTo = order.shipTo?.name
        return {...order, shipTo, orderDate: moment(order.orderDate).format('MM-DD-yyyy')}
      });
    }
    console.log("exportOrders", exportOrders);

    let fields = [
      'orderNumber',
      'orderDate',
      'shipTo',
    ];

    const result = jsonToCSV({
      fields,
      data: exportOrders
    });
    // console.log(result);

    const element = document.createElement("a");
    const file = new Blob([result], {
      type: "text/csv",
    });
    element.href = URL.createObjectURL(file);
    element.download = `manual_orders_${moment().format("YYYYMMDDHHmm")}.csv`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const importManualOrderHandler = (parsedData) => {
    console.log("* importManualOrderHandler init");

    let tempOrders = parsedData.map((order) => order.data);

    console.log("- tempItems", tempOrders);
    let msg = `Total number of orders: ${tempOrders.length}`;
    setMsg(msg)

    let i = 0;
    let len = tempOrders.length - 1;

    async function loop() {
      let tempOrder = tempOrders[i];
      tempOrder['source'] = 'manual';
      const tempShipTo = _.clone(tempOrder?.shipTo);
      console.log('- tempShipTo: ', tempShipTo);

      if(!_.isNil(tempShipTo) && !_.isEmpty(tempShipTo)) {
        tempOrder.shipTo = {}
        tempOrder.shipTo.name = tempShipTo
      }
      console.log('- tempOrder: ', i, tempOrder);

      try {
        const result = await createOrder(tempOrder)
        console.log('- creatOrder result: ', result)
        i += 1;
      } catch (error) {
        const retVal = errorHandler(error);
        console.log('- createOrder error: ', retVal)
        setMsg(retVal)
        return
      }

      if (i <= len) {
        loop();
      } else {
        setMsg("* Completed import orders");
        manulalOrderRefetch()
        return;
      }
    }  

    if (i <= len) {
      loop();
    }
  };

  return (
    <>
      <Title title={title} />
      <ToastContainer theme="dark" />
      <PageMainHeader title={title} />
      <section className="primary">
        {(isLoading || isFetching)? (
          <Skeleton count={20} height={50} circle={true} />
        ) : isError ? (
          <Msg msg={error?.message ? error.message : error} />
        ) : (
          <>
            <PageMenuHeader>
              <WorkOrdersHeader
                testLabel={settings?.testLabel}
                resetData={resetData}
                user={user}
              />
              <Search
                handleSearch={handleSearch}
                placeholderText="Search by order number"
              />
            </PageMenuHeader>

            <Tabs
              setSelected={setSelectedTab}
              selected={selectedTab}
              aria-label="Tabs"
            >
              {(settings?.defaultOrderManagement === 'shipstation' && shipstation_orders) && (
                <Tab name="Shipstation Orders">
                  <div className="card p-10">
                    <PageMenuHeader>
                      {user?.role === "admin" && (
                        <Button
                          variant="primary"
                          onClick={() => setOpenConfirmSyncBanner(true)}
                          leftGlyph={<Icon glyph="Download" />}
                          disabled={disableSyncBtn}
                          className="swing-icon"
                        > Sync Orders
                        </Button>
                      )}
                      {Boolean(shipstation_orders.orders.length) && (
                        <Banner>
                          <FaBellSlash color="red" style={{ marginBottom: 3 }} /> This
                          icon means an order that is not yet synced.
                        </Banner>
                      )}
                    </PageMenuHeader>


                    {shipstation_orders?.pages >= 0 && (
                      <Pagination
                        handleOnPageChange={handleOnPageChange}
                        totalDocs={shipstation_orders.total}
                        totalPages={shipstation_orders.pages}
                        page={page}
                        limit={pageSize}
                      />
                    )}

                    <WorkOrderList
                      orders={shipstation_orders.orders}
                      useWorkOrderStatus={settings?.useWorkOrderStatus}
                      workOrderStatuses={settings?.workOrderStatuses}
                      workOrderStatusHandler={workOrderStatusHandler}
                    />
                  </div>  
                </Tab>
              )}
              {settings?.useManualOrderCreation && (
                <Tab name="Manual Orders">
                  <div className="card p-10">
                    <PageMenuHeader>
                      <Button
                        variant="primary"
                        onClick={openModalManualOrder}
                        leftGlyph={<Icon glyph="Plus" />}
                        className="swing-icon"
                      >Create a manual order
                      </Button>
                      <FileImportExport
                        importHandler={importManualOrderHandler}
                        exportHandler={exportManualOrderHandler}
                        selectedExportMode={selectedExportMode}
                        setSelectedExportMode={setSelectedExportMode}
                      />
                    </PageMenuHeader>
                    {manual_orders?.totalPages > 1  && (
                      <Pagination
                        handleOnPageChange={handleOnPageChangeManualOrders}
                        totalDocs={manual_orders.totalDocs}
                        totalPages={manual_orders.totalPages}
                        page={pageManualOrders}
                        limit={manualOrderLimit}
                      />
                    )}
                    {msg && <Banner className="mb-10">{msg}</Banner>}
                    {
                      manual_orders?.docs && Boolean(manual_orders.docs.length) && (
                        <ManualOrderList
                          orders={manual_orders}
                          useWorkOrderStatus={settings?.useWorkOrderStatus}
                          workOrderStatuses={settings?.workOrderStatuses}
                          workOrderStatusHandler={workOrderStatusHandler}
                          openModalManualOrder={openModalManualOrder}
                        />
                      )
                    }
                  </div>
                </Tab>  
              )}
            </Tabs>
                

            <ScrollTop />
          </>
        )}

        

      </section>

      <ConfirmationModal
        open={openConfirmSyncBanner}
        onConfirm={() => syncOrders()}
        onCancel={() => setOpenConfirmSyncBanner(false)}
        title="Confirm sync orders"
        buttonText="Confirm"
      >
        The sync orders may take some minutes to complete depending on the
        amount of data. A done message will appear upon completion.
      </ConfirmationModal>
      
      <ConfirmationModal
        open={openConfirmAddItemBanner}
        onConfirm={() => {
          if (order?.orderId) {
            toast.info(`Moving the order details: ${order.orderNumber}`, {
              position: "bottom-right",
              onClose: () =>
                history.replace(`/workorder-details/${order.orderId}?source=manual`),
              autoClose: 2000,
            });
          }
        }}
        onCancel={() => setOpenConfirmAddItemBanner(false)}
        title="Add items"
        buttonText="Confirm"
      >
        Click to confirm to add items?
      </ConfirmationModal>

      <ModalManualOrder
        title="Manual Order"
        modalIsOpen={modalManualOrderIsOpen}
        setModalIsOpen={setModalManualOrderIsOpen}
        mode={mode}
        submitHandler={submitManualOrderHandler}
        handleDelete={deleteManualOrderHandler}
        order={order}
        setOrder={setOrder}
      />

      <ModalPreloader modalPreloaderIsOpen={modalPreloaderIsOpen} />
    </>
  );
};

export default WorkOrders;
