import React from 'react'
import { FaFileExport, FaFileImport } from 'react-icons/fa';
import { CSVReader } from 'react-papaparse'
import ModalExportDialog from 'components/modals/ModalExportDialog';
import Button from "@leafygreen-ui/button";
import _ from 'lodash';

export default function FileImportExport({ importHandler, exportHandler, selectedExportMode, setSelectedExportMode }) {
  const [modalIsOpen, setModalIsOpen] = React.useState(false)
  const importBtnRef = React.useRef()

  const handleOpenDialog = (e) => {
    if (importBtnRef.current) {
      importBtnRef.current.open(e);
    }
  };

  const handleOnFileLoad = (data) => {
    importHandler(data);
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  return (
    <>
      <div className='d-flex'>
        <CSVReader
          config={{ header: true, skipEmptyLines: true }}
          onError={handleOnError}
          onFileLoad={handleOnFileLoad}
          noProgressBar
          ref={importBtnRef}
        >
          {() => (
            <Button
              variant="primary" 
              className={`swing-icon ${_.isNil(importHandler) && 'hidden'}`}
              leftGlyph={<FaFileImport />}
              onClick={handleOpenDialog}
            >
              Import
            </Button>
          )}
           
        </CSVReader>
        <Button
          variant="primary" 
          className="swing-icon"
          leftGlyph={<FaFileExport />}
          onClick={() => {
            if(selectedExportMode==='all') {
              exportHandler()
            } else {
              setModalIsOpen(true)
            }
          }}
        >
          Export
        </Button>
      </div>
 
      <ModalExportDialog
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        title="Export"
        message="Choose one of the options below"
        selectedExportMode={selectedExportMode}
        setSelectedExportMode={setSelectedExportMode}
        onConfirm={exportHandler}
      />
    </>
  )
}
