import React from 'react'

export default function PageMenuHeader(props) {
  return (
    <div className="page-menu-header card p-10 hidden-print">
      <div className="btn-menu-list_container">
        {props.children}
      </div>
    </div>    
  )
}
