import { axiosInstance as axios} from "configs/axiosConfig";
import { errorHandler } from "./errorHandler";

export const deleteInventoryArea = (data) => {
  return new Promise((resolve, reject) => {
    try {
      const url = `/inventory-area/delete`;
      axios.post(url, data).then((res) => {
        resolve(res.data);
      });
    } catch (error) {
      let retval = errorHandler(error);
      console.log('-retval: ', retval)
      reject(retval);
    }
  });
};

export const getInventoryAreas = (data) => {
  return new Promise((resolve, reject) => {
    try {
      const url = `/inventory-area`;
      axios.post(url, data).then((res) => {
        resolve(res.data);
      });
    } catch (error) {
      let retval = errorHandler(error);
      console.log('-retval: ', retval)
      reject(retval);
    }
  });
};

export const submitInventoryArea = (data) => {
  return new Promise((resolve, reject) => {
    try {
      const url = `/inventory-area/update`;
      axios.post(url, data).then((res) => {
        resolve(res.data);
      });
    } catch (error) {
      let retval = errorHandler(error);
      console.log('-retval: ', retval)
      reject(retval);
    }
  });
};

export const searchInventoryAreas = (data) => {
  return new Promise((resolve, reject) => {
    try {
      const url = `/inventory-area/search`;
      axios.post(url, data).then((res) => {
        resolve(res.data);
      });
    } catch (error) {
      let retval = errorHandler(error);
      console.log('-retval: ', retval)
      reject(retval);
    }
  });
}

export const deleteInventoryAreaBySku = ({ sku, areaCode }) => {
  return new Promise((resolve, reject) => {
    try {
      const url = `/inventory-area/delete-by-sku`;
      axios.post(url, { sku, areaCode }).then((res) => {
        resolve(res.data);
      });
    } catch (error) {
      let retval = errorHandler(error);
      console.log('-retval: ', retval)
      reject(retval);
    }
  });
};