import { axiosInstance as axios } from "configs/axiosConfig";

export const getAllItems = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `/items`;
      const response = await axios.post(url, data);
      // console.log("getAllItems response", response);
      resolve(response.data);
    } catch (error) {
      console.error("getAllItems error", error);
      reject(error);
    }
  });
};

export const getItemInfo = (sku) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `/items/item-info`;
      const response = await axios.get(url, { params: { sku } });
      // console.log("getItemInfo response", response.data);
      resolve(response.data);
    } catch (error) {
      // console.log("getItemInfo error", error.response);
      reject(error);
    }
  });
};

export const getItemDoc = (sku) => {
  return new Promise(async (resolve, reject) => {
    try {
      // console.log(`- getItemDoc sku: ${sku}`);
      const url = `/items/item-doc`;
      const response = await axios.get(url, { params: { sku } });
      // console.log("getItemDoc response", response.data);
      resolve(response.data);
    } catch (error) {
      // console.log("getItemInfo error", error.response);
      reject(error);
    }
  });
};

// mode: update, create
export const submitItem = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `/items/update`;
      const response = await axios.post(url, data);
      resolve(response.data);
    } catch (error) {
      // console.log("create item err", err.response);
      reject(error);
    }
  });
};

export const deleteItem = (id) => {
  return new Promise((resolve, reject) => {
    const url = `/items/delete/${id}`;
    axios.delete(url)
      .then((res) => {
        // console.log("deleteItem res", res);
        resolve(res.data);
      })
      .catch((err) => {
        console.log("delete item err", err);
        reject(err);
      });
  });
};

export const deleteItemBySku = ({ sku }) => {
  return new Promise((resolve, reject) => {
    const url = `/items/delete-by-sku`;
    axios
      .post(url, {sku})
      .then((res) => {
        // console.log("deleteItemBySku res", res);
        resolve(res.data);
      })
      .catch((err) => {
        console.log("deleteItemBySku err", err);
        reject(err);
      });
  });
};

export const getSearchResults = (data) => {
  return new Promise((resolve, reject) => {
    const url = `/items/search`;
    axios.post(url, data)
      .then((response) => {
        // console.log(response.data);
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
