import { axiosInstance as axios } from "configs/axiosConfig";
import { errorHandler } from "./errorHandler";

export const getSessions = () => {
  return new Promise((resolve, reject) => {
    const storedSessions = window.localStorage.getItem("_sessions");
    // console.log("- storedSessions: ", storedSessions);
    if (storedSessions) {
      resolve(JSON.parse(storedSessions));
      return;
    } else {
      const url = `/sessions`;
      axios
        .get(url)
        .then((response) => {
          if (response?.data) {
            window.localStorage.setItem(
              "_sessions",
              JSON.stringify(response.data)
            );
            resolve(response.data);
          }
        })
        .catch((error) => {
          reject(errorHandler(error));
        });
    }
  });
};
