import React from 'react'
import { FaTimes } from 'react-icons/fa';
import Modal from "react-modal";
Modal.setAppElement("#root");

function ModalTemplate(props) {
  // console.log('* ModalTemplate init')
  // console.log('- props: ', props)
  const { modalIsOpen, setModalIsOpen, afterOpenModal, onAfterCloseModal, title, subTitle, modalSize } = props
  
  function handleCloseModal() {
    setModalIsOpen(false)
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onAfterClose={onAfterCloseModal}
      onRequestClose={handleCloseModal}
      className="modal"
      overlayClassName="modal-overlay"
      contentLabel="Modal"
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      shouldFocusAfterRender={true}
      style={{
        content: {minWidth: modalSize.minWidth, width: modalSize.width,height: modalSize.height}
      }}
      closeTimeoutMS={500}
    >
      <div className="modal-header">
        <h2>{title}</h2>
        {subTitle && <h3>{subTitle}</h3>}
        <span onClick={() => setModalIsOpen(false)} className="modal-close-btn" role="button"><FaTimes /></span>
      </div>

      <div className='modal-content'>
        {props.children}
      </div>

    </Modal>
  )
}

export default React.memo(ModalTemplate)
