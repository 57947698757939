import React, {useState, useContext, useEffect} from 'react'
import Skeleton from 'react-loading-skeleton'
import { endOfMonth, startOfDay } from "date-fns";
import queryString from "query-string"
import moment from "moment";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { jsonToCSV } from 'react-papaparse';
import _ from "lodash";
import { Title } from 'utils/Title'
import { getItemDoc } from 'utils/items';
import InventoryTransactionsList from './InventoryTransactionsList';
import InventoryTransactionHeader from './InventoryTransactionHeader';
import { AuthContext } from 'contexts/AuthContext';
import InventoryTransactionItem from './InventoryTransactionItem';
import ModalInventoryTransaction from './ModalInventoryTransaction';
import { ToastContainer } from 'react-toastify';
import PageMenuHeader from 'components/PageMenuHeader';
import PageMainHeader from 'components/PageMainHeader';
import { SettingsContext } from 'contexts/SettingsContext';
import Banner from "@leafygreen-ui/banner";
import ScrollTop from 'utils/ScrollTop';
import { addTransaction, fetchInventoryTransactions } from 'utils/inventoryTransactions';

const title="Inventory Transactions"
const limit = 50

const initTransaction = {
  sku: "",
  description: "Inventory adjustment",
  quantity: 0,
  date: new Date(),
  inventoryArea: "",
  currentStock: 0,
};
const sDate = startOfDay(new Date());
const eDate = endOfMonth(new Date());

export default function InventoryTransactions({location}) {
  const [transaction, setTransaction] = useState(initTransaction);
  const [msg, setMsg] = useState(null);
  const [startDate, setStartDate] = useState(sDate);
  const [endDate, setEndDate] = useState(eDate);
  const [selectedExportMode, setSelectedExportMode] = useState("all");
  const [sku, setSKU] = useState(null)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const { user } = useContext(AuthContext)
  const { settings } = useContext(SettingsContext)

  const queryClient = useQueryClient();

  const { data: transactions, isLoading, isError, error } = useQuery(['transactions', { sku }],
    () => fetchInventoryTransactions({ sku, startDate, endDate }),
    { refetchOnWindowFocus: false }
  )

  // console.log('- transactions: ', transactions)

  const { data: item, refetch } = useQuery(["itemDoc", sku], async () => {
    console.log("* itemDoc query init")
    console.log("- sku: ", sku);
    return await getItemDoc(sku)
  }, { enabled: false, refetchOnWindowFocus: false });

  useEffect(() => {
    const parsedQs = queryString.parse(location.search)
    // console.log("parsed query string", parsedQs)
    if (_.has(parsedQs, "sku")) {
      setSKU(parsedQs.sku);
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    console.log("**sku hook: ", sku)
    sku && refetch()
    // eslint-disable-next-line
  }, [sku])



  const addTransactionHandler = (transaction) => {
    console.log("* addTransactionHandler init");
    // console.log("- username", user.username)
    // console.log("- item", item);
    // console.log("- sku", sku);
    // console.log("- transaction", transaction);
    modalIsOpen && setModalIsOpen(false)
    
    const type = (sku && item) ? item.type : transaction.type;
    // console.log("type", type)
    transaction.sku = sku ? sku : transaction?.sku ? transaction.sku : null;
    transaction.user = user.username;
    transaction.type = type

    if (_.has(transaction, "date")) delete transaction["date"];
    // console.log('- transaction: ', transaction)
    if(transaction?.sku) {
      handleAddTransaction.mutate({ transaction })
    }
  };

  const fetchTransactionHandler = async () => {
    console.log("* fetchTransactionHandler init");
    // eslint-disable-next-line
    const results = await queryClient.fetchQuery(["transactions", { sku }],
      async () => {
        return await fetchInventoryTransactions({ sku, startDate, endDate, page:1, limit })
      }
    )
    // console.log("- results: ", results)
  }

  const importTransactions = (tas) => {
    // console.log("importTransactions tas", tas)
    let i = 0
    let len = tas.length;

    async function loop() {
      const t = tas[i].data;
      console.log("- each transaction data", t)
      addTransactionHandler(t);

      i = i + 1;
      if (i < len) {
        // console.log("i", i)
        setMsg(`${i + 1}/${len}: ${t.sku} imported.`)
        loop();
      } else {
        // console.log("else i", i);
        setMsg(`** Completed import.`)
      }
    }

    if (i < len) {
      loop()
    }

  }

  const exportTransactions = async () => {
    console.log("* exportTransactions init");
    // console.log("- transactions", transactions);
    // console.log('- selectedExportMode:', selectedExportMode)
    const result = jsonToCSV({
      fields: [
        "sku",
        "description",
        "quantity",
        "inventoryArea",
        "user",
        "date",
        "type"
      ],
      data: transactions
    });
    // console.log("- result: ", result);

    const element = document.createElement("a");
    const file = new Blob([result], {
      type: "text/csv",
    });
    element.href = URL.createObjectURL(file);
    element.download = `${item ? item.sku.replace(" ", "") : "inventory"}_transactions_${moment().format("YYYYMMDDHHmm")}.csv`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const handleAddTransaction = useMutation(async (payload) => {
    console.log("* addTransaction(mutate) init")
    console.log("- payload: ", payload);
    return await addTransaction(payload);
  }, {
    onSuccess: async (response, variables) => {
      // console.log("- variables: ", variables);
      const result = response.data;
      console.log("- onSuccess result: ", result);

      //update f/g current stock at inventory area;
      if (item) {
        queryClient.invalidateQueries(["transactions", { sku }])
      } else {
        queryClient.invalidateQueries(["transactions", { sku: null }])
      }
    }
  })

  return (
    <>
      <Title title={title} />
      <PageMainHeader
        title={title}
        settings={settings}
        user={user}
      />
      <section className="primary">
        {isLoading ? (
          <Skeleton count={20} height={50} circle={true} />
        ) : isError ? (
          <Banner varinat="danger">
            {error?.message ? error.message : error}
          </Banner>
        ) : (
            <>
              <PageMenuHeader>
                <InventoryTransactionHeader
                  importHandler={importTransactions}
                  exportHandler={exportTransactions}
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  fetchTransactionHandler={fetchTransactionHandler}
                  selectedExportMode={selectedExportMode}
                  setSelectedExportMode={setSelectedExportMode}
                  item={item}
                  setModalIsOpen={setModalIsOpen}
                />
              </PageMenuHeader>
              {item && <InventoryTransactionItem item={item} setModalIsOpen={setModalIsOpen} />}    

              {
                msg && <Banner className='mh-10'>{msg}</Banner>
              }

              {
                (transactions && Boolean(transactions?.length))
                  ? (<InventoryTransactionsList transactions={transactions} />)
                  : (<Banner className='mt-10'>Transactions not found.</Banner>)
              }
          </>
        )}
        <ScrollTop />
      </section>

      <ModalInventoryTransaction
        title={"Add Transaction"}
        modalIsOpen={ modalIsOpen }
        setModalIsOpen={setModalIsOpen}
        addTransactionHandler={addTransactionHandler}
        transaction={transaction}
        setTransaction={setTransaction}
      />
      <ToastContainer theme='dark'/>
    </>

  )
}
