import DateRange from 'components/DateRange';
import FileImportExport from 'components/FileImportExport';
import React from 'react'
import { FaBolt, FaPlus } from 'react-icons/fa';
import Button from "@leafygreen-ui/button";

export default function InventoryTransactionHeader({
  importHandler,
  exportHandler,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  fetchTransactionHandler,
  selectedExportMode,
  setSelectedExportMode,
  item,
  setModalIsOpen
}) {
  return (
    <>
      <DateRange
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        onClickHandler={fetchTransactionHandler}
      />
      <div className="btn-menu-list align-right_container">
        <Button
          variant="primary"
          className="swing-icon"
          title="Reload"
          leftGlyph={<FaBolt />}
          onClick={() => {
            window.location.reload();
          }}
        >
          Refresh
        </Button>

        <FileImportExport
          importHandler={importHandler}
          exportHandler={exportHandler}
          selectedExportMode={selectedExportMode}
          setSelectedExportMode={setSelectedExportMode}
        />

        {item && (
          <Button
            variant="primary"
            className="swing-icon"
            onClick={() => setModalIsOpen(true)}
            leftGlyph={<FaPlus />}
          >
            Add Transaction
          </Button>
        )}

      </div>
    </>

  )
}
