import React from "react";
import ModalTemplate from "./ModalTemplate";
import { Table, TableHeader, Row, Cell } from "@leafygreen-ui/table";

export default React.memo(function ModalActivityGraphicDetails({
  modalIsOpen,
  setModalIsOpen,
  title,
  selectedGraphic,
}) {
  // console.log("* ModalConsolidationDetails init");
  
  const columns = [
    <TableHeader label="SKU" />,
    <TableHeader label="Ordered" dataType="number"/>,
  ];
  return (
    <ModalTemplate
      title={title}
      subTitle={selectedGraphic?.graphicFilename}
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}
      modalSize={{ width: 500, height: "auto" }}
    >
      <div className="section">
        {selectedGraphic && (
          <Table data={selectedGraphic.items} columns={columns}>
            {({ datum }) => {
              // console.log("- datum: ", datum);
              return (
                <Row key={datum.sku}>
                  <Cell>{datum?.sku ? datum.sku : ""}</Cell>
                  <Cell>{datum?.total ? datum.total : 0}</Cell>
                </Row>
              );
            }}
          </Table>
        )}

      </div>
    </ModalTemplate>
  );
})

