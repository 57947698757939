import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { Table, TableHeader, Row, Cell } from "@leafygreen-ui/table";
import _ from "lodash";
import { FaEdit } from "react-icons/fa";
import IconButton from "@leafygreen-ui/icon-button";
import Tooltip from "@leafygreen-ui/tooltip";

const ManualOrderList = ({
  orders,
  useWorkOrderStatus,
  workOrderStatuses,
  workOrderStatusHandler,
  openModalManualOrder,
}) => {
  // console.log("* ManualOrderList init");
  // console.log("- orders: ", orders);
  // console.log("- useWorkOrderStatus: ", useWorkOrderStatus);
  // console.log("- workOrderStatuses: ", workOrderStatuses);
  const columns = [
    <TableHeader label="Order Number" sortBy={(datum) => datum.orderNumber} />,
    <TableHeader
      label="Date"
      sortBy={(datum) => datum.orderDate}
      dataType="date"
    />,
    <TableHeader label="Recipient" sortBy={(datum) => datum.shipTo.name} />,
    <TableHeader label="Ordered" dataType="number" />,
    <TableHeader label="Picked" dataType="number" />,
    <TableHeader label="Printed" dataType="number" />,
    <TableHeader label="Checked Out" dataType="number" />,
    <TableHeader label="Bin #" sortBy={(datum) => datum.local?.bin} />,
    <TableHeader label="Action" />,
  ];
  // console.log('- columns: ', columns)
  if (useWorkOrderStatus) {
    columns.push(
      <TableHeader
        label="Order Status"
        sortBy={(datum) => datum.local?.workOrderStatus}
      />
    );
  }

  const WorkOrderStatus = ({ order, flag }) => {
    // console.log('* OrderStatus init')
    const styled = { backgroundColor: "#E1F2F6", color: "#1A567E" };

    const status = _.find(workOrderStatuses, {
      name: order?.workOrderStatus,
    });
    if (status) {
      // console.log('- status: ', status);
      styled.backgroundColor = status.color;
      styled.color = "#fff";
    }

    return (
      <select
        // defaultValue={""}
        // value={order?.local?.workOrderStatus ? order.local.workOrderStatus : ""}
        onChange={(e) => onChangeHandler({ order, value: e.target.value })}
        className={`work-order-status ${flag}`}
        style={styled}
      >
        <option style={{ backgroundColor: "#E1F2F6", color: "#1A567E" }}>
          = Pick Order Status
        </option>
        {workOrderStatuses.map((s) => {
          return (
            <option
              value={s.name}
              key={s._id}
              style={{
                backgroundColor: s.color,
                color: "#fff",
              }}
              selected={s.name === order?.workOrderStatus}
            >
              {s.name}
            </option>
          );
        })}
      </select>
    );
  };

  const onChangeHandler = ({ order, value }) => {
    //change select elem style
    console.log("- order: ", order);
    console.log("- value: ", value);
    console.log("- orders: ", orders);
    let orderId = order?.orderId;
    let foundIndex = _.findIndex(orders?.docs, { orderId });
    console.log("- foundIndex: ", foundIndex);
    if (foundIndex !== -1) {
      const elems = document.querySelectorAll("select.manual");
      elems[foundIndex].selected = value;
      console.log("- elems: ", elems);
      const status = _.find(workOrderStatuses, { name: value });
      console.log("- status: ", status);
      if (status) {
        elems[foundIndex].style.backgroundColor = status.color;
        elems[foundIndex].style.color = "#fff";
      } else {
        elems[foundIndex].style.backgroundColor = "#E1F2F6";
        elems[foundIndex].style.color = "#1A567E";
      }
    }

    workOrderStatusHandler({ orderId, value });
  };
  return (
    <>
      {orders && Boolean(orders?.docs.length) && (
        <div className="page-content card p-10 mt-10 manual-orders">
          <Table data={orders.docs} columns={columns}>
            {({ datum: order }) => (
              <Row key={order.orderId}>
                <Cell>
                  <Link
                    to={`/workorder-details/${order.orderId}?source=manual`}
                  >
                    {order.orderNumber}
                  </Link>
                </Cell>
                <Cell>
                  {moment(order.orderDate).local().format("MM-DD-YYYY")}
                </Cell>
                <Cell style={{ width: 200 }}>
                  {order?.shipTo?.name ? order.shipTo.name : ""}
                </Cell>
                <Cell>{order?.quantity ? order.quantity : 0}</Cell>
                <Cell>{order?.pickedQty ? order.pickedQty : 0}</Cell>
                <Cell>{order?.printedQty ? order.printedQty : 0}</Cell>
                <Cell>{order?.checkedOutQty ? order.checkedOutQty : 0}</Cell>
                <Cell>{order?.bin ? order.bin : ""}</Cell>

                <Cell>
                  <div className="action-btn-list">
                    <Tooltip
                      align="left"
                      justify="start"
                      triggerEvent="hover"
                      aria-labelledby="Edit"
                      darkMode={true}
                      className="lg-tooltip-container"
                      trigger={
                        <IconButton
                          className="swing-icon"
                          aria-labelledby="Edit"
                          onClick={() => {
                            openModalManualOrder({ flag: "edit", order });
                          }}
                        >
                          <FaEdit />
                        </IconButton>
                      }
                    >
                      Edit
                    </Tooltip>
                  </div>
                </Cell>
                {useWorkOrderStatus && (
                  <Cell>
                    <WorkOrderStatus order={order} flag={"manual"} />
                  </Cell>
                )}
              </Row>
            )}
          </Table>
          {/* <pre>orders: {JSON.stringify(orders, null, 2)}</pre> */}
        </div>
      )}
    </>
  );
};

export default React.memo(ManualOrderList);
