import moment from 'moment'
import React from 'react'
import ReactTableContainer from 'utils/ReactTableContainer'

export default function InventoryTransactionsList({transactions}) {
  // console.log('* InventoryTransactionsList init')
  // console.log('- transactions: ', transactions)
  const columns = React.useMemo(
    () => [
      {
        Header: "ID", accessor: "_id",
      },
      {
        Header: "SKU", accessor: "sku",
      },
      {
        Header: "Date", accessor: "date", Cell: ({ row }) => {
          return moment(row.values.date).local().format("MM-DD-YYYY h:mm:ss a")
      } },
      { Header: "Description", accessor: "description" },
      { Header: "Inventory Area", accessor: "inventoryArea" },
      { Header: "Quantity", accessor: "quantity" },
      { Header: "On Hand", accessor: "currentStock" },
      { Header: "Handled By", accessor: "user" },
    ],
    // eslint-disable-next-line
    []
  )
  return (
    <ReactTableContainer
      columns={columns}
      data={transactions}
      hiddenColumns={["_id"]}
      sortBy={[{ id: "date", desc: true }]}
    />

  )
}
