import React, { useState, useEffect, useContext, useCallback, useRef } from "react";
import socketIOClinet from "socket.io-client";
// import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { axiosInstance as axios } from "configs/axiosConfig";
import moment from "moment";

import { SettingsContext } from "contexts/SettingsContext";
import { AuthContext } from "contexts/AuthContext";

import WorkOrderDetailsHeader from "./WorkOrderDetailsHeader";
import WorkOrderDetailsItems from "./WorkOrderDetailsItems";
import { Title } from "utils/Title";
import { submitItem } from "utils/items";
import {
  updatePackingItem,
  addPackingItem,
  deletePackingItem,
  getPackingItemsByOrder,
  updateManyPackingItem,
} from "utils/packingItems";
import {
  createShippingLabel,
  fetchListShipments,
  getShipStationOrder,
  getWarehouse,
} from "utils/shipstation";
import { getLocalOrder, deleteLocalOrder, updateOrder } from "utils/orders";
import { assignBin, updateBin } from "utils/bins";
import { errorHandler } from "utils/errorHandler";
import { ModalPreloader } from "utils/Preloader";
import { useMutation, useQueryClient } from "react-query";
// import { findOnePickFinishedProduct } from "utils/pickFinishedProduct";
import { generatePMTHandler } from "utils/pretreatment";
import {
  FaAddressBook,
  FaCreativeCommonsSampling,
  FaPrint,
  FaRemoveFormat,
  FaShip,
} from "react-icons/fa";
import OrderItemModal from "components/modals/OrderItemModal";
import ModalItem from "components/modals/ModalProduction";
import PageMainHeader from "components/PageMainHeader";
import PageMenuHeader from "components/PageMenuHeader";
import Button from "@leafygreen-ui/button";
import Banner from "@leafygreen-ui/banner";
import IconButton from "@leafygreen-ui/icon-button";
import { useDebouncedCallback } from "use-debounce/lib";
import Search from "components/Search";
import { searchByOrderNumber } from "utils/search";
import { PackingSlipTemplate } from "utils/PackingSlipTemplate";
import { printOrderItemLabel } from "utils/printOrderItemLabel";
import Skeleton from "react-loading-skeleton";
import queryString from "query-string";
import { useHistory, useLocation, useParams } from "react-router-dom";

// import queryString from "query-string";

const title = "Order Details";
const socket = socketIOClinet(process.env.REACT_APP_SOCKET_ENDPOINT);

const WorkOrderDetails = (props) => {
  // console.log("WorkOrderDetails props", props);
  const [shipstationOrder, setShipstationOrder] = useState(null);
  const [shipProcessResult, setShipProcessResult] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const { platens, settings } = useContext(SettingsContext);
  const [orderItemMode, setOrderItemMode] = useState("edit");
  const [orderItemModalIsOpen, setOrderItemModalIsOpen] = useState(false);
  const [itemModalIsOpen, setItemModalIsOpen] = useState(false);
  const { user } = useContext(AuthContext);
  const [shipFrom, setShipFrom] = useState(null);
  const [modalPreloaderIsOpen, setModalPreloaderIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [msg, setMsg] = useState(null);
  const [localOrder, setLocalOrder] = useState(null);
  const [orderItems, setOrderItems] = useState([]);
  const sourceOrderRef = useRef(null);
  const queryClient = useQueryClient();
  const bannerVariantRef = useRef('info');
  const isEditItemSubmitRef = useRef(false)
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const orderIdRef = useRef(null);
  // console.log("user", user);
  // console.log("- orderIdRef", orderIdRef);
  // console.log("testLabel", settings?.testLabel);
  // console.log("defaultIntegratedAutomation", settings?.defaultIntegratedAutomation);
  // console.log("- useWorkOrderStatus: ", settings?.useWorkOrderStatus);

  const fetchShipStationOrder = useMutation(
    async ({ orderId }) => {
      console.log("* fetchShipStationOrder init");
      return await getShipStationOrder(orderId);
    },
    {
      onMutate: () => {
        setIsLoading(true);
      },
      onError: (err) => {
        console.log("- getShipStationOrder error", err);
        let retval = errorHandler(err);
        console.log("- retval error", retval);
        setMsg(retval);
        setIsLoading(false);
      },
      onSuccess: (ssOrder, variables) => {
        // console.log("- onSuccess ssOrder: ", ssOrder);
        // console.log("- onSuccess ssOrder: ", ssOrder);
        if (ssOrder?.orderStatus === "shipped") {
          fetchShipment.mutate({ orderId: variables.orderId });
        }
        setShipstationOrder(ssOrder);
        setIsLoading(false);
      },
    }
  );

  const fetchShipment = useMutation(
    async ({ orderId }) => {
      console.log("* fetchShipment init");
      return await fetchListShipments({ orderId });
    },
    {
      onError: (err) => {
        console.log("- fetchListShipments error", err);
        setMsg(err);
      },
      onSuccess: (result) => {
        // console.log("- onSuccess result: ", result);
        let shipment = result?.shipments[0];
        if (shipment) {
          let shippingLabelName = `${shipment.orderNumber}.pdf`;

          setShipProcessResult({
            orderId: shipment.orderId,
            serviceCode: shipment.serviceCode,
            shipmentCost: shipment.shipmentCost,
            trackingNumber: shipment.trackingNumber,
            shippingLabelName,
          });
        }
      },
    }
  );

  const fetchLocalOrder = useMutation(
    async ({ orderId }) => {
      console.log("* fetchLocalOrder init");
      return await getLocalOrder(orderId);
    },
    {
      onMutate: () => msg && setMsg(null),
      onError: (err) => {
        console.log("[fetchLocalOrder] error", err);
        const retVal = errorHandler(err)
        setMsg(retVal);
      },
      onSuccess: async (result, variables) => {
        console.log("[fetchLocalOrder] onSuccess result: ", result);
        if(result) {
          setLocalOrder(result);
          fetchOrderItems.mutate({ orderId: variables.orderId });
          console.log("[fetchLocalOrder] source: ", result?.source);
          if(result?.source !== 'manual' || _.isNil(result.source)) {
            sourceOrderRef.current = 'shipstation'
            fetchShipStationOrder.mutate({orderId: variables.orderId})
          }
        } else {
          const queryParams = queryString.parse(props.location.search);
          console.log('-queryParams; ', queryParams)
          if(_.has(queryParams, 'source') && queryParams?.source) {
            sourceOrderRef.current = queryParams.source
            if(queryParams?.source==='shipstation'){
              fetchShipStationOrder.mutate({orderId: variables.orderId})
            }
          } else {
            setMsg("The order not exists");
          }

        }
      },
    }
  );

  const fetchOrderItems = useMutation(
    async ({ orderId }) => {
      console.log("* fetchOrderItems init");
      return await getPackingItemsByOrder({ orderId });
    },
    {
      onError: (err) => {
        console.log("[fetchOrderItems] error", err);
        const retVal = errorHandler(err)
        setMsg(retVal);
      },
      onSuccess: (result) => {
        console.log("[fetchOrderItems] onSuccess result: ", result.length);
        if(Boolean(result.length)){
          setOrderItems(result);
        } else {
          if(sourceOrderRef.current==='manual') orderItemHandler({type: 'add'})
        } 
      }
    }
  );

  const onBarcodeScanned = async () => {
    socket.on("on-barcode-scanned", async (data) => {
      console.log("* on-barcode-scanned")
      console.log("[on-barcode-scanned] data: ", data);
      let dataArr = [];
      let delimiter = ";";
      if (_.includes(data, "*")) delimiter = "*";
      console.log("[on-barcode-scanned] delimiter: ", delimiter);
      dataArr = data.split(delimiter);
      const id = parseInt(dataArr[1]);
      console.log("[on-barcode-scanned] id", id, typeof id);
      console.log("[on-barcode-scanned] orderIdRef", orderIdRef, typeof Number(orderIdRef.current) );
      console.log(id !== Number(orderIdRef.current))
      if(id !== Number(orderIdRef.current)) {
        setLocalOrder(null);
        setShipstationOrder(null);
        setOrderItems([])
        id && history.push(`/workorder-details/${id}`);
      }
    });
  };

  const onPrintedShipLabel = () => {
    socket.on("on-printed-shipping-label", (result) => {
      console.log("on-printed-shipping-label result", result);
      toast.info(result.message, {
        position: "bottom-right",
      });
    });
  };

  //location.state hook
  useEffect(() => {
    console.log("* location hook init");
    console.log('[location hook] location: ', location)
    console.log('[location hook] params: ', params)
    console.log('[location hook] checking location state has shipment!')
    let orderId;
    if(_.has(params, 'orderId')) {
      orderId = orderIdRef.current = params.orderId;
      console.log('[location hook] call fetchLocalOrder')
      fetchLocalOrder.mutate({orderId})
    }

    if (_.has(props.location.state, "shipment")) {
      let shpmt = props.location.state.shipment;
      let shippingLabelName = `${shpmt.orderNumber}.pdf`;

      setShipProcessResult({
        orderId: shpmt.orderId,
        serviceCode: shpmt.serviceCode,
        shipmentCost: shpmt.shipmentCost,
        trackingNumber: shpmt.trackingNumber,
        shippingLabelName,
      });
    }
    // eslint-disable-next-line
  }, [location]);

  //onPrintedShipLabel hook
  useEffect(() => {
    onPrintedShipLabel();

    return () => {
      socket.removeAllListeners(["on-printed-shipping-label"]);
    };
  }, []);

  //onBarcodeScanned hook
  useEffect(() => {
    setTimeout(() => {
      onBarcodeScanned();
    }, 1000);

    return () => {
      socket.removeAllListeners(["on-barcode-scanned"]);
    };
    // eslint-disable-next-line
  }, []);

  
  const onSKUPrintResult = useCallback(() => {
    socket.on("on-sku-print-result", (result) => {
      console.log("[on-sku-print-result]", result);
      if (result?.name === "Error") {
        toast.error(result?.message, {
          position: "bottom-right",
        });
      } else {
        toast.info(result?.message, {
          position: "bottom-right",
          autoClose: 1500,
        });
      }
    });
  }, []);

  useEffect(() => {
    onSKUPrintResult();

    return () => {
      socket.removeAllListeners(["on-sku-print-result"]);
    };
  }, [onSKUPrintResult]);

  const orderItemHandler = ({ item, type }) => {
    console.log("* orderItemHandler init");
    // console.log('- item: ', item)
    // console.log('- type: ', type)
    setOrderItemMode(type);

    if (item) {
      setSelectedItem(item);
    } else {
      setSelectedItem((current) => {
        // console.log("current", current);
        return current;
      });
    }

    setOrderItemModalIsOpen(true);
  };

  const updateLocalOrder = useMutation(
    async ({ condition, update }) => {
      return await updateOrder({ condition, update });
    },
    {
      onSuccess: (data) => {
        console.log("[updateLocaOrder] onSuccess data: ", data);
        console.log("[updateLocalOrder] setLocalOrder")
        setLocalOrder(data);
      },
    }
  );

  const reAssignBin = useMutation(
    async () => {
      console.log("* reAssignBin init");
      let volumn = _.sumBy(orderItems, (item) => {
        return item.isActive && item.quantity;
      });
      console.log("reAssignBin volumn: ", volumn);
      return await assignBin(orderIdRef.current, volumn);
    },
    {
      onMutate: () => {
        setModalPreloaderIsOpen(true);
        queryClient.cancelQueries(["localOrder", orderIdRef.current]);
        const { bin: previousBin } = localOrder;
        console.log("previousBin: ", previousBin);
        return { previousBin };
      },
      onSuccess: async (data, variables, context) => {
        console.log("reAssignBin onSuccess data: ", data);
        console.log("reAssignBin onSuccess variables: ", variables);
        console.log("reAssignBin onSuccess context: ", context);
        setModalPreloaderIsOpen(false);
        await updateBin({
          condition: { binCode: context.previousBin },
          update: { $set: { isActive: false } },
        });
        queryClient.invalidateQueries(["localOrder", orderIdRef.current]);
      },
    }
  );

  const binHandler = useMutation(
    async (orderQty) => {
      console.log("* binHandler init");
      console.log("[binHandler] orderQty: ", orderQty);
      console.log("[binHandler]  binAssignmentType: ", settings?.binAssignmentType);
      let orderId = orderIdRef.current;
      if (orderQty === 1 && !_.isNil(localOrder?.bin)) {
        return updateBin({
          condition: { binCode: localOrder.bin },
          update: {
            $set: {
              isActive: false,
            },
          },
        }).then(async (result) => {
          console.log("updateBin result", result);
          return await updateOrder({
            condition: { orderId },
            update: {
              $set: {
                bin: null,
                quantity: orderQty,
              },
            },
          });
        });
      } else {
        return
      }
    },
    {
      onSuccess: async (data) => {
        console.log("[binHandler] onSuccess data", data);
        // if(data) await fetchLocalOrder.mutate({orderId: orderIdRef.current});
      },
    }
  );

  const updateOrderItem = useMutation(
    async (orderItem) => {
      console.log("* updateOrderItem mutate init");
      console.log("[updateOrderItem] orderItem: ", orderItem);
      let { sku, quantity, pickedQty, checkedOutQty, isActive } = orderItem;
      return await updatePackingItem({
        condition: { id: orderItem._id },
        update: {
          sku,
          quantity,
          pickedQty,
          checkedOutQty,
          isActive,
        },
      });
    },
    {
      onError: (err) => {
        console.log("[updateOrderItem] onError: ", err);
      },
      onSuccess: async (data) => {
        console.log("[updateOrderItem] onSuccess data: ", data);
        let orderId = orderIdRef.current;
        
        // await fetchOrderItems.mutate({ orderId });
        
        let currentOrderItems = _.cloneDeep(orderItems);
        console.log("[updateOrderItem] currentOrderItems: ", currentOrderItems);
        let foundIndex = _.findIndex(currentOrderItems, { _id: data._id });
        console.log("[updateOrderItem] foundIndex: ", foundIndex);

        let updatedOrderItem = { ...currentOrderItems[foundIndex], ...data};
        console.log('[updateOrderItem] updatedOrderItem: ', updatedOrderItem)
        currentOrderItems[foundIndex] = updatedOrderItem;
        console.log("[updateOrderItem] setOrderItems with updated")
        setOrderItems(currentOrderItems);

        let orderQty = _.sumBy(
          currentOrderItems,
          (item) => item.isActive && parseInt(item.quantity)
        );
        console.log(`[updateOrderItem] total orderQty: ${orderQty}`);

        await binHandler.mutate(orderQty);

        let checkedOutQty = _.sumBy(
          currentOrderItems,
          (item) => item.isActive && parseInt(item.checkedOutQty)
        );
        console.log(`[updateOrderItem] total checkedOutQty: ${checkedOutQty}`);

        let pickedQty = _.sumBy(
          currentOrderItems,
          (item) => item.isActive && parseInt(item.pickedQty)
        );
        console.log(`[updateOrderItem] total pickedQty: ${pickedQty}`);

        let payload = {
          condition: { orderId },
          update: {
            checkedOutQty,
            quantity: orderQty,
            pickedQty,
          },
        };
        await updateLocalOrder.mutate(payload);

        // await fetchLocalOrder.mutate({ orderId });
      },
    }
  );

  const addOrderItem = useMutation(
    async (update) => {
      return await addPackingItem({ update });
    },
    {
      onError: (err) => {
        console.log(" -err: ", err);
      },
      onSuccess: async (data) => {
        console.log("onSuccess data", data);
        let orderId = orderIdRef.current;
        await getPackingItemsByOrder({ orderId }).then(async (results) => {
          console.log("- results: ", results);
          setOrderItems(results);

          let currentOrderItems = _.cloneDeep(results);

          return axios
            .post(`packing-items/update-many`, {
              condition: { orderId },
              update: {
                count: currentOrderItems.length,
              },
            })
            .then(async (result) => {
              console.log("- updated count result", result);
              let orderQty = _.sumBy(currentOrderItems, (item) => {
                return item.isActive && parseInt(item.quantity);
              });

              await binHandler.mutate(orderQty);

              let checkedOutQty = _.sumBy(currentOrderItems, (item) => {
                return item.isActive && parseInt(item.checkedOutQty);
              });
              let quantity = _.sumBy(currentOrderItems, (item) => {
                return item.isActive && parseInt(item.quantity);
              });
              console.log(
                `total checkedOutQty: ${checkedOutQty}, quantity: ${quantity}`
              );

              let palyload = {
                condition: { orderId },
                update: {
                  checkedOutQty,
                  quantity,
                },
              };

              updateLocalOrder.mutate(palyload);
            });
        });
      },
    }
  );

  const orderItemSubmitHandler = async (e, orderItem) => {
    console.log("* orderItemSubmitHandler init");
    e.preventDefault();
    console.log("[orderItemSubmitHandler] orderItemMode: ", orderItemMode);
    // console.log("[orderItemSubmitHandler] orderItem: ", orderItem);
    setOrderItemModalIsOpen(false);
    if (orderItemMode === "edit") {
      await updateOrderItem.mutate(orderItem);
    } else {
      // console.log("orderItem in add mode", orderItem);
      const { orderId, orderNumber } = shipstationOrder || localOrder;

      const len = Boolean(orderItems.length) ? orderItems.length : 0;
      // console.log("orderItemsLen:", len);
      orderItem?._id && delete orderItem["_id"];
      const { component, description, imageUrl } = orderItem._item;
      const orderItemId = Math.floor(Math.random()*10000000).toString()
      let update = {
        ...orderItem,
        orderId,
        orderNumber,
        orderItemId,
        component,
        count: len + 1,
        sequence: Boolean(len) ? orderItems[len - 1].sequence + 1 : 1,
        checkedOutQty: 0,
        pickedQty: 0,
        name: description,
        isBundle: false,
        imageUrl: imageUrl,
      };

      // console.log("orderItem updated", orderItem);
      addOrderItem.mutate(update);
    }
  };

  const printShippingLabel = () => {
    console.log("* printShippingLabel init");
    let { shippingLabelName } = shipProcessResult;
    console.log("- shippingLabelName", shippingLabelName);
    if (shippingLabelName)
      socket.emit("on-shipping-label-print", shippingLabelName);
  };

  const showEditItemModal = ({ item, index }) => {
    console.log("* showEditItemModal init");
    // console.log('- item: ', item)
    // console.log('- index: ', index)
    const _item = item?._item ?? item._item;
    // console.log('- _item: ', _item)
    if (_item) {
      setSelectedItem(_item);
      setItemModalIsOpen(true);
    } else {
      console.log("- item info null");
      toast.info("Item profile not defined.");
    }
  };

  const updateItem = useMutation(
    async ({ condition, update }) => {
      return await submitItem({ condition, update });
    },
    {
      onMutate: async () => {
        setModalPreloaderIsOpen(true);
        if(isEditItemSubmitRef.current) isEditItemSubmitRef.current = false;
        await queryClient.cancelQueries(["orderItems", orderIdRef.current]);
      },
      onError: (error) => {
        console.log("updateItem error", error);
        const retval = errorHandler(error);
        toast.error(retval, {
          position: "bottom-right",
        });
      },
      onSuccess: (data) => {
        console.log("updateItem onSuccsess data: ", data);
        setModalPreloaderIsOpen(false);
        queryClient.invalidateQueries(["orderItems", orderIdRef.current]);
      },
    }
  );

  const editItemSubmitHandler = () => {
    console.log("* editItemSubmitHandler init");
    console.log("selectedItem", selectedItem);
    isEditItemSubmitRef.current = true

    itemModalIsOpen && setItemModalIsOpen(false);

    delete selectedItem?.stock;
    delete selectedItem?._graphics;
    delete selectedItem?._inventoryArea;

    updateItem.mutate({
      condition: { sku: selectedItem.sku },
      update: selectedItem,
    });
  };

  const removeLocalOrder = () => {
    console.log("* removeLocalOrder init");
    let orderId = orderIdRef.current;
    const confirm = window.confirm(
      "Confirm to remove this order. Restoring data is not available after removing an order"
    );
    if (confirm) {
      setModalPreloaderIsOpen(true);
      deleteLocalOrder(orderId)
        .then(async (result) => {
          console.log("[removeLocalOrder] deleteLocalOrder result: ", result)
          console.log("[removeLocalOrder] orderId: ", orderId);
          return await deletePackingItem(orderId);
        })
        .then(async () => {
          if (localOrder?.bin) {
            const url = `bins/update`;
            await axios.post(url, {
              condition: { binCode: localOrder.bin },
              update: { $set: { isActive: false } },
            });
          }

          setModalPreloaderIsOpen(false);
          history.push("/work-orders");
        });
    }
  };

  const createShipLabelErrHandler = (error) => {
    return new Promise((resolve, reject) => {
      let errorMsg;
      try {
        if (_.has(error.response.data, "ExceptionMessage")) {
          const exceptionMessage = error.response.data.ExceptionMessage;
          console.log("exceptionMessage", exceptionMessage);
          resolve({ message: exceptionMessage });
        } else {
          errorMsg = errorHandler(error);
          console.log("errorMsg", errorMsg);
          resolve({ message: errorMsg });
        }
      } catch (error) {
        console.log("catch error", error);
        errorMsg = errorHandler(error);
        console.log("errorMsg", errorMsg);
        reject({ message: errorMsg });
      }
    });
  };

  const updateCheckedOutQtyAll = () => {
    return new Promise((resolve, reject) => {
      let promises = orderItems.map((item) => {
        const payload = {
          condition: {
            id: item._id,
          },
          update: {
            $set: { checkedOutQty: item.quantity },
          },
        };
        return updatePackingItem(payload);
      });
      Promise.all(promises)
        .then(async (res) => {
          console.log("updatePackingItem promises res", res);
          let { orderId } = shipstationOrder || localOrder;
          
          let sumCheckedOutQty = _.sumBy(orderItems, "quantity");
          await updateOrder({
            condition: { orderId },
            update: { $set: { checkedOutQty: sumCheckedOutQty } },
          }).then(async (result) => {
            console.log("updateOrder result", result);
            resolve(true);
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const postHandleShipment = useMutation(
    async () => {
      console.log("* init postHandleShipment");
      let orderId = orderIdRef.current;
      return await updateCheckedOutQtyAll()
        .then((res) => {
          console.log("updateCheckedOutQtyAll res", res);

          return updateManyPackingItem({
            condition: { orderId },
            update: { $set: { isActive: false } },
          });
        })
        .then(async () => {
          let { bin } = localOrder;
          if (!_.isNil(bin)) {
            await updateBin({
              condition: { binCode: bin },
              update: { $set: { isActive: false } },
            });
          }
        });
    },
    {
      onMutate: () => {
        setModalPreloaderIsOpen(true);
      },
      onSuccess: async () => {
        setModalPreloaderIsOpen(false);
        let orderId = orderIdRef.current;
        updateLocalOrder.mutate({
          condition: { orderId },
          update: { orderStatus: "shipped" },
        });
        // queryClient.invalidateQueries(["orderItems", orderId]);

        await fetchOrderItems.mutate({ orderId });

        const confirm = window.confirm('Click OK to print packing slip')
        if(confirm) handlePrintPackingSlip()
      },
    }
  );

  const createShippingLabelHandler = useMutation(
    async ({ temp, orderNumber }) => {
      console.log("* createShippingLabelHandler init");
      return await createShippingLabel(temp, orderNumber, settings?.testLabel);
    },
    {
      onMutate: async () => {
        setModalPreloaderIsOpen(true);
        await queryClient.cancelQueries(["ssOrder", orderIdRef.current]);
      },
      onError: async (error) => {
        let errResponse = await createShipLabelErrHandler(error);
        console.log("- errResponse", errResponse);
        setModalPreloaderIsOpen(false);

        toast.error(errResponse.message, {
          position: "bottom-right",
          autoClose: false,
        });
      },
      onSuccess: (result, { orderNumber }) => {
        setModalPreloaderIsOpen(false);

        console.log("- result", result);
        console.log("- orderNumber", orderNumber);
        let shippingLabelName = `${orderNumber}.pdf`;
        let { shipmentCost, trackingNumber } = result;
        setShipProcessResult({
          shipmentCost,
          trackingNumber,
          shippingLabelName,
        });

        socket.emit("on-shipping-label-print", shippingLabelName);

        if (!settings?.testLabel) {
          postHandleShipment.mutate();
        } //end if

        queryClient.invalidateQueries(["ssOrder", orderIdRef.current]);
      },
    }
  );

  const handleShipment = async () => {
    console.log("** hanldeShipment init");
    let flag = _.isNil(shipstationOrder) ? "check-out" : "creation of shipping label";        
    let msg = `The purpose of batch shipment is to automatically process a ${flag} without individual scanning of items. Do you want to run?`;
    let confirm = window.confirm(msg);
    if (confirm) {
      if(shipstationOrder) {
        if (_.includes(shipstationOrder?.orderStatus, "awaiting")) {
          // if (settings?.orderStatus === "awaiting_shipment") {
          // create shipping label args
          let {
            orderId,
            orderNumber,
            carrierCode,
            serviceCode,
            confirmation,
            weight,
          } = shipstationOrder;
  
          let temp = {
            orderId,
            carrierCode,
            serviceCode,
            confirmation,
            weight,
            shipDate: moment().format("YYYY-MM-DD HH:mm:ss"),
          };
  
          createShippingLabelHandler.mutate({ temp, orderNumber });
        } else {
          Boolean(orderItems.length) && postHandleShipment.mutate();
        }
      } else {
        Boolean(orderItems.length) && postHandleShipment.mutate();
      }
    }
  };

  const handlePrintPackingSlip = async () => {
    console.log("== handlePrintPackingList start ==");
    setModalPreloaderIsOpen(true);
    console.log("- handlePrintPackingSlip shipstationOrder", shipstationOrder);
    let warehouseId;
    if(shipstationOrder) warehouseId = shipstationOrder?.advancedOptions?.warehouseId;
    console.log("- warehouseId", warehouseId);
    
    if (warehouseId) {
      try {
        let warehouse = await getWarehouse(warehouseId);
        // console.log("warehouse result", warehouse);
        setShipFrom(warehouse.originAddress);
        setModalPreloaderIsOpen(false);
      } catch (err) {
        console.log("- packingListTemplate err", err);
        setModalPreloaderIsOpen(false);
        alert(err);
      }
    } else {
      setModalPreloaderIsOpen(false);
    }

    const printContent = document.getElementById("print-section").innerHTML;

    const frame1 = document.createElement("iframe");
    frame1.name = "frame3";
    frame1.style.position = "absolute";
    frame1.style.top = "-100000000px";
    document.body.appendChild(frame1);

    const frameDoc = frame1.contentWindow
      ? frame1.contentWindow
      : frame1.contentDocument.document
      ? frame1.contentDocument.document
      : frame1.contentDocument;

    frameDoc.document.open();
    frameDoc.document.write("<html><head><title>Print Picking Slip</title>");
    frameDoc.document.write("<style>");
    frameDoc.document.write(
      "body,table,span{font-family:Arial,Helvetica,sans-serif;font-size:7pt}"
    );
    frameDoc.document.write(
      ".text-center{text-align:center}.text-right{text-align:right}"
    );
    frameDoc.document.write("table th{text-align:center}");
    frameDoc.document.write(
      "table{border-collapse:collapse;border:none;width:100%}"
    );
    frameDoc.document.write(
      "table thead th{border:#666 .5px solid;padding:5px;}"
    );
    frameDoc.document.write("table thead td{border:none;padding:7px;}");
    frameDoc.document.write(".print-section_items{margin-top:10px;}");
    frameDoc.document.write(".customer-guide-wrapper{margin-top: 20px}");
    frameDoc.document.write(
      "span.block{display:block;padding:10px 0;font-size:9pt}"
    );
    frameDoc.document.write("</style>");
    frameDoc.document.write('</head><body onafterprint="window.close()">');
    frameDoc.document.write(printContent);
    frameDoc.document.write("</body></html>");
    frameDoc.document.close();
    setTimeout(() => {
      window.frames["frame3"].focus();
      window.frames["frame3"].print();
      document.body.removeChild(frame1);
    }, 1000);
  };

  const handleGeneratePMT = async (e, item) => {
    console.log("* handleGeneratePMT init");
    // console.log("- item: ", item);
    e.preventDefault();
    if (settings?.useLocalRepository) {
      alert(
        "Generation of pretreatment barcode is not available in Use Local Repository mode."
      );
      return;
    }
    setModalPreloaderIsOpen(true);
    try {
      const result = await generatePMTHandler(item, settings);
      console.log("- result: ", result);
      const { pretreatmentBarcode, message } = result;
      if (pretreatmentBarcode) {
        setSelectedItem({
          ...item,
          pretreatmentBarcode: result.pretreatmentBarcode,
        });
        updateItem.mutate({
          condition: { sku: item.sku },
          update: { pretreatmentBarcode },
        });
      }
      if (message) {
        toast.error(message, {
          position: "bottom-right",
        });
      }
      setModalPreloaderIsOpen(false);
    } catch (error) {
      console.log("- generatePMTHandler error: ", error);
      setModalPreloaderIsOpen(false);
      toast.error(error, {
        position: "bottom-right",
      });
    }
  };

  const workOrderStatusHandler = useDebouncedCallback(
    ({ workOrderStatus }) => {
      console.log("* workOrderStatusHandler");
      let orderId = orderIdRef.current
      console.log("- orderId: ", orderId);
      console.log("- workOrderStatus: ", workOrderStatus);
      let payload = {
        condition: { orderId },
        update: { workOrderStatus },
      };
      updateLocalOrder.mutate(payload);
    },
    [500]
  );

  const WorkOrderStatus = React.memo(({ workOrderStatuses, order }) => {
    // console.log('* OrderStatus init')
    let workOrderStatus = order?.workOrderStatus ? order.workOrderStatus : null;
    // let orderId = order?.orderId;

    const styled = { backgroundColor: "#E1F2F6", color: "#1A567E" };

    const status = _.find(workOrderStatuses, { name: workOrderStatus });
    if (status) {
      // console.log('- status: ', status);
      styled.backgroundColor = status.color;
      styled.color = "#fff";
    }

    return (
      <select
        defaultValue={workOrderStatus ? workOrderStatus : ""}
        onChange={(e) => {
          workOrderStatusHandler({ workOrderStatus: e.target.value });
        }}
        className="work-order-status ml-10"
        style={styled}
      >
        <option
          value=""
          style={{ backgroundColor: "#E1F2F6", color: "#1A567E" }}
        >
          = Order Status
        </option>
        {workOrderStatuses.map((s) => {
          return (
            <option
              value={s.name}
              key={s._id}
              style={{
                backgroundColor: s.color,
                color: "#fff",
              }}
            >
              {s.name}
            </option>
          );
        })}
      </select>
    );
  });

  const handleSearch = async (searchTxt) => {
    console.log("* handleSearch init");
    await searchByOrderNumber(searchTxt)
      .then((result) => {
        console.log("- result: ", result);
        if (result?.orderId) {
          toast.info(`Moving the order details: ${searchTxt}`, {
            position: "bottom-right",
            onClose: () =>
              props.history.replace(`/workorder-details/${result.orderId}`),
            autoClose: 2000,
          });
        }
      })
      .catch((error) => {
        console.log("- error: ", error);
        toast.error("Not found!", {
          position: "bottom-right",
          pauseOnFocusLoss: false,
          // onClose: () => setSearchTxt("")
        });
        return false;
      });
  };

  const onAfterCloseModal = () => {
    console.log("* onAfterCloseModal init");
    if(isEditItemSubmitRef.current) fetchOrderItems.mutate({ orderId: orderIdRef.current });
  };

  return (
    <>
      <Title title={"Order Details"} />
      <ToastContainer theme="dark" />
      <PageMainHeader title={title} user={user} settings={settings} />
      <section className="primary">
        {_.isEmpty(localOrder) && shipstationOrder && (
          <Banner className="lg-banner">Not a synced order</Banner>
        )}
        {msg && <Banner variant={bannerVariantRef.current} className="mb-10">{msg}</Banner>}
        {localOrder && _.isNil(shipProcessResult) && (
          <PageMenuHeader>
            <div className="btn-menu-list">
              {user && user?.role === "admin" && (
                <Button
                  variant="danger"
                  className="swing-icon"
                  onClick={() => removeLocalOrder()}
                  leftGlyph={<FaRemoveFormat />}
                >
                  Remove this order
                </Button>
              )}
              <Button
                variant="primary"
                className="swing-icon"
                onClick={() => orderItemHandler({ type: "add" })}
                leftGlyph={<FaAddressBook />}
              >
                Add an item
              </Button>
              <Button
                variant="primary"
                className={`swing-icon ${
                  !_.isNil(shipProcessResult) ? "hidden" : ""
                }`}
                onClick={() => handleShipment()}
                disabled={!_.isNil(shipProcessResult) || (shipstationOrder && shipstationOrder?.orderStatus === 'shipped') || localOrder?.orderStatus === 'shipped'}
                leftGlyph={<FaShip />}
              >
                Batch shipment
              </Button>
              {settings?.printPackingSlip && (
                <Button
                  variant="primary"
                  onClick={() => handlePrintPackingSlip()}
                  className="swing-icon"
                  leftGlyph={<FaPrint />}
                >
                  Print packing slip
                </Button>
              )}
              {localOrder?.data?.bin && (
                <Button
                  variant="primary"
                  className="swing-icon"
                  onClick={() => {
                    let confirm = window.confirm("Confirm re-assign Bin?");
                    if (confirm) reAssignBin.mutate();
                  }}
                  leftGlyph={<FaCreativeCommonsSampling />}
                >
                  Re-assign BIN
                </Button>
              )}
              {settings?.useWorkOrderStatus && localOrder && (
                <WorkOrderStatus
                  workOrderStatuses={settings?.workOrderStatuses}
                  order={localOrder}
                />
              )}
            </div>
            <Search
              handleSearch={handleSearch}
              placeholderText="Search by order number"
            />
          </PageMenuHeader>
        )}
        {shipProcessResult && (
          <div className="title-content-list card">
            <div className="title-content">
              <span className="title">Shipment Cost</span>
              <span className="content">
                {shipProcessResult?.shipmentCost?.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </span>
            </div>
            <div className="title-content">
              <span className="title">Tracking Number</span>
              <span className="content">
                {shipProcessResult?.trackingNumber}
              </span>
            </div>
            <div className="title-content ml-10">
              <span className="title">Print Label</span>
              <IconButton
                className="swing-icon"
                onClick={printShippingLabel}
                aria-labelledby="Print a shipping label"
              >
                <FaPrint />
              </IconButton>
            </div>
          </div>
        )}
        {(shipstationOrder || localOrder) && (
          <WorkOrderDetailsHeader
            ssOrder={shipstationOrder}
            localOrder={localOrder}
            isLoading={isLoading}
            settings={settings}
          />

        )}

        {fetchOrderItems.isLoading ? (
          <Skeleton count={10} height={50} />
        ) : fetchOrderItems.isError ? (
          <Banner variant="danger">
            {fetchOrderItems?.error?.message}
          </Banner>
        ) : (
          settings && (
            <WorkOrderDetailsItems
              items={orderItems}
              handlePrintLabel={printOrderItemLabel}
              orderItemHandler={orderItemHandler}
              showEditItemModal={showEditItemModal}
              platens={platens}
              settings={settings}
              shipstationOrder={shipstationOrder}
              user={user}
            />
          )
        )}

        {/* {(orderItems && Boolean(orderItems.length) && settings) && (
          <WorkOrderDetailsItems
            items={orderItems}
            handlePrintLabel={printOrderItemLabel}
            orderItemHandler={orderItemHandler}
            showEditItemModal={showEditItemModal}
            platens={platens}
            settings={settings}
            shipstationOrder={shipstationOrder}
            user={user}
          />
        )} */}
      </section>

      <OrderItemModal
        modalIsOpen={orderItemModalIsOpen}
        setModalIsOpen={setOrderItemModalIsOpen}
        item={selectedItem}
        editOrderItemSubmitHandler={orderItemSubmitHandler}
        title={"Order Item"}
        mode={orderItemMode}
      />

      <ModalItem
        modalIsOpen={itemModalIsOpen}
        setModalIsOpen={setItemModalIsOpen}
        title={"Item Profile"}
        mode={"update"}
        item={selectedItem}
        setItem={setSelectedItem}
        submitHandler={editItemSubmitHandler}
        platens={platens}
        settings={settings}
        handleGeneratePMT={handleGeneratePMT}
        user={user}
        onAfterCloseModal={onAfterCloseModal}
      />

      {orderItems && (
        <PackingSlipTemplate
          shipFrom={shipFrom}
          ssOrder={shipstationOrder}
          localOrder={localOrder}
          orderItems={orderItems}
          settings={settings}
        />
      )}

      <ModalPreloader modalPreloaderIsOpen={modalPreloaderIsOpen} />

      {/* <pre>{order && JSON.stringify(order, null, 2)}</pre> */}
    </>
  );
};

export default WorkOrderDetails;
