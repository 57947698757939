import { axiosInstance as axios } from "configs/axiosConfig";
import { errorHandler } from "./errorHandler";

export const fetchPrintedFilms = (data) => {
  return new Promise((resolve, reject) => {
    try {
      const url = `/printed-film`;
      axios.post(url, data).then((res) => {
        resolve(res.data);
      });
    } catch (error) {
      let retval = errorHandler(error);
      console.log("-retval: ", retval);
      reject(retval);
    }
  });
};

export const updatePrintedFilm = (data) => {
  return new Promise((resolve, reject) => {
    try {
      const url = `/printed-film/update`;
      axios.post(url, data).then((res) => {
        resolve(res.data);
      });
    } catch (error) {
      let retval = errorHandler(error);
      console.log("-retval: ", retval);
      reject(retval);
    }
  });
};

export const deletePrintedFilm = (data) => {
  return new Promise((resolve, reject) => {
    try {
      const url = `/printed-film/delete`;
      axios.post(url, data).then((res) => {
        resolve(res.data);
      });
    } catch (error) {
      let retval = errorHandler(error);
      console.log("-retval: ", retval);
      reject(retval);
    }
  });
};

export const deletePrintedFilmByGraphicFilename = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      const url = `/printed-film/delete-by-sku`;
      axios.post(url, payload).then((res) => {
        resolve(res.data);
      });
    } catch (error) {
      let retval = errorHandler(error);
      console.log("-retval: ", retval);
      reject(retval);
    }
  });
};

export const searchPrintedFilms = (data) => {
  return new Promise((resolve, reject) => {
    try {
      const url = `/printed-film/search`;
      axios.post(url, data).then((res) => {
        resolve(res.data);
      });
    } catch (error) {
      let retval = errorHandler(error);
      console.log("-retval: ", retval);
      reject(retval);
    }
  });
};

export const updatePrintedFilmStock = (data) => {
  return new Promise((resolve, reject) => {
    try {
      const url = `/printed-film/update-stock`;
      axios.post(url, data).then((res) => {
        resolve(res.data);
      });
    } catch (error) {
      let retval = errorHandler(error);
      console.log("-retval: ", retval);
      reject(retval);
    }
  });
};
