import { getPackingItemsByOrder } from "./packingItems";
import _ from "lodash";
import { errorHandler } from "./errorHandler";

export const searchByOrderNumber = (searchTxt) => {
  console.log("* handleSearch init");
  return new Promise(async (resolve, reject) => {
    console.log("- searchTxt", searchTxt);
    let orderId;
    //let local, order;
    if (!_.isEmpty(searchTxt)) {
      await getPackingItemsByOrder({ orderNumber: searchTxt })
        .then(async (results) => {
          // console.log('- getPackingItemsByOrder results: ', results)
          if (Boolean(results.length)) {
            orderId = results[0]?.orderId ?? results[0].orderId;
            console.log("- orderId: ", orderId);
            resolve({ orderId });
          } else {
            throw new Error("Not found");
          }
        })
        .catch((error) => {
          console.log("- getPackingItemsByOrder error: ", error);
          let retval = errorHandler(error);
          reject(retval);
        });
    }
  });
};
