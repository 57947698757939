import React from 'react'
import _ from 'lodash'

export default function InventoryTransactionItem({ item }) {
  
  function InventoryArea({ inventories }) {
    return _.map(inventories, (inv) => {
      return <span className="each-inventory-area" key={inv._id}>{inv.areaCode} : {inv.stock}</span>
    })
  }

  return (
    <div className="title-content-list card justify-content-space mb-10">
      <div className="d-flex">
        <div className="title-content">
          <span className="title">SKU</span>
          <span className="content">{item.sku}</span>
        </div>
        <div className="title-content">
          <span className="title">Description</span>
          <span className="content">{item.description}</span>
        </div>
        <div className="title-content">
          <span className="title">Category</span>
          <span className="content">{item.category}</span>
        </div>
        <div className="title-content">
          <span className="title">Inventory Area</span>
          <span className="content">
            {_.includes(item.type, "prod")
              ? (
                _.has(item, "_inventoryArea")
                  ? (item._inventoryArea.length > 1 ? "Multiple locations" : Boolean(item._inventoryArea.length) ? item._inventoryArea[0].areaCode : "")
                  : ""
              )
              : (item.inventoryArea)
            }
          </span>
        </div>
        <div className="title-content">
          <span className="title">Type</span>
          <span className="content">{item.type}</span>
        </div>
        <div className="title-content">
          <span className="title">Stock</span>
          <span className="content">
            {_.includes(item.type, "prod")
              ? (
                _.has(item, "_inventoryArea")
                  ? _.sumBy(item._inventoryArea, "stock")
                  : 0
              )
              : (item.stock)
            }
          </span>
        </div>
        {
          _.has(item, "_inventoryArea") && Boolean(item._inventoryArea.length) && (
            <div className="title-content">
              <span className="title">Inventory Area(s)</span>
              <span className="content">
                <InventoryArea inventories={item._inventoryArea} />
              </span>
            </div>
          )
        }
      </div>

    </div>
  )
}

