import React from 'react'
import Notification from "react-web-notification";

export default function PrintfloNotification() {
  console.log('* PrintfloNotification init')
  const [notificationIgnore, setNotoficationIgnore] = React.useState(true);
  const options = {
    icon: "32x32.png",
    body: `
      v3.4.1 updated\r\nAllows editing of graphics and inventories in production profile
    `
  }

  return (
    <Notification
      ignore={notificationIgnore}
      notSupported={()=> console.log('-not supported')}
      onPermissionGranted={() => console.log('- permission granted')}
      onPermissionDenied={() => {console.log('- pesrmission denied');setNotoficationIgnore(true)}}
      onShow={() => console.log('-shown')}
      onClick={() => console.log('-clicked')}
      onClose={() => console.log('-closed')}
      onError={() => console.log('-error')}
      timeout={5000}
      title="Printflo Notification"
      options={options}
    />
  )
}
