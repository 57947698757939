import React from "react";
import _ from "lodash";
import moment from "moment";
// import QRCode from "react-qr-code";

export default function PackingListPrint({ packingItems }) {
  console.log('* PackingListPrint init')
  // console.log("- packingItems", packingItems);

  return (
    <div id="print-section" className="hidden">
      <div
        className="print-section-title"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <h3>Packing List</h3>
      </div>
      <div>
        {Boolean(packingItems?.length) &&
          <div style={{
            pageBreakAfter: "always"
          }}>
            <div style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}>
              <span>{moment().local().format("YYYY-MM-DD h:mm a")}</span>
            </div>
            
            <table>
              <thead>
                <tr>
                  <th>No</th>
                  <th>Bin</th>
                  <th>Order #</th>
                  <th>SKU</th>
                  <th>Ordered</th>
                  <th>Picked</th>
                  <th>Printed</th>
                  <th>Checked-out</th>
                  <th>Order Date</th>
                  {/* <th>Synced At</th> */}
                  {/* <th>QR code</th> */}
                </tr>
              </thead>
              <tbody>
                {packingItems.map((item, index) => (
                  <tr key={index} className={item.quantity<=item.checkedOutQty ? 'hidden' : ''}>
                    <td>{index+1}</td>
                    <td>{item?._order?.bin}</td>
                    <td>{item?.orderNumber}</td>
                    <td>{item?.sku}</td>
                    <td>{item?.quantity}</td>
                    <td>{item?.pickedQty}</td>
                    <td>{item?.printed ? _.sumBy(item.printed, "qty") : 0}</td>
                    <td>{item?.checkedOutQty}</td>
                    <td>{item?._order?.orderDate ? moment(item._order.orderDate).format("MM-DD-YYYY") : ""}</td>
                    {/* <td>{item?.createdAt ? moment(item.createdAt).format("MM-DD-YYYY") : ""}</td> */}
                    {/* <td>{<QRCode value={item.sku+'*'+item.orderId} size={50} level='Q'/>}</td> */}
                  
                  </tr>
                ))}
              </tbody>
            </table>

          </div>
        }
      </div>
    </div>
  );
}


/*
            <table>
              <thead>
              <tr>
                <th>No</th>
                <th style={{ minWidth: "100px" }}>SKU</th>
                <th>Location</th>
                <th>Qauntity</th>
                <th>Category</th>
                <th>Color</th>
                </tr>
              </thead>
              <tbody>
                {finishedGoods.map((fg, index) => {
                  return (
                    <tr key={index}>
                      <td>{index+1}</td>
                      <td>{fg?.sku}</td>
                      <td>
                        {fg?.picking_suggests.map(p => {
                          return (
                            <span key={p.areaCode} style={{ border: "thin solid grey", padding: "2px", borderRadius: "2px", marginRight: "2px" }}>
                              {p?.areaCode}: {p?.picking_qty}
                            </span>
                          )
                        })}
                      </td>
                      <td className="text-center">
                        { _.sumBy(fg?.packing_docs, 'quantity') - _.sumBy(fg?.packing_docs, 'pickedQty') }
                      </td>

                      <td>
                        {fg?.category}
                      </td>

                      <td>{fg?.color}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
*/
