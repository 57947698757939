import { axiosInstance as axios } from "configs/axiosConfig";
import { errorHandler } from "./errorHandler";

export const getSettings = () => {
  return new Promise((resolve, reject) => {
    const storedSettings = window.localStorage.getItem("_settings");
    // console.log("- storedSettings: ", storedSettings);
    if (storedSettings) {
      resolve(JSON.parse(storedSettings));
      return;
    } else {
      const url = `/settings`;
      axios
        .get(url)
        .then((res) => {
          // console.log("getSettings res", res.data);
          const settings = res.data;
          window.localStorage.setItem("_settings", JSON.stringify(settings));
          resolve(settings);
        })
        .catch((error) => {
          let retval = errorHandler(error);
          console.log("getSettings error retval: ", retval);
          reject(retval);
        });
    }
  });
};
