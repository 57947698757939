import BatchDelete from 'components/BatchDelete';
import FileImportExport from 'components/FileImportExport';
import React from 'react'
import { FaBolt, FaPlus } from 'react-icons/fa';
import Button from "@leafygreen-ui/button"

export default function FinishedGoodsInventoryHeader({ showModal, importHandler, exportHandler, handleSearch, selectedExportMode, setSelectedExportMode, batchDeleteHandler }) {
  return (
    <div className="page-header card p-10">
      <div className='btn-menu-list'>
        <Button
          variant="primary"
          className="swing-icon"
          title="Reload"
          leftGlyph={<FaBolt />}
          onClick={() => {
            window.location.reload();
          }}
        >
          Refresh
        </Button>

        <Button
          variant="primary"
          className="swing-icon"
          leftGlyph={<FaPlus />}

          onClick={() => {
            showModal("create");
          }}
        >
          Add
        </Button>

        <FileImportExport
          importHandler={importHandler}
          exportHandler={exportHandler}
          selectedExportMode={selectedExportMode}
          setSelectedExportMode={setSelectedExportMode}
        />

        <BatchDelete batchDeleteHandler={batchDeleteHandler} />
      </div>

    </div>
  )
}
