export const skuLabelTemplateQR = ({ item, printQty = 1 }) => {
  console.log("* skuLabelTemplateQR init");
  // console.log("- item", item);
  let data = "";
  data = "^xa";
  data += "^lh5,5"; // home position
  data += "^fo22,25^bq,2,3^fda," + item?.sku + "^fs";
  data += "^fo130,40^abn,15,15^fd" + item?.sku + "^fs";
  data +=
    "^fo130,60^abn,15,15^fd" + (item?.category ? item?.category : "") + "^fs";
  data += "^fo130,80^abn,15,15^fd" + (item?.color ? item?.color : "") + "^fs";
  data +=
    "^fo130,100^abn,10,10^fd" +
    (item?.description ? item?.description : "") +
    "^fs";
  data += "^pq" + printQty;
  data += "^xz";
  // eslint-disable-next-line
  return data;
};

export const skuLabelTemplateBar = ({ item, printQty = 1 }) => {
  console.log("* skuLabelTemplateBar init");
  // console.log("- item", item);
  let data = "";
  data = "^xa";
  data += "^lh5,5"; // home position
  data += "^fo22,25^abn,10,15^fd" + item?.sku + "^fs";
  data += "^by1,2";
  data += "^fo20,48^bcn,40,n,n,n^fd" + item?.sku + "^fs";
  data +=
    "^fo20,100^abn,15,15^fd" + (item?.category ? item?.category : "") + "^fs";
  data += "^fo20,120^abn,15,15^fd" + (item?.color ? item?.color : "") + "^fs";
  data +=
    "^fo20,140^abn,10,10^fd" +
    (item?.description ? item?.description : "") +
    "^fs";
  data += "^pq" + printQty;
  data += "^xz";
  // eslint-disable-next-line
  return data;
};
