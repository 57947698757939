import React, {useState, useEffect} from 'react'
import { useQuery, useQueryClient } from 'react-query';
import { ToastContainer, toast } from 'react-toastify';
import { format } from "date-fns"
import { axiosInstance as axios } from 'configs/axiosConfig';
import { updateManyPackingItem } from 'utils/packingItems';
import Pagination from 'utils/Pagination';
import LogsList from './LogsList';
import Skeleton from 'react-loading-skeleton'
import { Title } from 'utils/Title'
import Banner from "@leafygreen-ui/banner"
import { AuthContext } from "contexts/AuthContext"
import { SettingsContext } from 'contexts/SettingsContext'
import PageMainHeader from 'components/PageMainHeader';

const title = 'Logs'
const limit = 10;

const fetchLogs = (page) => {
  return new Promise((resolve, reject) => {
    try {
      const url = `/log/`;
      axios.get(url, {params: {page, limit}}).then(response => {
        if (response?.data) {
          resolve(response.data);
        }
      })
    } catch (error) {
      reject(error);
    }
  })
}

export default function Logs() {
  const [page, setPage] = useState(1);
  const { settings } = React.useContext(SettingsContext);
  const { user } = React.useContext(AuthContext);

  const queryClient = useQueryClient();
  const { data: logs, isLoading, isError, error } = useQuery(["logs", page],
    async () => {
      return await fetchLogs(page)
    }, {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5,
  })
  // console.log("- logs: ", logs);

  useEffect(() => {
    // logs?.docs && console.log("logs in hook: ");
    if (logs?.totalPages > page) {
      queryClient.prefetchQuery(
        ["logs", page + 1],
        () => fetchLogs(page + 1),
        {
          keepPreviousData: true,
          refetchOnWindowFocus: false,
          staleTime: 1000 * 60 * 5,
        }
      );
    }
    // eslint-disable-next-line
  }, [logs, page, queryClient]);

  const handleOnPageChange = ({ selected }) => {
    console.log("* handleOnPageChange init")
    // console.log("- selected: ", selected);
    setPage(selected + 1);
  };

  const restorePickedQty = async (timestamp) => {
    console.log('* restorePickedQty init')
    console.log('- timestamp: ', timestamp)
    const confirm = window.confirm(`Confirm to restore picked quantity from ${format(new Date(timestamp), "MM-dd-yyy hh:mm:ss b")}`)
    if (confirm) {
      updateManyPackingItem({
        condition: { isActive: true, createdAt: { $gte: new Date(timestamp) } },
        update: {$set: {pickedQty: 0}}
      }).then(response => {
        console.log('- response: ', response)
        toast.info(`Successfully restored ${response?.nModified} packing item(s)`, {
          position: "bottom-right"
        })
      })
      
    }
  }

  return (
    <>
      <Title title={title} />
      <ToastContainer theme='dark' />
      <PageMainHeader 
        title={title} 
        user={user} 
        settings={settings} 
      />
      <section className="primary">
        {isLoading ? (
          <Skeleton count={20} height={50} circle={true} />
        ) : isError ? (
          <Banner variant='danger'>{error?.message ? error.message : error}</Banner>
        ) : (
          null
        )}
        {
          logs && <Pagination
          handleOnPageChange={handleOnPageChange}
          totalDocs={logs?.totalDocs}
          totalPages={logs?.totalPages}
          page={page}
          limit={limit}
          />
        }
        {
          logs?.docs && Boolean(logs.docs.length) 
          ? (<LogsList logs={logs.docs} restorePickedQty={restorePickedQty} />) 
          : (<div className="card"><span>* Logs not found.</span></div>)
        }
      </section>
    </>
  )
}
