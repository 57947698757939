import log from "loglevel";
import remote from "loglevel-plugin-remote";

log.enableAll();

const customJson = (log) => {
  const user = window.localStorage.getItem("_auth");
  const hostname = window.localStorage.getItem("_hostname");
  const { username } = JSON.parse(user);
  log.level = log.level.label;
  return {...log, username, hostname}
}

remote.apply(log, { format: customJson, url: "/api/log/add" });

export default log;
