import React, { memo, useRef } from "react";
import ModalTemplate from "./ModalTemplate";
import { FaSave, FaTrashAlt } from "react-icons/fa";
import Button from "@leafygreen-ui/button";
import DatePicker from "react-datepicker";

function ModalManualOrder({
  modalIsOpen,
  setModalIsOpen,
  title,
  mode,
  order,
  setOrder,
  submitHandler,
  handleDelete,
}) {
  // console.log("* ModalManualOrder init ==");
  // console.log("- mode", mode);
  // console.log("- order", order);

  const formRef = useRef();
  // console.log("formRef", formRef);

  const handleSubmit = (e) => {
    e.preventDefault();
    formRef.current.reportValidity();
    if (formRef.current.checkValidity()) submitHandler();
  };

  const onChangeHandler = (e) => {
    // console.log('* onChangeHandler init')
    // console.log(e.target.id, e.target.value);
    if(e.target.id.includes('shipTo')) {
      setOrder({ ...order, shipTo: {name: e.target.value.trim()}  });
    } else {
      setOrder({ ...order, [e.target.id]: e.target.value.trim() });
    }
  };

  return (
    <ModalTemplate
      title={title}
      subTitle={mode}
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}
      modalSize={{ width: "500px", height: "450px" }}
    >
      <form ref={formRef} className="card modal-form" >
        <div>
          <div className="input-field">
            <label htmlFor="description">
              Date
            </label>
            <DatePicker
              selected={order?.orderDate ? new Date(order.orderDate): new Date()}
              className="browser-default"
              onChange={(date) => setOrder({...order, orderDate: new Date(date).toISOString()})}
            />
          </div>
          <div className="input-field">
            <label htmlFor="orderNumber">
              Order Number
            </label>
            <input
              id="orderNumber"
              type="text"
              defaultValue={order?.orderNumber ? order.orderNumber: ""}
              onChange={onChangeHandler}
              placeholder="order number"
              required={true}
              disabled={mode === "edit"}
              autoFocus={mode === "create"}
            />
          </div>
          
          <div className="input-field">
            <label htmlFor="shipTo.name">
              Recipient
            </label>
            <input
              id="shipTo.name"
              type="text"
              defaultValue={order?.shipTo?.name ? order?.shipTo?.name : ""}
              onChange={onChangeHandler}
              placeholder="recipient"
              required={true}
            />
          </div>
        </div>  
        <div className="modal-footer">
          {(mode!=='create') && (
            <Button
              variant="danger"
              className="swing-icon"
              onClick={() => handleDelete(order)}
              leftGlyph={<FaTrashAlt />}
            >
              Delete
            </Button>
          )}

          <Button
            variant="primary"
            onClick={handleSubmit} 
            className="swing-icon"
            leftGlyph={<FaSave />}
          >
            Submit
          </Button>
          
        </div>
      </form>
    </ModalTemplate>
  )
}

export default memo(ModalManualOrder)
