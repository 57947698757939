import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import {Table, TableHeader, Row, Cell } from "@leafygreen-ui/table";
import { FaBold, FaCheckCircle, FaTimesCircle } from "react-icons/fa";

const PackingItemsUnderOrderId = ({ awaitItems }) => {
  console.log("* PackingItemsUnderOrderId init")
  console.log("- awaitItems: ", awaitItems);

  return (
    <div className="card packing-items_under-order_id p-10">
      <div>
        <span style={{ fontSize: "1rem", fontWeight: "bolder" }}>
          {Boolean(awaitItems.length)
            ? ":: Awaiting Items"
            : ":: Single SKU in this order"}
        </span>
      </div>

      {
        Boolean(awaitItems.length) && (
          <Table
            data={awaitItems}
            columns={[
              <TableHeader label="Status" />,
              <TableHeader label="Bin" />,
              <TableHeader label="Order#" />,
              <TableHeader label="SKU" />,
              <TableHeader label="Ordered" dataType="number" />,
              <TableHeader label="Printed" dataType="number" />,
              <TableHeader label="Checked-out" dataType="number" />,
              <TableHeader label="Mockup" />,
            ]}
          >
            {
              ({datum}) => (
                <Row key={datum._id}>
                  <Cell>
                    {
                      (datum.quantity ===datum.checkedOutQty) 
                      ? <FaCheckCircle size="1.5em" />
                      : <FaTimesCircle size="1.5em"/>
                    }
                  </Cell>
                  <Cell>{datum?._order?.bin ? datum._order.bin : ""}</Cell>
                  <Cell>
                    <Link to={`workorder-details/${datum.orderId}`}>
                      <span>
                        {datum?.orderNumber ? datum.orderNumber : ""}
                      </span>
                    </Link>
                    <span className="block">
                      {datum?.sequence} / {datum?.count}
                    </span>
                  </Cell>
                  <Cell>
                    <span className="block">{datum?.sku}</span>
                    {datum?.isBundle && (<FaBold />)}
                  </Cell>
                  <Cell>{datum?.quantity ? datum.quantity : 0}</Cell>
                  <Cell>{datum?.printed ? _.sumBy(datum.printed, "qty") : 0}</Cell>
                  <Cell>{datum?.checkedOutQty ? datum.checkedOutQty : 0}</Cell>
                  <Cell>
                    {
                      datum?._item?.imageUrl && (
                        <div style={{maxWidth: 80}}>
                          <img
                            src={datum._item.imageUrl}
                            className="responsive-img"
                            alt={datum.sku}
                            title={datum.sku}
                            onError={(e) =>
                              (e.target.src = `${process.env.REACT_APP_S3_NOIMG}/50.png`)
                            }
                          />
                        </div>
                      )
                    } 
                  </Cell>
                </Row>
              )
            }
          </Table>
        )
      }      
    </div>
  );
};

export default React.memo(PackingItemsUnderOrderId);
