import React, { useState } from 'react'
import { axiosInstance as axios } from 'configs/axiosConfig'
import { Title } from 'utils/Title'
import { errorHandler } from 'utils/errorHandler'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import Skeleton from 'react-loading-skeleton'
import { toast, ToastContainer } from 'react-toastify'
import UsersList from './UsersList'
import { FaBolt, FaPlus } from "react-icons/fa";
import ModalUser from 'components/modals/ModalUser'
import PageMenuHeader from 'components/PageMenuHeader'
import PageMainHeader from 'components/PageMainHeader'
import Banner from '@leafygreen-ui/banner'
import Button from '@leafygreen-ui/button'
import ScrollTop from 'utils/ScrollTop'

const title = 'Users'

const initialUser = {
  username: "",
  password: "",
  role: "",
}

const fetchUsers = async () => {
  return new Promise((resolve, reject) => {
    const url = `/auth/users`;
    axios.get(url)
      .then((res) => {
        // console.log("getUsers res", res);
        const parsedUsers = res.data.map((u) => {
          return { ...u, password: atob(u.password) };
        });
        setTimeout(() => {
          resolve(parsedUsers);
        }, 2000);
      })
      .catch((error) => {
        let retval = errorHandler(error)
        reject(retval);
      });
  })
};

export default function Users() {
  const [selectedUser, setSelectedUser] = useState(initialUser);
  const [msg, setMsg] = useState(null);
  const [mode, setMode] = useState("create");
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const queryClient = useQueryClient();

  const { data: users, isLoading, isError, error } = useQuery("users", fetchUsers, {
    refetchOnWindowFocus: false, staleTime: 1000 * 60 * 5
  })

  // console.log('- selectedUser: ', selectedUser)

  const showModalHandler = ({selectedUser, mode}) => {
    // console.log("- mode", mode);
    setMode(mode);
    if (msg) setMsg(null);
    if (selectedUser) {setSelectedUser(selectedUser)} else {setSelectedUser(initialUser)};
    setModalIsOpen(true)
  };

  const mutateUser = useMutation(async () => {
    console.log("== mutateUser init ==");
    const url = `/auth/${mode}-user`;
    return await axios.post(url, selectedUser)
  }, {
    onError: (error) => {
      let retval = errorHandler(error)
      setMsg(retval);
    },
    onSuccess: (response) => {
      // console.log("onSuccess response: ", response)
      toast.info(response?.data?.message, {
        position: "bottom-right"
      })
      queryClient.invalidateQueries("users");
    }
  })

  const submitHandler = () => {
    console.log("== submitHandler init ==");
    // console.log("selectedUser: ", selectedUser);
    msg && setMsg(null);
    setModalIsOpen(false)
    mutateUser.mutate()
  };

  const deleteUser = useMutation(async () => {
    const url = `/auth/delete-user`;
    return await axios.post(url, { id: selectedUser._id })
  }, {
    onError: (error) => {
      let retval = errorHandler(error)
      setMsg(retval);
    },
    onSuccess: (response) => {
      // console.log("onSuccess response: ", response)
      toast.info(response?.data?.message, {
        position: "bottom-right"
      })
      queryClient.invalidateQueries("users");
    }
  })

  const handleDelete = () => {
    console.log("== handleDelete init ==");
    // console.log("selectedUser: ", selectedUser);
    modalIsOpen && setModalIsOpen(false)
    msg && setMsg(null);
    deleteUser.mutate();
  };
  
  return (
    <>
      <Title title={title} />
      <PageMainHeader title={title} />
      <ToastContainer theme='dark'/>
      <section className="primary">
        {isLoading ? (
          <Skeleton count={20} height={50} circle={true} />
        ) : isError ? (
          <Banner variant='danger'>
            {error?.message ? error.message : error}
          </Banner>
        ) : (
          <>
            {msg && <Banner>{msg}</Banner>}
            <PageMenuHeader>
              <div className="btn-menu-list">
                <Button
                  variant="primary"
                  className="swing-icon"
                  title="Reload"
                  leftGlyph={<FaBolt />}
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Refresh
                </Button>

                <Button
                  variant="primary"
                  className="swing-icon"
                  leftGlyph={<FaPlus />}

                  onClick={() => {
                    showModalHandler({mode: "create"});
                  }}
                >
                  Add
                </Button>
              </div>      
            </PageMenuHeader>
            {
              (users && Boolean(users?.length))
                ? (<UsersList users={users} showModal={showModalHandler} />)
                : (<Banner>Users not found.</Banner>)
            }
          </>      
        )}
        <ModalUser 
          modalIsOpen={modalIsOpen}
          setModalIsOpen={setModalIsOpen}
          title={"User"}
          mode={mode}
          user={selectedUser}
          setUser={setSelectedUser}
          submitHandler={submitHandler}
          handleDelete ={handleDelete}
        />
        <ScrollTop />
      </section>
    </>
  )
}
