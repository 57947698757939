import { format } from "date-fns";

export const filmLabelTemplate = ({
  graphicFilename,
  printedFilmInventoryLocation,
  printQty,
}) => {
  console.log("* filmLabelTemplate init");
  let data = "";
  data = "^xa";
  data += "^lh5,5"; // home position
  data += "^fo22,25^bq,2,3^fda," + graphicFilename + "^fs";
  data += "^fo130,40^abn,17,15^fd" + graphicFilename + "^fs";
  data += "^fo130,80^abn,17,15^fd" + printedFilmInventoryLocation + "^fs";
  data +=
    "^fo22,170^abn,15,15^fd" +
    format(new Date(), "MM/dd/yy hh:mm:ss a") +
    "^fs";
  data += "^pq" + printQty;
  data += "^xz";
  // eslint-disable-next-line
  return data;
};
