import React, { useState, useEffect, useContext } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import moment from "moment";
import socketIOClient from "socket.io-client";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { ToastContainer } from "react-toastify";
import Pagination from "utils/Pagination";
import { Title } from "utils/Title";
import { errorHandler } from "utils/errorHandler";
import { getItemDoc } from "utils/items";
import FinishedGoodsInventoryHeader from "./FinishedGoodsInventoryHeader";
import FinishedGoodsInventoryList from "./FinishedGoodsInventoryList";
import ModalFinishedGoodsInventory from "./ModalFinishedGoodsInventory";
import { jsonToCSV } from "react-papaparse";
import { SettingsContext } from "contexts/SettingsContext";
import { AuthContext } from "contexts/AuthContext";
import PageMainHeader from "components/PageMainHeader";
import Banner from "@leafygreen-ui/banner";
import PageMenuHeader from "components/PageMenuHeader";
import Search from "components/Search";
import { ModalPreloader } from "utils/Preloader";
import ScrollTop from "utils/ScrollTop";
import {
  getInventoryAreas,
  deleteInventoryArea,
  submitInventoryArea,
  searchInventoryAreas,
  deleteInventoryAreaBySku,
} from "utils/inventoryArea";

const title = "Finished Goods Inventory Status";
const socket = socketIOClient(process.env.REACT_APP_SOCKET_ENDPOINT);
const initialInventoryArea = {
  sku: "",
  areaCode: "",
  stock: "",
};

const initialLimit = 50;

export default function FinishedGoodsInventory() {
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState(initialInventoryArea);
  const [mode, setMode] = useState("create");
  const [msg, setMsg] = useState(null);
  const [page, setPage] = useState(1);
  const [selectedExportMode, setSelectedExportMode] = useState("retrieved");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [limit, setLimit] = useState(initialLimit);
  const { settings } = useContext(SettingsContext);
  const { user } = useContext(AuthContext);
  const [searchTxt, setSearchTxt] = useState("");

  const queryClient = useQueryClient();

  const {
    data: items,
    isLoading,
    isError,
    error,
  } = useQuery(
    ["inventoryArea", page],
    () =>
      getInventoryAreas({
        query: {},
        options: { page, limit },
      }),
    {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5,
    }
  );

  useEffect(() => {
    // console.log("items in hook: ", items);
    if (items?.totalPages > page) {
      queryClient.prefetchQuery(
        ["inventoryArea", page + 1],
        () =>
          getInventoryAreas({
            query: {},
            options: { page: page + 1, limit },
          }),
        {
          refetchOnWindowFocus: false,
          staleTime: 1000 * 60 * 5,
        }
      );
    }
    // eslint-disable-next-line
  }, [page, items, queryClient]);

  // on packing scan event
  const onBarcodeScanned = async () => {
    socket.on("on-barcode-scanned", async (data) => {
      console.log("- on-barcode-scanned: ", data);
      let dataArr = [];
      let delimiter = ";";
      if (_.includes(data, "*")) delimiter = "*";
      console.log("- delimiter: ", delimiter);
      dataArr = data.split(delimiter);
      const code = dataArr[0];
      console.log("- code", code);
      setSearchTxt(code);
      setPage(1);
      fetchSearchResults.mutate(code);
    });
  };

  const showModalHandler = async (mode, item) => {
    console.log("* showModalHandler init");
    console.log("- mode: ", mode);
    console.log("- item: ", item);
    console.groupCollapsed();
    setMode(mode);
    if (msg) setMsg(null);
    if (mode === "create") {
      setItem(initialInventoryArea);
    } else {
      setItem(item);
      const temp = await getItemDoc(item.sku);
      console.log("- temp: ", temp);
      let imageUrl;
      if (!_.isNil(temp?.imageUrl) && !_.isEmpty(temp?.imageUrl)) {
        imageUrl = temp.imageUrl;
      }
      if (temp) {
        setItem({
          ...item,
          imageUrl,
        });
      }
    }
    console.groupEnd();
    setModalIsOpen(true);
  };

  const mutateInventoryArea = useMutation(
    async (payload) => {
      return await submitInventoryArea(payload);
    },
    {
      onError: (error) => {
        const retval = errorHandler(error);
        setMsg(retval);
      },
      onSuccess: () => {
        // console.log('-searchTxt: ', searchTxt)
        if (searchTxt) {
          fetchSearchResults.mutate(searchTxt);
        } else {
          queryClient.invalidateQueries(["inventoryArea", page]);
        }
      },
    }
  );

  const submitHandler = () => {
    console.log("* submitHandler init");
    // console.log("onsubmit item", item);
    // console.log("mode", mode);
    // inventoryAreaModalInstance.close();
    setModalIsOpen(false);
    const { sku, areaCode } = item;
    mutateInventoryArea.mutate({
      condition: { sku, areaCode },
      update: item,
    });
  };

  const deleteInventoryAreaHandler = () => {
    setModalIsOpen(false);
    handleDelete.mutate();
  };

  const handleDelete = useMutation(
    async () => {
      return await deleteInventoryArea({ id: item._id, sku: item.sku });
    },
    {
      onError: (error) => {
        const retval = errorHandler(error);
        setMsg(retval);
      },
      onSuccess: () => {
        if (searchTxt) {
          fetchSearchResults.mutate(searchTxt);
        } else {
          queryClient.invalidateQueries(["inventoryArea", page]);
        }
      },
    }
  );

  const fetchSearchResults = useMutation(
    async (searchTxt) => {
      console.log("- searchTxt: ", searchTxt);
      setLoading(true);
      return await searchInventoryAreas({
        searchTxt,
        options: { pagination: false },
      });
    },
    {
      onError: (error) => {
        const retval = errorHandler(error);
        setMsg(retval);
      },
      onSuccess: async (result, variables) => {
        console.log("fetchSearchResults onSuccess result: ", result);
        console.log("fetchSearchResults onSuccess variables: ", variables);
        queryClient.setQueryData(["inventoryArea", page], (old) => {
          return { ...old, ...result };
        });
        setLimit(result?.limit);
        setLoading(false);
        if (!Boolean(result?.docs.length)) {
          console.log("- case of none for search result");
          console.log("- action: find an item doc by searchTxt: ", variables);
          const itemDoc = await getItemDoc(variables);
          // console.log('- itemDoc: ', itemDoc)
          if (itemDoc?.sku) {
            console.log("- case of exists sku on itemDoc");
            console.log(
              "- action: modal pop-up w/ sku and focus on inventory area field"
            );
            setMode("create");
            let imageUrl;
            if (!_.isNil(itemDoc?.imageUrl) && !_.isEmpty(itemDoc?.imageUrl)) {
              imageUrl = itemDoc.imageUrl;
            }

            setItem({ ...initialInventoryArea, sku: itemDoc.sku, imageUrl });
            setModalIsOpen(true);
          }
        }
      },
    }
  );

  const handleSearch = async (searchTxt) => {
    console.log("- searchTxt", searchTxt);
    if (!_.isEmpty(searchTxt)) {
      setSearchTxt(searchTxt);
      fetchSearchResults.mutate(searchTxt);
    }
  };

  //onBarcodeScanned hook
  useEffect(() => {
    setTimeout(() => {
      onBarcodeScanned();
      setLoading(false);
    }, 1000);

    return () => {
      socket.removeAllListeners(["on-barcode-scanned"]);
    };
    // eslint-disable-next-line
  }, []);

  const exportInvetoryArea = async () => {
    setLoading(true);
    setMsg(null);
    // console.log(selectedExportMode);
    let exportInventoryArea = [];

    if (selectedExportMode === "all") {
      if (item.totalDocs > 100000) {
        alert(
          "Sorry, Exporting all not available at this time because of big data, use mongoexport instead"
        );
        setLoading(false);
        return;
      } else {
        await getInventoryAreas({
          query: {},
          options: {
            pagination: false,
          },
        })
          .then((results) => {
            // console.log("get all inventory area", results)
            exportInventoryArea = results.docs;
          })
          .catch((error) => {
            console.log("get all invnetory areas error", error);
            alert("Error occured while retrieving all inventory areas");
            return;
          });
      }
    } else {
      exportInventoryArea = _.cloneDeep(items.docs);
    }

    // console.log("exportInventoryArea", exportInventoryArea);

    const result = jsonToCSV({
      fields: ["sku", "areaCode", "stock"],
      data: exportInventoryArea,
    });
    // console.log(result);

    const element = document.createElement("a");
    const file = new Blob([result], {
      type: "text/csv",
    });
    element.href = URL.createObjectURL(file);
    element.download = `finishedgoods_inventory_status_${moment().format(
      "YYYYMMDDHHmm"
    )}.csv`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    setLoading(false);
  };

  const importInventoryAreas = (parsedData) => {
    try {
      setMsg(null);

      let temp = parsedData.map((item) => item.data);
      let msg = `Total number of finished goods inventory status: ${temp.length}`;
      setMsg(msg);
      //for test
      // temp = temp.slice(0,10);

      let i = 0;
      let len = temp.length - 1;

      async function loop() {
        temp[i].sku = temp[i].sku.trim();
        let sku = temp[i].sku;
        let areaCode = temp[i].areaCode;
        let stock = temp[i].stock;

        console.log(i, temp[i]);
        if (sku && areaCode && stock && parseInt(stock) > 0) {
          try {
            const result = await submitInventoryArea({
              condition: { sku, areaCode },
              update: temp[i],
            });
            // console.log("submitInventoryArea result", result);
            setMsg(`${i + 1}/${temp.length}: ${result.sku} updated.`);
            i += 1;
          } catch (error) {
            console.log("submit item error", error);
            setMsg(`Error occured, try again from this sku: ${sku}`);
            return;
          }
        }

        if (i <= len && _.has(temp[i], "sku")) {
          loop();
        } else {
          setMsg(
            "* Completed import inventory area status, click refresh to load a page."
          );
          setLoading(false);
          return;
        }
      }

      if (i <= len) {
        loop();
      }
    } catch (error) {
      console.log("importInventoryArea error", error);
    }
  };

  const handleOnPageChange = ({ selected }) => {
    // console.log("handleOnPageChange page", selected);
    setPage(selected + 1);
  };

  const batchDelete = (parsedData) => {
    console.log("* batchDelete init");
    try {
      setLoading(true);
      let temp = parsedData.map((item) => item.data);
      let msg = `Total number of finished goods inventory status for deleting: ${temp.length}`;
      setMsg(msg);
      let i = 0;
      let len = temp.length - 1;

      async function loop() {
        let sku = temp[i].sku;
        let areaCode = temp[i].areaCode;
        try {
          // const result = await deleteInventoryAreaBySku({
          await deleteInventoryAreaBySku({
            sku,
            areaCode,
          });
          // console.log("submitInventoryArea result", result);
          setMsg(`${i + 1}/${temp.length}: ${sku} deleted.`);
          i += 1;
        } catch (error) {
          console.log("deleteInventoryAreaBySku error", error);
          const retval = errorHandler(error);
          setMsg(retval);
          return;
        }

        if (i <= len && _.has(temp[i], "sku")) {
          loop();
        } else {
          setMsg(
            "* Completed batch deletion of the inventory area status, click refresh to load a page."
          );
          setLoading(false);
          return;
        }
      }

      if (i <= len) {
        loop();
      }
    } catch (error) {
      const retval = errorHandler(error);
      console.log("- batchDelete error: ", retval);
      setMsg(retval);
    }
  };

  return (
    <>
      <Title title={title} />
      <ToastContainer theme="dark" />
      <PageMainHeader title={title} user={user} settings={settings} />
      <section className="primary">
        {isLoading ? (
          <Skeleton count={20} height={50} circle={true} />
        ) : isError ? (
          <Banner variant="danger">
            {error?.message ? error.message : error}
          </Banner>
        ) : (
          <>
            <PageMenuHeader>
              <FinishedGoodsInventoryHeader
                showModal={showModalHandler}
                importHandler={importInventoryAreas}
                exportHandler={exportInvetoryArea}
                selectedExportMode={selectedExportMode}
                setSelectedExportMode={setSelectedExportMode}
                batchDeleteHandler={batchDelete}
                handleDelete={handleDelete}
              />
              <Search handleSearch={handleSearch} placeholderText={"Search"} />
            </PageMenuHeader>

            {items && (
              <Pagination
                handleOnPageChange={handleOnPageChange}
                totalDocs={items?.totalDocs}
                totalPages={items?.totalPages}
                page={page}
                limit={limit}
              />
            )}

            {msg && <Banner className="mb-10">{msg}</Banner>}

            {items && Boolean(items?.docs.length) ? (
              <FinishedGoodsInventoryList
                items={items.docs}
                showModal={showModalHandler}
              />
            ) : (
              <Banner>Inventory status not found</Banner>
            )}
          </>
        )}
        <ScrollTop />
      </section>
      <ModalFinishedGoodsInventory
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        title={"Inventory Status"}
        mode={mode}
        item={item}
        setItem={setItem}
        submitHandler={submitHandler}
        handleDelete={deleteInventoryAreaHandler}
        user={user}
      />
      <ModalPreloader modalPreloaderIsOpen={loading} />
    </>
  );
}
