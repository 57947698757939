import React from "react";
import { Link } from "react-router-dom";
import { FaPrint } from "react-icons/fa";
import Msg from "components/Msg";
import { Table, TableHeader, Row, Cell } from '@leafygreen-ui/table';
import IconButton from "@leafygreen-ui/icon-button";
import Tooltip from "@leafygreen-ui/tooltip";

const ShippedOrderList = ({ shippedOrders, printShippingLabel }) => {
  console.log("* ShippedOrderList init");
  // console.log("- shippedOrders: ", shippedOrders);
  const columns = [
    <TableHeader label='Order Number' sortBy={datum => datum.orderNumber}/>,
    <TableHeader label='Ship Date' sortBy={datum => datum.shipDate} />,
    <TableHeader label='Recipient' sortBy={datum => datum.shipTo.name}/>,
    <TableHeader label='Tracking Number' />,
    <TableHeader label='Shipment Cost' dataType='number'/>,
    <TableHeader label='Carrier Code' sortBy={datum => datum.carrierCode}/>,
    <TableHeader label='Service Code' sortBy={datum => datum.serviceCode}/>,
    <TableHeader label='Action' />,
  ]
  // console.log('- columns: ', columns)

  return (
    <>
      {Boolean(shippedOrders.length) ? (
        <div className="page-content card p-10">
          <Table
            data={shippedOrders}
            columns={columns}
          >
            {({datum: order}) => (
              <Row key={order.orderId}>
                <Cell>
                  <Link to={`/workorder-details/${order.orderId}`}>
                    {order.orderNumber}
                  </Link>
                </Cell>
                <Cell>{order.shipDate}</Cell>
                <Cell>{order.shipTo.name}</Cell>
                <Cell>{order?.trackingNumber}
                </Cell>
                <Cell>
                  {
                    order.shipmentCost.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })
                  }
                </Cell>
                <Cell>{order.carrierCode}</Cell>
                <Cell>{order.serviceCode}</Cell>
                <Cell>
                  <Tooltip
                    align="left"
                    justify="start"
                    triggerEvent="hover"
                    className='lg-tooltip-container'
                    darkMode={true}
                    trigger={
                      <IconButton
                        onClick={() => printShippingLabel(order)}
                        className="swing-icon"
                        aria-label="Print a label"
                      >
                        <FaPrint />
                      </IconButton>}
                  >
                    Print a shipping label
                  </Tooltip>

                </Cell>
              </Row>
            )}
          </Table>
        </div>
      ) : (
        <div className="mt-10">
          <Msg msg={'Shipment not found.'} />  
        </div>
      )}
    </>
  );
};

export default React.memo(ShippedOrderList);