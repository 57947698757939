export const servicesInRush = [
  { name: "usps_priority_mail_express_international", symbol: "R" },
  { name: "usps_priority_mail_express", symbol: "R" },
  { name: "ups_next_day_air_early_am", symbol: "R" },
  { name: "ups_next_day_air", symbol: "R" },
  { name: "ups_worldwide_express_plus", symbol: "R" },
  { name: "ups_next_day_air_saver", symbol: "R" },
  { name: "ups_2nd_day_air_am", symbol: "R" },
  { name: "ups_2nd_day_air", symbol: "R" },
  { name: "ups_2nd_day_air_international", symbol: "R" },
  { name: "ups_3_day_select", symbol: "R" },
  { name: "ups_next_day_air_international", symbol: "R" },
  { name: "fedex_2day", symbol: "R" },
  { name: "fedex_2day_am", symbol: "R" },
  { name: "fedex_standard_overnight", symbol: "R" },
  { name: "fedex_priority_overnight", symbol: "R" },
  { name: "fedex_first_overnight", symbol: "R" },
  { name: "fedex_1_day_freight", symbol: "R" },
  { name: "fedex_2_day_freight", symbol: "R" },
  { name: "fedex_3_day_freight", symbol: "R" },
  { name: "fedex_first_overnight_freight", symbol: "R" },
  { name: "sunrise_service", symbol: "R" },
  { name: "sunrise_gold_service", symbol: "R" },
  { name: "ontrac_ground_service", symbol: "R" },
  { name: "palletized_freight_service", symbol: "R" },
];
