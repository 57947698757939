import React from 'react'
import IconButton from '@leafygreen-ui/icon-button';
import Icon from '@leafygreen-ui/icon';
import TextInput from '@leafygreen-ui/text-input';

export default function Search({handleSearch, placeholderText="Search"}) {
  const [searchText, setSearchText] = React.useState('')

  const onClickHandler = (e) => {
    e.preventDefault()
    // console.log('- searchText: ', searchText)
    Boolean(searchText.length) && handleSearch(searchText)
  }
  
  const onChangeHandler = (e) => {
    setSearchText(e.target.value.trimStart());
  };

  const onKeyDownHandler = (e) => {
    if (e.key === "Enter") {
      Boolean(searchText.length) && handleSearch(searchText)
    }
  };

  return (
    <div className="search-wrapper">
      <TextInput
        type="text"
        value={searchText}
        onChange={onChangeHandler}
        onKeyDown={onKeyDownHandler}
        placeholder={placeholderText ? placeholderText : "Search"}
        aria-labelledby="Search"
        handleValidation={(value) => {
          //console.log(value)}
        }}
      >
      </TextInput>
      <IconButton aria-label={placeholderText} onClick={onClickHandler}>
        <Icon glyph="MagnifyingGlass" />
      </IconButton>

    </div>
  )
}
