import React, { useState, useEffect } from "react";
import Aside from "./Aside";
import Main from "./Main";
import { AuthContext } from "contexts/AuthContext";
import SettingsContextProvider from "contexts/SettingsContext";
import { QueryClientProvider, QueryClient } from "react-query";
const queryClient = new QueryClient();

function Layout() {
  console.log("* Layout init");
  const [collapsed, setCollapsed] = useState(true);
  const [toggled, setToggled] = useState(false);
  const [showAside, setShowAside] = useState(true);
  const { user } = React.useContext(AuthContext);

  useEffect(() => {
    if (!user) {
      setShowAside(false);
    } else {
      !showAside && setShowAside(true);
    }
    // eslint-disable-next-line
  }, [user]);

  const handleCollapsedChange = (value) => {
    setCollapsed(() => (value = !value));
  };

  const handleToggleSidebar = (value) => {
    console.log("handleToggleSidebar value", value);
    setToggled(value);
  };

  return (
    <div className={`app ${toggled ? "toggled" : ""}`}>
      {showAside && (
        <QueryClientProvider client={queryClient}>
          <SettingsContextProvider>
            <Aside
              collapsed={collapsed}
              toggled={toggled}
              handleToggleSidebar={handleToggleSidebar}
              handleCollapsedChange={handleCollapsedChange}
            />
          </SettingsContextProvider>
        </QueryClientProvider>
      )}
      <Main />
    </div>
  );
}

export default Layout;
