import React from "react";
import _ from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import ItemOptions from "utils/ItemOptions";
import Tooltip from "@leafygreen-ui/tooltip";
import { FaQrcode } from "react-icons/fa";
import IconButton from "@leafygreen-ui/icon-button";

const color = "#adadad";
const highlightColor = "#252525";

export const ShipmentDetail = ({
  item,
  shipstationOrder,
  localOrder,
  shipProcessResult,
  checkedOutQtyRef,
  setCheckoutQty,
  loading,
  settings,
  handlePrintOrderItemLabel
}) => {
  // console.log(
  //   "checkedOutQtyRef.current.value",
  //   checkedOutQtyRef.current?.value
  // );

  // shipProcessResult && console.log("shipProcessResult", shipProcessResult);

  // let isDisabledPrintBtn = shipProcessResult ? false : true;
  // console.log(item)
  // console.log(localOrder)
  return (
    <div className="shipment-info_detail">
      <div className="title-content-list">
        <div className="title-content">
          <span className="title">SKU</span>
          <span className="content">{item && item.sku}</span>
        </div>
        <div className="title-content">
          <span className="title">Order Number</span>
          {localOrder && (
            <Link
              to={`workorder-details/${localOrder?.orderId}`}
            >
              <span className="content">
                {localOrder?.orderNumber ? localOrder?.orderNumber : item?.orderNumber ? item.orderNumber : null}
              </span>
            </Link>
          )}
        </div>
        <div className="title-content">
          <span className="title">Order Date</span>
          <span className="content">
            {localOrder?.orderDate && moment(localOrder?.orderDate).format("MM-DD-YYYY") }
          </span>
        </div>
        <div className="title-content">
          <span className="title">Graphic Position</span>
          <span className="content">
            {item?._item && item._item.graphicPosition}
          </span>
        </div>
        <div className="title-content">
          <span className="title">Ordered</span>
          <span className="content ordered-checkedout">
            {item?.quantity && item.quantity}
          </span>
        </div>
        <div className="title-content">
          <span className="title">Checked-out</span>
          <input
            type="text"
            className="ordered-checkedout"
            ref={checkedOutQtyRef}
            onChange={(e) => {
              setCheckoutQty(e.target.value);
            }}
            disabled={
              item &&
              checkedOutQtyRef?.current?.value &&
              item.quantity === parseInt(checkedOutQtyRef?.current?.value)
            }
          />
          {/* <span className="content ordered-checkedout">
            
          </span> */}
        </div>
        <div className="title-content">
          <span className="title">Printed</span>
          <span className="content ordered-checkedout">
            {item?.printed && _.sumBy(item.printed, "qty")}
          </span>
        </div>
        {shipstationOrder && (
          <>
            <div className="title-content">
              <span className="title">Carrier Code</span>
              {!loading ? (
                <span className="content">
                  {shipstationOrder && shipstationOrder.carrierCode}
                </span>
              ) : (
                <SkeletonTheme color={color} highlightColor={highlightColor}>
                  <Skeleton width={150} height={30} duration={1} />
                </SkeletonTheme>
              )}
            </div>
            <div className="title-content">
              <span className="title">Service & Package Code</span>
              {!loading ? (
                <span className="content">
                  {shipstationOrder && shipstationOrder.serviceCode} <br />
                  {shipstationOrder && shipstationOrder.packageCode}
                </span>
              ) : (
                <SkeletonTheme color={color} highlightColor={highlightColor}>
                  <Skeleton width={150} height={30} duration={1} />
                </SkeletonTheme>
              )}
            </div>
          </>
        )}

        {(shipstationOrder) && (
          <>
            {shipstationOrder?.customerNotes && (
              <div className="title-content">
                <span className="d-block font-size-sm title">Customer Notes</span>
                <span className="d-block content">
                  {shipstationOrder && shipstationOrder?.customerNotes}
                </span>
              </div>
            )}
            {shipstationOrder?.internalNotes && (
              <div className="title-content">
                <span className="d-block font-size-sm title">Internal Notes</span>
                <span className="d-block content">
                  {shipstationOrder && shipstationOrder?.internalNotes}
                </span>
              </div>
            )}
            {shipstationOrder?.gift && (
              <div className="title-content">
                <span className="d-block font-size-sm title">Gift message</span>
                <span className="d-block content">
                  {shipstationOrder && shipstationOrder?.giftMessage}
                </span>
              </div>
            )}
          </>  
        )}
        
        {item && Boolean(item?.options?.length) && (
          <div className="title-content">
            <span className="title">Order Item Options</span>
            <ItemOptions options={item.options} />
          </div>
        )}
        {shipstationOrder && (
          <>
            <div className="title-content">
              <span className="title">Shipment Cost</span>
              {!loading ? (
                <span className="content">
                  {shipProcessResult &&
                    shipProcessResult.shipmentCost.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                </span>
              ) : (
                <SkeletonTheme color={color} highlightColor={highlightColor}>
                  <Skeleton width={150} height={30} duration={1} />
                </SkeletonTheme>
              )}
            </div>
            <div className="title-content">
              <span className="title">Tracking Number</span>
              {!loading ? (
                <span className="content">
                  {shipProcessResult ? shipProcessResult?.trackingNumber : ""}
                </span>
              ) : (
                <SkeletonTheme color={color} highlightColor={highlightColor}>
                  <Skeleton width={150} height={30} duration={1} />
                </SkeletonTheme>
              )}
            </div>
          </>
        )}

        {item && (
          <div className="title-content">
            <span className="title">Print label</span>
            <Tooltip
              className="lg-tooltip-container" 
              darkMode={true}
              trigger={
                <IconButton
                  onClick={() => handlePrintOrderItemLabel({item, settings})}
                  className="swing-icon"
                  aria-label="Print labels"
                >
                  <FaQrcode />
                </IconButton>}
            >
              Print order item label
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
};
