import React from "react";
import ModalTemplate from "./ModalTemplate";
import { FaSave, FaTrashAlt } from "react-icons/fa";
import Button from "@leafygreen-ui/button";
import _ from "lodash";

export default React.memo(function ModalGraphic({
  modalIsOpen,
  setModalIsOpen,
  title,
  mode,
  selectedGraphic,
  setSelectedGraphic,
  submitHandler,
  platens,
  media,
  handleDelete,
  user,
  useARXfile,
  usePolarisRecipe,
  usePrintMode
}) {
  // console.log('- selectedGraphic: ', selectedGraphic)
  // console.log("- media: ", media);
  // console.log("- usePrintMode: ", usePrintMode);
  const modalGraphicFormRef = React.useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    modalGraphicFormRef.current.reportValidity();
    if (modalGraphicFormRef.current.checkValidity()) {
      setModalIsOpen(false);
      submitHandler();
    }
  };

  const onChangeHandler = (e) => {
    // console.log(e.target.name, e.target.value);
    setSelectedGraphic({ ...selectedGraphic, [e.target.name]: e.target.value });
  };

  const onChangeSizeHandler = (e) => {
    console.log("* onChangeSizeHandler init: ", e.target.name, e.target.value);
    console.log("- name, value: ", e.target.name, e.target.value);
    // console.log("selectedGraphic: ", selectedGraphic);
    // console.log('_.has(selectedGraphic, [size, e.target.name]', _.has(selectedGraphic, ['size', e.target.name]));
    if (!_.has(selectedGraphic, ["size", e.target.name])) {
      selectedGraphic["size"] = {};
      selectedGraphic["size"][e.target.name] = e.target.value;
    } else {
      selectedGraphic["size"][e.target.name] =
        e.target.value === "0" ? null : e.target.value;
    }
    // console.log("selectedGraphic: ", selectedGraphic);

    setSelectedGraphic({ ...selectedGraphic });
  };

  const deleteHandler = (e) => {
    e.preventDefault();
    const confirm = window.confirm("Confirm to delete?");
    if (confirm) {
      handleDelete();
    }
  };

  return (
    <ModalTemplate
      title={title}
      subTitle={mode}
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}
      modalSize={{ width: "600px", height: "auto" }}
    >
      <form className="card modal-form modal-graphic" ref={modalGraphicFormRef}>
        <div className="input-field">
          <label htmlFor="sku">SKU</label>
          <input
            name="sku"
            type="text"
            value={selectedGraphic?.sku ? selectedGraphic.sku : ""}
            onChange={onChangeHandler}
            placeholder="SKU"
            required={true}
            autoFocus
          />
        </div>
        <div className="input-field">
          <label htmlFor="graphicFileName">Graphic File Name</label>
          <input
            name="graphicFileName"
            type="text"
            value={
              selectedGraphic?.graphicFileName
                ? selectedGraphic.graphicFileName
                : ""
            }
            onChange={onChangeHandler}
            placeholder="Graphic File Name"
          />
        </div>
        {useARXfile && (
          <div className="input-field">
            <label htmlFor="arxFileName">ARX File Name</label>
            <input
              name="arxFileName"
              type="text"
              value={
                selectedGraphic?.arxFileName ? selectedGraphic.arxFileName : ""
              }
              onChange={onChangeHandler}
              placeholder="ARX File Name"
            />
          </div>
        )}
        <div className="d-flex">
          <div className="input-field">
            <label htmlFor="graphicPosition">Graphic Position</label>
            <input
              name="graphicPosition"
              type="text"
              value={
                selectedGraphic?.graphicPosition
                  ? selectedGraphic.graphicPosition
                  : ""
              }
              onChange={onChangeHandler}
              placeholder="* Graphic Position"
            />
          </div>
          <div className="input-field">
            <label htmlFor="ripEnv">Rip Env</label>
            <input
              name="ripEnv"
              type="text"
              value={selectedGraphic?.ripEnv ? selectedGraphic.ripEnv : ""}
              onChange={onChangeHandler}
              placeholder="Rip env"
            />
          </div>
        </div>
        <div className="d-flex">
          <div className="input-field">
            <label htmlFor="size">Graphic Width</label>
            <input
              name="width"
              type="text"
              value={
                selectedGraphic?.size?.width ? selectedGraphic.size.width : ""
              }
              onChange={onChangeSizeHandler}
              placeholder="Width"
            />
          </div>
          <div className="input-field">
            <label htmlFor="size.height">Graphic Height</label>
            <input
              name="height"
              type="text"
              value={
                selectedGraphic?.size?.height ? selectedGraphic.size.height : ""
              }
              onChange={onChangeSizeHandler}
              placeholder="Height"
            />
          </div>
        </div>


        <div className="input-field">
          <label htmlFor="platen">Platen</label>
          <select
            name="platen"
            value={selectedGraphic?.platen ? selectedGraphic.platen : ""}
            onChange={onChangeHandler}
          >
            <option value="">-- Choose a platen --</option>
            {platens &&
              platens.map((p) => {
                return (
                  <option value={p.code} key={p.code}>
                    {p.name} ({p.code} inch) : {p.printers.join(", ")}
                  </option>
                );
              })}
          </select>
        </div>

        {(media && Boolean(media.length)) && (
          <div className="input-field">
            <label htmlFor="media">Media(CADlink)</label>
            <select
              name="media"
              value={selectedGraphic?.media ? selectedGraphic.media : ""}
              onChange={onChangeHandler}
            >
              <option value="">-- Choose media --</option>
              {media &&
                media.map((p) => {
                  return (
                    <option value={p.code} key={p.code}>
                      {p.name} ({p.code} inch)
                    </option>
                  );
                })}
            </select>
          </div>
        )}

        {usePolarisRecipe && (
          <div className="input-field">
            <label htmlFor="polarisRecipe">Recipe(Polaris)</label>
            <input
              name="polarisRecipe"
              type="text"
              value={selectedGraphic?.polarisRecipe ? selectedGraphic.polarisRecipe : ""}
              onChange={onChangeHandler}
              placeholder="Recipe(Polaris)"
            />
          </div>
        )}
        
        {usePrintMode && (
          <div className="input-field">
            <label htmlFor="printMode">Print mode(CADlink)</label>
            <input
              name="printMode"
              type="text"
              value={selectedGraphic?.printMode ? selectedGraphic.printMode : ""}
              onChange={onChangeHandler}
              placeholder="Print mode(CADlink)"
            />
          </div>
        )}

        <div className="d-flex">
          <div className="input-field">
            <label htmlFor="imageUrl">Mockup Image Url</label>
            <textarea
              name="imageUrl"
              type="text"
              value={selectedGraphic?.imageUrl ? selectedGraphic.imageUrl : ""}
              onChange={onChangeHandler}
              placeholder="Mockup Image Url"
              col="50"
              rows="5"
            />
          </div>
          {selectedGraphic?.imageUrl && (
            <div className="card mockup-img">
              <img
                className="responsive-img"
                src={selectedGraphic?.imageUrl}
                style={{ maxHeight: 200 }}
                alt={`${selectedGraphic?.sku}`}
              />
            </div>
          )}
        </div>
        <div className="modal-footer">
          {mode === "update" && user?.role === "admin" && (
            <Button
              variant="danger"
              className="swing-icon"
              onClick={deleteHandler}
            >
              <FaTrashAlt />
              Delete
            </Button>
          )}
          <Button
            variant="primary"
            onClick={handleSubmit}
            className="swing-icon"
            leftGlyph={<FaSave />}
          >
            Submit
          </Button>
        </div>
      </form>
    </ModalTemplate>
  );
});
