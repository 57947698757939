import React from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaDownload } from 'react-icons/fa';
import Button from '@leafygreen-ui/button';

export default function DateRange({startDate, endDate, setStartDate, setEndDate, onClickHandler}) {
  return (
    <div className="date-range">
      <div className="title-content">
        <span className="title">From</span>
        <div className="content">
          <DatePicker
            selected={startDate}
            className="browser-default"
            onChange={(date) => setTimeout(() => {setStartDate(date)}, 1500)}
          />
        </div>
      </div>
      <div className="title-content ml-10">
        <span className="title">To</span>
        <div className="content">
          <DatePicker
            selected={endDate}
            className="browser-default"
            onChange={(date) => setTimeout(() => {setEndDate(date)}, 1500)}
          />
        </div>
      </div>  
      <Button
        variant="primary"
        className="swing-icon"
        onClick={onClickHandler}
        size='small'
        leftGlyph={<FaDownload />}
        style={{marginBottom: "-15px"}}
      >
        Submit
      </Button>
    </div>
  )
}
