import React from 'react'
import { FaInfoCircle } from 'react-icons/fa'

export default function Msg({msg}) {
  return (
    <div className="card warn msg p-10 mb-10">
      <FaInfoCircle color={ 'red' }/> {msg}
    </div>
  )
}
