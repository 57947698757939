import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Table, TableHeader, Row, Cell } from '@leafygreen-ui/table';
import { FaCheck, FaPrint, FaQrcode, FaTimes } from "react-icons/fa";
import Banner  from '@leafygreen-ui/banner'
import Tooltip from "@leafygreen-ui/tooltip";
import IconButton from "@leafygreen-ui/icon-button";
import Button from '@leafygreen-ui/button'
import PageMenuHeader from "components/PageMenuHeader";

const PickingComponents = ({ components, handlePrintLabel, TotalPickupQty, handlePrintLabelByFlag, msg }) => {
  // console.log('* PickingComponents init')
  // console.log("- components", components);
  const [missingComps, setMissingComps] = useState([])

  const columns = [
    <TableHeader label="SKU" />,
    <TableHeader label="Inventory Location" />,
    <TableHeader label="Position" />,
    <TableHeader label="Ordered" dataType="number"/>,
    <TableHeader label="Label Printed(Picked)" dataType="number"/>,
    <TableHeader label="Status" />,
    <TableHeader label="Category" />,
    <TableHeader label="Color" />,
    <TableHeader label="Action" />
  ]

  useEffect(() => {
    let missingComps = []
    
    let undefinedSKUs = _.filter(components, { sku: undefined })
    for (let o of undefinedSKUs) {
      for (let p of o.packing_docs) {
        missingComps.push(p)
      }
    }  
    // console.log('- missingComps: ', missingComps)
    Boolean(missingComps.length) && setMissingComps(missingComps)
  }, [components])
  

  const NulComponents = ({ missingComps }) => {
    // console.log("- missingComps", missingComps)
    let skus = []
    for (let p of missingComps) {
      if (skus.indexOf(p.sku) === -1 ) {
        skus.push(p.sku)
      } 
    }
    // console.log('- skus: ', skus)
    return (
      <Banner variant="danger" className="mt-10">
        <span>Production SKUs of undefined* component(s):&nbsp;{_.sortBy(skus).join(", ")}</span>
      </Banner>
    )
  }

  return (
    <>
      <div className="collection-items collection">
        {
          Boolean(components.length) && (
            <div className="mt-10">
              <PageMenuHeader>
                <Button
                  variant="primary"
                  className="swing-icon"
                  onClick={() => handlePrintLabelByFlag({states: components, flag: "component"})}
                  leftGlyph={<FaPrint/>}
                >
                  Print components labels
                </Button>
                <Banner>
                  <TotalPickupQty states={components} />
                </Banner>
            </PageMenuHeader>
            {msg && <Banner className="mb-10">{msg}</Banner>}
          </div>
            

          )
        }
        {!components ? (
          <p>loading...</p>
        ) : (
          <Table
            data={components}
            columns={columns}
          >
            {({datum}) => {
              // console.log('- datum: ', datum)
              const ordered = _.sumBy(datum?.packing_docs, 'quantity')
              const picked = _.sumBy(datum?.packing_docs, 'pickedQty')
              // const isMeta =  _.filter(datum?.packing_docs, doc => doc?.meta ).length > 0
              // console.log('- isMeta ', isMeta )
              return (
                <Row key={datum.sku}>
                  <Cell>{datum?.sku ? datum.sku : "undefined*"}</Cell>
                  <Cell>{datum?.inventoryArea ? datum.inventoryArea : ""}</Cell>
                  <Cell>{datum?.gp ? datum.gp : ""}</Cell>
                  <Cell>{ordered}</Cell>
                  <Cell>{ordered > picked ? picked : ordered}</Cell>
                  <Cell>
                    <span className="inline-block text-center" style={{minWidth: '30px'}}>
                      {
                        ordered <= picked
                          ? <FaCheck color="#09804C"/>
                          : <FaTimes color="#B1371F"/>
                      }

                    </span>
                  </Cell>
                  <Cell>{datum?.category ? datum.category : ""}</Cell>
                  <Cell>{datum?.color ? datum.color : ""}</Cell>
                  <Cell>
                      <Tooltip
                        align="left"
                        justify="start"
                        className='lg-tooltip-container'
                        darkMode={true}
                        trigger={
                          <IconButton
                            onClick={() => handlePrintLabel({ data: datum, flag: 'component', from: 'action' })}
                            className="swing-icon"
                            aria-label="Print a label"
                          >
                            <FaQrcode />
                          </IconButton>}
                        triggerEvent="hover"
                      >
                        Print label(s)
                      </Tooltip>
                  </Cell>
                  
                </Row>
              )
            }}

          </Table>    
        )}
      </div>
      {
        Boolean(missingComps.length) && <NulComponents missingComps={missingComps} />
      }
    </>
  );
};

export default React.memo(PickingComponents);