import React, { useState, useEffect, useContext, useCallback, useRef } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import { Title } from 'utils/Title'
import PageMainHeader from 'components/PageMainHeader'
import { AuthContext } from "contexts/AuthContext"
import { SettingsContext } from 'contexts/SettingsContext'
import Banner from '@leafygreen-ui/banner'
import {Tabs, Tab} from '@leafygreen-ui/tabs'
import PageMenuHeader from 'components/PageMenuHeader'
import socketIOClient from "socket.io-client";
import { getPackingItems, getPackingItemsByOrder, updatePackingItem } from 'utils/packingItems'
import "react-toastify/dist/ReactToastify.css";
import { useQuery, useMutation } from "react-query";
import log from 'utils/log';
import _ from 'lodash'
import { labelTemplateQR } from 'utils/labelTemplates'
import PickingComponents from './PickingComponents'
import PickingStocks from './PickingStocks'
import Button from '@leafygreen-ui/button'
import { FaListAlt, FaPrint} from 'react-icons/fa'
import PickingListPrint from './PickingListPrint'
import ConfirmationModal from '@leafygreen-ui/confirmation-modal'
import { updateOrder, updateOrdersPickedAsOrdered } from 'utils/orders'
import ScrollTop from 'utils/ScrollTop'
import { addTransaction } from 'utils/inventoryTransactions'
import { ModalPreloader } from 'utils/Preloader'
import PickingComponentsPF from './PickingComponentsPF'
import { updatePrintedFilmStock, updatePrintedFilm } from 'utils/printedFilms'

const title = "Picking List"
const socket = socketIOClient(process.env.REACT_APP_SOCKET_ENDPOINT);

export default function PickingList(props) {
  const { user } = React.useContext(AuthContext);
  const { settings, platens } = useContext(SettingsContext);
  const [components, setComponents] = useState([]);
  const [finishedGoods, setFinishedGoods] = useState([]);
  const [componentsPF, setComponentsPF] = useState([]);
  const [loading,setLoading] = useState(false);
  const [msg,setMsg] = useState(null);
  const [modalConfirmIsOpen, setModalConfirmIsOpen] = useState(false)
  const [flag, setFlag] = useState();
  // const queryClient = useQueryClient()
  const [selectedTab, setSelectedTab] = useState(0)
  const activeFilmInventoriesRef = useRef([])
  const [isPickedOverall, setIsPickedOverall] = useState(false)

  const printedLabelWarning = "The label has already been printed. Do you want to re-print ALL?"
  // console.log('- user: ', user)
  const { data: packingItems, isLoading, isError, error } = useQuery(["packingItems"],
    () => getPackingItems({page: null}),
    { refetchOnWindowFocus: false }
  );

  isError && setMsg(error);

  const PickingList = useCallback(async () => {
    console.group('* PickingList init')
    if(settings && packingItems) {

      if (!Boolean(packingItems.length)) {
        setMsg("Items not found.");
        return;
      }
      setLoading(true);

      let clonedPIs = _.cloneDeep(_.orderBy(packingItems, "sku"));
      // console.log("clonedPIs count", clonedPIs.length)
      // console.log("clonedPIs", clonedPIs)

      let stockPIsTemp = [], compPIsTemp = [];
      activeFilmInventoriesRef.current = []
      let compPFsMapped; 
      let compPFsTemp = []
      let runTotalOrdered = 0, runTotalPicked = 0;

      let skuGrouped = _.groupBy(clonedPIs, 'sku')
      // console.log('- skuGrouped: ', skuGrouped)
      console.groupCollapsed('- seperate temp stockPIs && compPIs')
      let skuMapped = Object.entries(skuGrouped).map(g => {
        // console.log('- g: ', g)
        let sku = g[0], packing_docs = g[1];
        // console.log('- sku: ', sku)
        // console.log('- packing_docs: ', packing_docs)
        
        let _inventoryArea, graphicPosition, _graphics;

        _inventoryArea = packing_docs[0]?._item?._inventoryArea ? packing_docs[0]._item._inventoryArea : [];
        graphicPosition = packing_docs[0]?._item?.graphicPosition ? packing_docs[0]._item.graphicPosition : null;
        let position_priority = /front/i.test(graphicPosition) ? 0 : 99
        _graphics = packing_docs[0]?._item?._graphics ? packing_docs[0]._item._graphics : [];
        return { sku, packing_docs, _inventoryArea, graphicPosition, position_priority, _graphics }
      })

      console.log('- skuMapped: ', skuMapped)
      
      console.groupCollapsed('- skuMapped loop')
      let skuMappedlen = skuMapped.length, count = 0
      for (let a of skuMapped) {
        count = count + 1
        const { sku, _inventoryArea, packing_docs, graphicPosition, position_priority, _graphics} = a
        console.log(`- ${count}/${skuMappedlen}: ${sku}`)
        const totalInstock = _.sumBy(_inventoryArea, 'stock')
        const totalOrdered = _.sumBy(packing_docs, 'quantity')
        const totalPicked = _.sumBy(packing_docs, 'pickedQty')
        const category = packing_docs[0]?._item?.category ? packing_docs[0]._item.category : null
        const color = packing_docs[0]?._item?.color ? packing_docs[0]._item.color : null
        let totalFilmInventory = 0;
        let graphicFilename;
        for(let g of _graphics) {
          graphicFilename = g.graphicFileName
          let f = g?._filmInventory ?? g._filmInventory
          totalFilmInventory += f?.stock ? f.stock : 0
          if(f?.stock > 0) {
            const idx = _.findIndex(activeFilmInventoriesRef.current, {graphicFilename})
            if(idx === -1) {
              activeFilmInventoriesRef.current.push(f)
            }
          }
        }
        if (totalOrdered > 0) {
          if (Boolean(_inventoryArea.length) && totalInstock > 0) {
            stockPIsTemp.push({ ...a, totalInstock, totalOrdered, totalPicked, category, color })
          } else {
            let component;
            if(totalFilmInventory > 0) {
              component = packing_docs[0]?._item?._component ? packing_docs[0]._item._component : {sku: undefined}
              compPFsTemp.push({ a, position_priority, ...component, graphicPosition, totalFilmInventory, graphicFilename })
            } else {
              component = packing_docs[0]?._item?._component ? packing_docs[0]._item._component : {sku: undefined}
              compPIsTemp.push({ a, position_priority, ...component, graphicPosition })

            }
          }
        }
        runTotalOrdered = runTotalOrdered + totalOrdered
        runTotalPicked = runTotalPicked + totalPicked
      }
      console.groupEnd('- end skuMapped loop')
      console.log(`- runTotalOrdered: ${runTotalOrdered}, runTotalPicked: ${runTotalPicked}`)
      if(runTotalOrdered<=runTotalPicked) setIsPickedOverall(true)
      console.log('- stockPIsTemp: ', stockPIsTemp)
      console.log('- compPIsTemp: ', compPIsTemp)
      console.log('- compPFsTemp: ', compPFsTemp)
      console.log('- activeFilmInventoriesRef.current: ', activeFilmInventoriesRef.current)
      console.groupEnd('- end seperate temp stockPIs && compPIs')

      console.group('- compPIsTemp group')
      const compPITempGrouped = _.groupBy(_.orderBy(compPIsTemp, 'sku'), function (o) {
        return [o.sku, o.graphicPosition]
      })
      // console.log(`- compPIGrouped: count: ${Object.values(compPITempGrouped).length} `, compPITempGrouped)
      console.groupEnd('- end compPIsTemp group')

      console.groupCollapsed('- compPITempGrouped mapping')
      let compPIsMapped = Object.entries(compPITempGrouped)
        .map(g => {
          const {sku, inventoryArea, stock, priority, category, color, graphicPosition: gp, position_priority: gp_priority} = g[1][0]
          let tempPackDocs = []

          for (let b of g[1]) {
            let { a } = b
            let {packing_docs} = a
            for (let c of packing_docs) {
              tempPackDocs.push(c)
            }
          }

          return { docs_count: tempPackDocs.length, sku, inventoryArea, stock, priority, category, color, 'packing_docs': tempPackDocs, gp, gp_priority}
        })
      
      console.log('- compPIsMapped: ', compPIsMapped)
      console.groupEnd('- end compPITempGrouped mapping')
        
      console.groupCollapsed('- stockPIsTemp loop')
      // console.group('- stockPIsTemp loop')
      let clonedStockPIs = _.cloneDeep(stockPIsTemp)
      let clonedStockPIsCount = 0;
      for (let p of clonedStockPIs) {
        const { sku, packing_docs, _inventoryArea, totalOrdered, totalInstock } = p
        let picking_suggests = []
        clonedStockPIsCount = clonedStockPIsCount + 1
        console.log(`+ ${clonedStockPIsCount}/${clonedStockPIs.length} of clonedStockPIs(grouped) loop : ${sku}`)
        if (totalOrdered > totalInstock) console.log(`@ case of shortage stockPI(grouped): shortage: ${totalOrdered - totalInstock}`)
        
        console.log(`- inventoryArea(${_inventoryArea.length}), totalOrdered: ${totalOrdered}, totalInstock: ${totalInstock}`)
        
        let clonedInventories = _.cloneDeep(_inventoryArea)
        console.log('- _inventoryArea: ', _inventoryArea)
        let orderedRemainder = totalOrdered

        console.groupCollapsed(`- packing_docs(${packing_docs.length}) loop`)
        let dCount = 0
        for (let d of packing_docs) {
          dCount = dCount + 1
          let { quantity: ordered, pickedQty: picked, orderNumber, orderId, sku } = d
          let picking_suggest = {}
          console.log(`++ ${dCount}/${packing_docs.length} of packing_docs`)
          console.log(`- orderNumber: ${orderNumber}, ordered: ${ordered}, picked: ${picked}`)

          console.log('- action: make a picking_suggest for pick-up')

          console.group('- clonedInventories loop')
          let ciIndex = 0
          for (let ci of clonedInventories) {
            console.log(`+ ${ciIndex + 1}/${clonedInventories.length} of clonedInventories`)
            const { stock, areaCode } = ci
            console.log(`- stock: ${stock}, areaCode: ${areaCode}`)
            console.log(`- orderedRemainder: ${orderedRemainder}`)
            // if (stock > 0) {
              const diffValue = stock - ordered
              console.log('- diffValue: ', diffValue)
              if (diffValue >= 0) {
                console.log('- case of sufficient stock')
                console.log('- action: push picking_suggest into picking_suggestions and change stock as left-over value(diffValue) ')
                picking_suggest = { picking_qty: ordered, areaCode, sku, orderId }
                const foundIdx = _.findIndex(picking_suggests, { areaCode })
                // console.log('- found index from picking_suggests: ', foundIdx)
                if (foundIdx !== -1) {
                  // console.log('- case of found in picking_suggests')
                  // console.log('- action: add this ordered at found picking_qty')
                  const { picking_qty } = picking_suggests[foundIdx];
                  picking_suggests[foundIdx].picking_qty = picking_qty + ordered
                } else {
                  // console.log('- case of not exists in picking_suggests')
                  // console.log('- action: push this picking_suggest into picking_suggests')
                  picking_suggests.push(picking_suggest)
                }
                ci.stock = diffValue
                orderedRemainder -= ordered

                if (diffValue >= 0 || orderedRemainder===0) {
                  console.log('- case diffValue gte 0 or orderedRemainder is 0 then needed this doc break')
                  break
                } 
              } else {
                console.log('- case of in-sufficient')
                console.log('- stock: ', stock)
                if (stock > 0) {
                  console.log('- action: push picking_suggest(this stock) into picking_suggestions and change stock as 0')
                  picking_suggest = { picking_qty: stock, areaCode, sku, orderId }
                  picking_suggests.push(picking_suggest)
                  ci.stock = 0
                  orderedRemainder -= stock
                }

                if (ciIndex === clonedInventories.length - 1) {
                  console.log('- case of last inventories')
                  console.log('- action : add a meta w/ shortage qty')
                  ////??
                  d['meta'] = {
                    message: 'This packing_doc is needed to be produced',
                    isShortage: true,
                    shortageQty: diffValue * -1,
                    initOrdered: ordered
                  }
                  orderedRemainder -= diffValue * -1
                  /////
                  
                }

              }

              ciIndex += 1
          } // end of inventories loop
          console.groupEnd('end of clonedInventories loop')
          
        } // end packing_docs loop
        
        console.groupEnd('- end packing_docs loop')
        console.log('-> orderedRemained after inv loop: ', orderedRemainder)

        p.picking_suggests = picking_suggests
      }// end clonedStockPIs loop

      console.groupEnd('- end stockPIsTemp loop')

      console.groupCollapsed('- StockPIs: find a shortage packing_doc and move into compPIsSorted')
      let clonedStockPIsIndex = 0 // index of clonedStockPIs
      for (let s of clonedStockPIs) {
        const { packing_docs } = s
        let clonedPackingDocs = _.cloneDeep(packing_docs)
        let packingDocsIndex = 0 // index of packing_docs
        for (let p of clonedPackingDocs) {
          if (p?.meta?.isShortage) {
            console.log(`+ ${clonedStockPIsIndex+1} of stockPIs, ${packingDocsIndex+1}/${packing_docs.length} of packing_docs`)
            console.log(`- this doc isShortage: stock sku: ${p.sku} `, p)
            let sku;
            if (p?._item?._component) {
              sku = p._item._component.sku
            } else {
              break
            }
            console.log('- sku(component): ', sku)
            console.log('- action: find this sku in compPIsMapped: use findIndex')
            const foundIndex = _.findIndex(compPIsMapped, { sku })
            console.log('- foundIndex: ', foundIndex)
            console.log('- the found compPI: ', compPIsMapped[foundIndex])

            console.log('- action: consider ordered is needed to be changed if ordered is gt shortage on the packing_doc(p)')
            let ordered = p?.meta?.shortageQty
            // let picked = p?.pickedQty - p?.meta?.shortageQty <= 0 ? p.pickedQty : p?.pickedQty - p?.meta?.shortageQty
            console.log('- ordered: ', ordered)
            // console.log('- picked: ', picked)
            
            if (foundIndex !== -1) {
              console.log('- case of exist the found')
              compPIsMapped[foundIndex].packing_docs.push({ ...p, quantity: ordered })
            } else {
              console.log('- case of not exist the found')
              console.log('- action: create a compPI for push this p into packing_docs:: base of _component')
              const component = p?._item?._component ? p._item._component : null
              console.log('- component: ', component)
              
              let compPITemp = {
                sku: component?.sku,
                inventoryArea: component?.inventoryArea,
                stock: component?.stock,
                priority: component?.priority,
                category: component?.category,
                color: component?.color,
                gp: p._item?.graphicPosition,
                gp_priority: /[front]/i.test(p._item?.graphicPosition) ? 0 : 99,
                'packing_docs': [{ ...p, quantity: ordered }]
              }
              compPITemp['doc_count'] = compPITemp.packing_docs.length
              console.log('- compPITemp: ', compPITemp)
              compPIsMapped.push(compPITemp)
            } // end foundIndex condition

            console.log('- action: update quantity & pickedQty on the shortage packing_doc')
            console.log(`- the shortage packing_doc's index: ${clonedStockPIsIndex}, ${packingDocsIndex}`)

            clonedStockPIs[clonedStockPIsIndex].packing_docs[packingDocsIndex].quantity = p?.quantity - p?.meta?.shortageQty
            // clonedStockPIs[clonedStockPIsIndex].packing_docs[packingDocsIndex].pickedQty = p?.pickedQty - p?.meta?.shortageQty

          } // end if p.meta.isShortage
          packingDocsIndex = packingDocsIndex + 1
        }
        clonedStockPIsIndex = clonedStockPIsIndex + 1
      }//end loop of clonedStockPIs
      console.groupEnd('- end find a shortage')

      // console.log('- settings?.usePrintedFilm: ', settings?.usePrintedFilm) 
      if(settings?.usePrintedFilm) {
        console.group('- compPFsTemp group')
        const compPFTempGrouped = _.groupBy(_.orderBy(compPFsTemp, 'sku'), function (o) {
          return [o.sku, o.graphicPosition]
        })
        compPFsMapped = Object.entries(compPFTempGrouped)
        .map(g => {
          const {sku, inventoryArea, stock, priority, category, color, graphicPosition: gp, position_priority: gp_priority, totalFilmInventory, graphicFilename} = g[1][0]
          let tempPackDocs = []

          for (let b of g[1]) {
            let { a } = b
            let {packing_docs} = a
            for (let c of packing_docs) {
              tempPackDocs.push(c)
            }
          }

          return { docs_count: tempPackDocs.length, sku, inventoryArea, stock, priority, category, color, 'packing_docs': tempPackDocs, gp, gp_priority, totalFilmInventory, graphicFilename}
        })
      
        console.log('- compPFsMapped: ', compPFsMapped)

        let cIdx = 0
        for(let c of compPFsMapped) {
          let pIdx = 0
          const {sku} = c
          for(let p of c.packing_docs) {
            // console.log(p)
            const graphics = p?._item?._graphics ? p._item._graphics : null
            const ordered = p.quantity
            
            if(graphics && Boolean(graphics.length)) {
              // console.log('- lenGraphics: ', graphics.length)
              for(let g of graphics) {
                if(g?._filmInventory) {
                  // console.log('- c:', cIdx, c)
                  // console.log('- p:', pIdx, p)
                  // console.log('- g:', g)
                  const graphicFilename = g?.graphicFileName
                  const filmInvIdx = _.findIndex(activeFilmInventoriesRef.current, {graphicFilename})
                  // console.log('- filmInvIdx: ', filmInvIdx)
                  let stock = 0;
                  if(filmInvIdx !==-1) stock = activeFilmInventoriesRef.current[filmInvIdx]?.stock
                  if(stock > 0) {
                    //compare ordered and film stock
                    const remain = stock - ordered
                    // console.log(graphicFilename, '-stock:',stock, ', -ordered:',ordered,', -remain:', remain)
                    if(remain >= 0) {
                      // console.log('- case of remain is sufficient')
                      // console.log('- idxCompPFTemp: ', idxCompPFTemp)
                      //update activeFilmInventories's stock
                      activeFilmInventoriesRef.current[filmInvIdx].stock = remain
                    } else {
                      // console.log('- case of remain is negave')
                      p.meta ={initOrdered: _.clone(p.quantity), description: 'Split off from printed films'}
                      p.quantity = stock 
                      //move packing_doc to compPIsMapped
                      const foundIndex = _.findIndex(compPIsMapped, { sku })
                      // console.log('- foundIndex: ', foundIndex)
                      // console.log('- the found compPI: ', compPIsMapped[foundIndex])

                      
                      if (foundIndex !== -1) {
                        // console.log('- case of exist the found')
                        compPIsMapped[foundIndex].packing_docs.push({ ...p, quantity: remain*-1 })
                      } else {
                        // console.log('- case of not exist the found')
                        // console.log('- action: create a compPI for push this p into packing_docs:: base of _component')
                        // if(c?.graphicFilename) delete c.graphicFilename
                        compPIsMapped.push({...c, packing_docs: [{...p}]})
                        activeFilmInventoriesRef.current[filmInvIdx].stock = 0

                      }
                    }

                  }
                }
              }

            }
            pIdx = pIdx + 1
          }
          cIdx = cIdx + 1
        }
        console.groupEnd()
      }
      // end film inventory

      // let stockPIsSorted = _.orderBy(clonedStockPIs, ['sku', '_inventoryArea[0].areaCode'])
      let stockPIsSorted = _.orderBy(clonedStockPIs, [pi => pi._inventoryArea[0].areaCode.toLowerCase(), 'sku'])
      console.log('- stockPIsSorted: ', stockPIsSorted)
      setFinishedGoods(stockPIsSorted)

      if(compPFsMapped) {
        let compPFsSorted = _.orderBy(compPFsMapped, ['gp_priority', 'priority', 'sku'])
        console.log('- compPFsSorted: ', compPFsSorted)
        setComponentsPF(() => {
          return compPFsSorted
        })
      } 

      let compPIsSorted = _.orderBy(compPIsMapped, ['gp_priority', 'priority', 'sku'])
      console.log('- compPIsSorted: ', compPIsSorted)
      setComponents(compPIsSorted)

      setTimeout(() => {
        setLoading(false);
        setMsg(null)
      }, 1500);
      
      console.groupEnd('* end PickingList init')
    }

  }, [settings, packingItems])
  
  useEffect(() => {
    // console.log("packingItems: ", packingItems)
    if (packingItems) {
      // PickingList(packingItems);
      PickingList();
    }
    // eslint-disable-next-line
  }, [packingItems]);

  const onSKUPrintResult = useCallback(() => {
    socket.on("on-sku-print-result", (result) => {
      console.log("on-sku-print-result", result);
      if (result?.name === "Error") {
        toast.error(result?.message, {
          position: "bottom-right",
        })
      } else {
        toast.info(result?.message, {
          position: "bottom-right",
          autoClose: 1500
        })
      }
    })
  }, []);

  useEffect(() => {
    onSKUPrintResult();

    return () => {
      socket.removeAllListeners(["on-sku-print-result"])
    }
  }, [onSKUPrintResult]);

  useEffect(() => {
    // console.log('* all states hook init')
    const allStates = [...components, ...componentsPF, ...finishedGoods]
    const isPickedAll = retValIsPicked(allStates)
    console.log('- isPickedAll: ', isPickedAll)
    setIsPickedOverall(isPickedAll)
  }, [components, componentsPF, finishedGoods])

  const mutatePackingitems = useMutation(async ({ _id, quantity, flag, stateIndex, docIndex}) => {
    console.log('* mutatePackingitems init')
    console.log('- quantity; ', quantity)
    return await updatePackingItem({
      condition: { id: _id },
      update: { pickedQty: quantity },
    })
  },{
    onSuccess: async (result, variables) => {
      console.log('- onSucess result: ', result)
      console.log('- onSucess variables: ', variables)
      const {flag, stateIndex, docIndex} = variables
      changeStateDoc({flag, stateIndex, docIndex, pickedQty: result.pickedQty})  

      const {orderId} = result;
      await getPackingItemsByOrder({orderId})
        .then(async p => {
          // console.log('- p: ', p)
          let pickedQty = _.sumBy(p, "pickedQty");
          // console.log('- pickedQty: ', pickedQty);
          let payload = {
            condition: { orderId },
            update: { pickedQty }
          };
          await updateOrder(payload)
        })

      // queryClient.invalidateQueries(['packingItems'])
    }
  })

  const updateInventoryTransaction = async ({flag, states}) => {
    console.log('* updateInventoryTransaction init')
    console.log('- flag: ', flag)
    console.log('- states: ', states)
    if(flag==='fg') flag = 'production'
    let transactionArr = [];
    let transactionObjInit = {
      sku: null,
      quantity:  0,
      description: "",
      inventoryArea: null,
      type: flag,
      user: user?.username ? user.username : 'admin',
    }


    let sumPickedQty = 0, sumOrdered = 0, transactionQty = 0; 
    if(flag==='component') {
      _.forEach(states, (s) => {
        sumPickedQty = _.sumBy(s.packing_docs, "pickedQty");
        sumOrdered = _.sumBy(s.packing_docs, "quantity");
        transactionQty = sumOrdered - sumPickedQty;
        if (transactionQty > 0 && !_.isNil(s.sku) && !_.isEmpty(s.sku) ) {
          transactionArr.push({
            ...transactionObjInit, 
            sku: s.sku,
            quantity: transactionQty * -1,
            description: `Batch output of ${flag} by picking list`,
            inventoryArea:s.inventoryArea
          })
        }
      })
    } else {
      _.forEach(states, (s) => {
        console.log(s.sku)
        sumPickedQty = _.sumBy(s.packing_docs, "pickedQty");
        console.log('-sumPickedQty: ', sumPickedQty)
        sumOrdered = _.sumBy(s.packing_docs, "quantity");
        transactionQty = sumOrdered - sumPickedQty;
        let remainderSuggestPicking = _.sumBy(s.picking_suggests, "picking_qty");
        if (transactionQty > 0) {
          console.log('-transactionQty: ', transactionQty)
          console.log('-remainderSuggestPicking: ', remainderSuggestPicking)
          for(let i=0, len=s.picking_suggests.length;i<len;i++) {
            const suggest = s.picking_suggests[i];
            if (transactionQty > 0) {
              if(sumPickedQty===0) {
                console.log('-case of sumPickedQty===0')
                transactionArr.push({
                  ...transactionObjInit, 
                  sku: s.sku,
                  quantity: suggest.picking_qty * -1,
                  description: `Batch output of F/G by picking list`,
                  inventoryArea:suggest.areaCode
                });
                remainderSuggestPicking = remainderSuggestPicking - suggest.picking_qty
                console.log('-remainderSuggestPicking: ', remainderSuggestPicking)
                if(remainderSuggestPicking===0) break;
              } 
              if(sumPickedQty > 0){
                console.log('-case of sumPickedQty > 0')
                
                remainderSuggestPicking = remainderSuggestPicking - sumPickedQty;
                console.log('-remainderSuggestPicking: ', remainderSuggestPicking);
                if(remainderSuggestPicking >= transactionQty ) {
                  transactionArr.push({
                    ...transactionObjInit, 
                    sku: s.sku,
                    quantity: transactionQty * -1,
                    description: `Batch output of F/G by picking list`,
                    inventoryArea:suggest.areaCode
                  });
                  if(remainderSuggestPicking===0) break;
                }
              }

            }
          } 
          
        }
      })
    }

    console.log('- transactionArr: ', transactionArr)

    let transactionPromises = transactionArr.map(async t => addTransaction({transaction: t}));

    Promise.all(transactionPromises);
  }

  const batchUpdatePickedQty = (states) => {
    console.log('* batchUpdatePickedQty init')
    //save all pickedQty
    let promises = []
    for (let s of states) {
      // console.log('- s: ', s)
      for (let d of s.packing_docs) {
        // console.log('- d: ', d)
        let { _id, quantity } = d
        if (d.quantity > d.pickedQty) {
          promises.push(
            updatePackingItem({
              condition: { id: _id },
              update: { pickedQty: quantity },
            })
          )
        } 
        // else {
        //   if(flag==='pf') {
        //     if(d?.quantity_orig && (d.quantity_orig > d.pickedQty)) {
        //       promises.push(
        //         updatePackingItem({
        //           condition: { id: _id },
        //           update: { pickedQty: d.quantity_orig },
        //         })
        //       )
        //     }
        //   }
        // }
      }
    }

    if (Boolean(promises.length)) {
      Promise.all(promises)
        .then(res => {
          console.log('- batchUpdatePickedQty promise all res: ', res)
          // queryClient.invalidateQueries(['packingItems'])
        }).catch(() => setLoading(false))
    }
  }

  const changeStates = (flag) => {
    function retVal(old) {
      let temp = _.cloneDeep(old)
      for(let e of temp) {
        for(let p of e.packing_docs){
          p.pickedQty = p.quantity
        }
      }
      return temp
    }

    if (flag === 'fg') {
      setFinishedGoods(old => {
        return retVal(old)
      })
    } else if (flag === 'component') {
      setComponents(old => {
        return retVal(old)
      })
    } else if (flag === 'pf') {
      setComponentsPF(old => {
        return retVal(old)
      })
    }
    
  }

  const changeStateDoc = ({flag, stateIndex, docIndex, pickedQty}) => {
    console.log('* changeStateDoc init')
    if (flag === 'fg') {
      setFinishedGoods(old => {
        let temp = _.cloneDeep(old);
        temp[stateIndex]["packing_docs"][docIndex]["pickedQty"] = pickedQty
        return temp
      })
    } else if (flag === 'component') {
      setComponents(old => {
        let temp = _.cloneDeep(old);
        temp[stateIndex]["packing_docs"][docIndex]["pickedQty"] = pickedQty
        return temp
      })
    } else if (flag === 'pf') {
      setComponentsPF((old) => {
        let temp = _.cloneDeep(old);
        temp[stateIndex]["packing_docs"][docIndex]["pickedQty"] = pickedQty
        return temp
      })
    }
  }

  //run after modalConfirmHandler
  const batchUpdateHandler = async ({flag, states}) => {
    console.log('* batchUpdateHandler init')
    console.log('- flag: ', flag)
    //save all pickedQty
    setLoading(true)
    await batchUpdatePickedQty(states)
    // update orders' pickedQty as quantity(ordered)
    await updateOrdersPickedAsOrdered();
    // update inventory transactions by flag
    if(flag !== 'pf' && settings?.useInventoryModule) {
      await updateInventoryTransaction({flag, states})
    }

    if(flag ==='pf' && settings?.usePrintedFilm) {
      if(activeFilmInventoriesRef.current) {
        let promises;
        promises = activeFilmInventoriesRef.current.map(a => {
          let payload  = {
            condition: {
              graphicFilename: a.graphicFilename,
            },
            update: {
              stock: a.stock
            }
          }
          return updatePrintedFilm(payload)
        })
  
        if(promises) await Promise.all(promises)

      }
    }

    // change states
    changeStates(flag)
    setLoading(false)
 
  }

  const modalConfirmHandler = async () => {
    console.log('* modalConfirmHandler init')
    console.log('- flag: ', flag)
    // if flag is true it's printalllabel or individual states
    modalConfirmIsOpen && setModalConfirmIsOpen(false);
    let states = []

    if(flag) {
      if (flag === 'fg') {
        if (Boolean(finishedGoods.length)) {
          states = finishedGoods
          await batchUpdateHandler({states, flag})
        }
      } else if(flag === 'component') {
        if (Boolean(components.length)) {
          states = components
          await batchUpdateHandler({states, flag})  
        }
      } else if(flag === 'pf') {
        if (Boolean(componentsPF.length)) {
          states = componentsPF
          await batchUpdateHandler({states, flag})
        }
      }
      // 
    } else {
      if (Boolean(finishedGoods.length)) {
        await batchUpdateHandler({states: finishedGoods, flag: 'fg'})
      }
      if (Boolean(componentsPF.length)) {
        await batchUpdateHandler({states: componentsPF, flag: 'pf'})
      }
      if (Boolean(components.length)) {
        await batchUpdateHandler({states: components, flag: 'component'})  
      }
    }

    flag && setFlag(null)
    
  }

  const retValIsPicked = (states) => {
    console.log('* retValIsPicked init')
    let isPicked = false
    let sumOrdered = 0, sumPicked = 0;
    // console.log('- states: ', states)
    // console.log('- isArray: ', _.isArray(states))
    if(_.isArray(states)) {
      for (let s of states) {
        // console.log('- s: ', s)
        for (let d of s.packing_docs) {
          // console.log('- d: ', d)
          sumOrdered += d.quantity
          sumPicked += d.quantity < d.pickedQty ? d.quantity : d.pickedQty
        }
      }
      // console.log(`- sumOrdered: ${sumOrdered}, sumPicked: ${sumPicked}`)

    } else {
      for (let d of states.packing_docs) {
        // console.log('- d: ', d)
        sumOrdered += d.quantity
        sumPicked += d.quantity < d.pickedQty ? d.quantity : d.pickedQty
      }
    }

    if (sumOrdered === sumPicked) isPicked = true;
    return isPicked
  }

  function printHandler({states, flag}) {
    console.log('* printHandler init')
    // console.log('- flag: ', flag)

    return new Promise((resolve) => {
      let isLastState = false
      for (let f = states.length - 1; f >= 0; f--) {
        // console.log(`- ${f}/${states.length}`)
        const item = states[f]
        // console.log('- item: ', item)
        handlePrintLabel({ data: item, flag })
        if (f === 0) {
          // console.log('- f is reached the last state: ', f)
          isLastState = true
        }
      }

      if (isLastState) {
        // console.log('- isLastState: ', isLastState)
        resolve(true)
      }

    })
      
  }// end printHandler

  const handlePrintLabelAll = async () => {
    console.log("* handlePrintLabelAll init");
    // console.log('- isPickedOverall: ', isPickedOverall)
    if (isPickedOverall) {
      let confirm = window.confirm(printedLabelWarning);
      // console.log("- confirm: ", confirm);
      if (!confirm) return;
    }

    setLoading(true)

    if (Boolean(finishedGoods.length)) {
      await printHandler({states: finishedGoods, flag: 'fg'})
    }
    if (Boolean(componentsPF.length)) {
      await printHandler({states: componentsPF, flag: 'pf'})
    }
    if (Boolean(components.length)) {
      await printHandler({states: components, flag: 'component'})  
    }
    setLoading(false)

    if(!isPickedOverall) {
      setTimeout(() => {
        user?.username !== "super" && log.info(`Print All Label init at ${new Date()}`)
        setModalConfirmIsOpen(true);
      }, 2000)
    }
  }

  const handlePrintLabelByFlag = async ({states, flag}) => {
    console.log('* handlePrintLabelByFlag init')
    // console.log('- flag: ', flag)
    setFlag(flag)
    const isPicked = retValIsPicked(states)
    // console.log('- isPicked: ', isPicked)
    if(isPicked) {
      let confirm = window.confirm(printedLabelWarning);
      // console.log("- confirm: ", confirm);
      if (!confirm) return;
    }
    await printHandler({ states, flag})
    // saving pickedQty and changing states
    if(!isPicked) {
      setTimeout(() => {
        setModalConfirmIsOpen(true);
      }, 2000)
    }
  }

  function printLabelMiddleware({item, platens, settings, type, isPicked, areaCode}) {
    console.log('* printLabelMiddleware init')
    let zpl;
    if(!settings?.printLabelByGraphicPositions) {
      if(item?._item?._graphics && Boolean(item?._item?._graphics.length)) {
        let tempGraphicPositionArr = [];
        for(let g of item?._item?._graphics) {
          if(g?.graphicPosition) tempGraphicPositionArr.push(g.graphicPosition)
        }
        if(Boolean(tempGraphicPositionArr.length)) {
          item._item.graphicPosition = tempGraphicPositionArr.join(",");
        }
      }
      zpl = labelTemplateQR({ item, platens, settings, type, isPicked, areaCode });
      zpl && socket.emit("on-sku-print", zpl);
    } else {
      if (item?._item?._graphics.length > 1) {
        for (let graphic of item._item._graphics) {
          // console.log('- graphic: ', graphic)
          if (item?._item?.graphicPosition)
            item._item.graphicPosition = graphic.graphicPosition;
          zpl = labelTemplateQR({ item, platens, settings });
          zpl && socket.emit("on-sku-print", zpl);
        }
      } else {
        if (item?._item?._graphics.length === 1) {
          item._item.graphicPosition = item._item._graphics[0]?.graphicPosition;
        }

        zpl = labelTemplateQR({ item, platens, settings });
        zpl && socket.emit("on-sku-print", zpl);
      }
    }
  }

  function findStateIndex({state, flag}) {
    console.log('- findStateIndex init')
    // console.log('- state: ', state)
    let index;
    let states = []
    const {sku} = state;
    // console.log('- sku: ', sku)
    if (flag === 'fg') {
      if (Boolean(finishedGoods.length)) {
        states = finishedGoods
      }
    } 
    else if(flag === 'component') {
      if (Boolean(components.length)) {
        states = components
      }
    } else if(flag === 'pf') {
      if (Boolean(componentsPF.length)) {
        states = componentsPF
      }
    }

    index = _.findIndex(states, {sku})
    // console.log('- find index: ', index)
    return index
  }

  const handlePrintLabel = async ({ data, flag, from}) => {
    console.log("* handlePrintLabel init")
    // console.groupCollapsed("* handlePrintLabel init")
    // console.log("- data:", data);
    // console.log("- flag:", flag);
    // console.log("- from:", from);
    // console.log("- index:", index);
    const { packing_docs, picking_suggests } = data
    let type = 'component';
    let isPicked = false
    isPicked = retValIsPicked(data)
    console.log('- isPicked: ', isPicked)
    let confirm = false
    if (from) {
      if(settings?.printLabelByGraphicPositions) {
        setMsg('Printing label by graphic position is truned on in Settings, so labels will be printed off only graphic exists')
      }
      if (isPicked) {
        confirm = window.confirm(printedLabelWarning);
        // console.log("- confirm: ", confirm);
        if (!confirm) return;
      } else {
        //inventory transactions
        if(flag!=='pf' && settings?.useInventoryModule) {
          await updateInventoryTransaction({flag, states: [data]})
        }
      }
    } 
    // console.log('- confirm: ', confirm)

    type = flag;
    let docIndex = 0
    for (let item of packing_docs) {
      console.log(`+ ${docIndex+1}/${packing_docs.length} of packing_docs: `);
      // console.log('- item: ', item)
      const { orderId, sku } = item
      
      let areaCode;
      let graphicFilename;
      let printedFilmId;
      if (flag === 'fg') {
        if (picking_suggests.length === 1) {
          areaCode = picking_suggests[0]?.areaCode
        } else {
          const foundPickingSuggest = _.find(picking_suggests, { orderId, sku })
          // console.log('- foundPickingSuggest: ', foundPickingSuggest)
          areaCode = foundPickingSuggest?.areaCode
        }
      } else if(flag==='pf') {
        let areaCodeTemp = item._item._graphics.map(g => {
          graphicFilename = g._filmInventory?.graphicFilename 
          areaCode = g._filmInventory?.areaCode;
          const dotIndex = _.lastIndexOf(graphicFilename, ".")
          if(dotIndex !==-1) {
            printedFilmId = graphicFilename.substring(0, dotIndex)
          }
          return `${printedFilmId},${areaCode}`
        })
        areaCode = areaCodeTemp.join(' ')
      }

      // console.log('- setting.printLabelByGraphicPositions: ', settings?.printLabelByGraphicPositions)
      item.pickedQty = item.quantity < item.pickedQty ? item.quantity : item.pickedQty;
      // console.log('- item.quantity: ', item.quantity)
      // console.log('- item.pickedQty: ', item.pickedQty)
      //new labels exist
      if (!isPicked) {
        if (item?.quantity > 0) {
          if(item?.quantity > item?.pickedQty) {
            //QR code
            // console.log('- printing component sku in packing_doc: ', docsCount, item?._item?.component)
            printLabelMiddleware({ item, platens, settings, type, isPicked, areaCode })
    
            if (from==='action') {
              const {_id, quantity, pickedQty} = item
              if (quantity > pickedQty) {
                // console.log('- calling mutatePackingitems')
                let stateIndex = findStateIndex({state: data, flag})
                // console.log('- stateIndex: ', stateIndex)
                // console.log('- docCount: ', docIndex)
                // updated pickedQty
                mutatePackingitems.mutate({_id, quantity, flag, stateIndex, docIndex})
                
                // printed-film stock update
                if(flag ==='pf' && settings?.usePrintedFilm) {
                  if(graphicFilename) await updatePrintedFilmStock({graphicFilename, qty: quantity*-1})
                }
              }
            }
          } 
        }
      // re-print labels
      } 
      else {
        if (confirm && item?.quantity > 0) {
          printLabelMiddleware({ item, platens, settings, type, isPicked, areaCode })
        }
      }
      docIndex += 1

    }// end for
    
    // console.groupEnd('- end packing_docs loop')

  };

  const printCollectionList = () => {
    console.log("== printing collection list ==");
    const printContent = document.getElementById("print-section").innerHTML;

    const frame1 = document.createElement("iframe");
    frame1.name = "frame3";
    frame1.style.position = "absolute";
    frame1.style.top = "-100000000px";
    document.body.appendChild(frame1);

    const frameDoc = frame1.contentWindow
      ? frame1.contentWindow
      : frame1.contentDocument.document
        ? frame1.contentDocument.document
        : frame1.contentDocument;

    frameDoc.document.open();
    frameDoc.document.write("<html><head><title>Print Picking List</title>");
    frameDoc.document.write("<style>");
    frameDoc.document.write(
      "body,table{font-family:Arial,Helvetica,sans-serif;font-size:9pt}"
    );
    frameDoc.document.write(
      ".text-center{text-align:center}.text-right{text-align:right}"
    );
    frameDoc.document.write("table th{text-align:center}");
    frameDoc.document.write(
      "table{border-collapse:collapse;border:#666 .5px solid;width:100%}"
    );
    frameDoc.document.write(
      "table thead th, table tbody td{border:#666 .5px solid;padding:5px;}"
    );
    frameDoc.document.write(
      "table tbody tr.hidden{display: none;}"
    );
    frameDoc.document.write(".print-section_items{margin-top:10px;}");
    frameDoc.document.write("</style>");
    frameDoc.document.write('</head><body onafterprint="window.close()">');
    frameDoc.document.write(printContent);
    frameDoc.document.write("</body></html>");
    frameDoc.document.close();
    setTimeout(() => {
      window.frames["frame3"].focus();
      window.frames["frame3"].print();
      document.body.removeChild(frame1);
    }, 1000);
  };

  const filmInventoryAreaCodes = (packing_docs) => {
    // console.log('- packing_docs: ', packing_docs);
    const areaCodes = [];
    for(let p of packing_docs) {
      const {_item, quantity} = p
      // console.log(_item)
      if(_item && _item?._graphics) {
        for(let g of p?._item?._graphics){
          // console.log(g)
          const {_filmInventory} = g
          if(_filmInventory) {
            let {graphicFilename, areaCode} = _filmInventory
            if(graphicFilename) {
              // console.log(graphicFilename)
              const dotIndex = _.lastIndexOf(graphicFilename, ".")
              // console.log(dotIndex)
              if(dotIndex !==-1) {
                graphicFilename = graphicFilename.substring(0, dotIndex)
              }
              areaCodes.push({graphicFilename, areaCode, quantity})

            }
          }
        }

      }
    }
    
    return areaCodes
  }

  const TotalPickupQty = ({ states }) => {
    let ordered = 0, picked = 0;
    for (let s of states) {
      for (let p of s?.packing_docs) {
        ordered += p.quantity
        picked += p.pickedQty
      }
    }
    // console.log(`- orderded: ${ordered}, picked: ${picked}`)
    let balance = ordered < picked ? 0 : ordered - picked;

    return (
      <span>Recent quantity to be collected: <b>{balance}</b></span>
    )
  }

  return <>
      <Title title={title} />
      <PageMainHeader 
        title={title} 
        user={user} 
        settings={settings} 
      />
      <ToastContainer theme='dark'/>
      <section className="primary">
        {isLoading ? (
          <Skeleton count={20} height={50} circle={true} />
        ) : isError ? (
          <Banner variant='danger'>{error?.message ? error.message : error}</Banner>
        ) : (
          null
        )}
        <PageMenuHeader>
          <Button
            variant="primaryOutline"
            className="swing-icon"
            onClick={printCollectionList}
            leftGlyph={<FaListAlt />}
          >
            Print Picking List
          </Button>
          <Button
            variant="danger"
            className="swing-icon"
            onClick={() => handlePrintLabelAll()}
            leftGlyph={<FaPrint/>}
          >
            Print All labels
          </Button>

        </PageMenuHeader>
        <Tabs setSelected={setSelectedTab} selected={selectedTab} aria-label='Select a tab'>
            {(components && Boolean(components.length)) &&(
              <Tab name="Components">
                  <PickingComponents
                    components={components}
                    handlePrintLabel={handlePrintLabel}
                    printHandler={printHandler}
                    TotalPickupQty={TotalPickupQty}
                    handlePrintLabelByFlag={handlePrintLabelByFlag}
                    msg={msg}
                  />
              </Tab>
            )}
          {(settings?.usePrintedFilm && componentsPF && Boolean(componentsPF.length)) && (
            <Tab name="Components/Printed Films">
              <PickingComponentsPF
                components={componentsPF}
                handlePrintLabel={handlePrintLabel}
                filmInventoryAreaCodes={filmInventoryAreaCodes}
                TotalPickupQty={TotalPickupQty}
                handlePrintLabelByFlag={handlePrintLabelByFlag}
              />
            </Tab>

          )}
          {(finishedGoods && Boolean(finishedGoods.length)) && (
            <Tab name="Finished Goods">
                <PickingStocks
                  finishedGoods={finishedGoods}
                  handlePrintLabel={handlePrintLabel}
                  TotalPickupQty={TotalPickupQty}
                  handlePrintLabelByFlag={handlePrintLabelByFlag}
                />
            </Tab>
          )}
        </Tabs>
        {(components && finishedGoods) && (
          <PickingListPrint components={components} finishedGoods={finishedGoods} componentsPF={componentsPF} TotalPickupQty={TotalPickupQty} filmInventoryAreaCodes={filmInventoryAreaCodes}  />
        )}
        <ConfirmationModal
          open={modalConfirmIsOpen}
          onConfirm={modalConfirmHandler}
          onCancel={() => setModalConfirmIsOpen(false)}
          buttonText='Confirm'
          title="Confirm saving picked quantities"
        >
          Did the label print without problems? Then proceed to save the picked quantity
        </ConfirmationModal>
        <ModalPreloader modalPreloaderIsOpen={loading} />
        <ScrollTop />
        {/* <pre>{JSON.stringify(activeFilmInventoriesRef.current, null, 2)}</pre> */}
        {/* <pre>{JSON.stringify(components, null, 2)}</pre> */}
      </section>
    </>
}
