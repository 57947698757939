import React, { useState, useRef, useContext } from "react";
import { FaSignInAlt } from "react-icons/fa";
import { AuthContext } from "contexts/AuthContext";
import { Redirect } from "react-router-dom";
import Button from "@leafygreen-ui/button";
import Banner from "@leafygreen-ui/banner";
import { getSettings } from "utils/settings";
import { getSessions } from "utils/sessions";

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  // const [branch, setBranch] = useState("");
  const {user, login, authError, setAuthError} = useContext(AuthContext)
  const loginFrmRef = useRef();

  // console.log('- user: ', user)

  const handleSubmit = async (e) => {
    console.log('* handleSubmit init')
    // console.log('- username: ', username)
    // console.log('- password: ', password)
    e.preventDefault();
    // console.log(loginFrmRef.current);
    // setAuthError(null);
    loginFrmRef.current.reportValidity();
    if (loginFrmRef.current.checkValidity()) {
      await login(username, password)
        .then(() => {
          return getSettings()
        })
        .then(() => {
          return getSessions()
        })
    }
  };
  
  React.useEffect(() => {
    setAuthError(null)
  // eslint-disable-next-line  
  }, [])

  if (user) return <Redirect to="/" />;

  return (
    <>
      <div className="login-wrapper">
        <h1>Printingify</h1>
        <div className="card">
          <form
            className="login-form"
            ref={loginFrmRef}
            onSubmit={handleSubmit}
          >
            <div className="input-field">
              <label htmlFor="username">User name</label>
              <input
                type="text"
                name="username"
                id="username"
                autoComplete="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="input-field">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                name="password"
                id="password"
                autoComplete="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            {
              authError && (
                <Banner variant="danger" className="mb-10">{authError}</Banner>
              )
            }
            
            <div className="form-footer">
              <Button
                variant="primary"
                type="submit"
                leftGlyph={<FaSignInAlt />}
              >
                Login
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
