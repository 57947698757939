import React from 'react';
import IconButton from "@leafygreen-ui/icon-button";
import { FaArrowCircleUp } from "react-icons/fa";

export default function ScrollTop() {
  const [showScrollBtn, setShowScrollBtn] = React.useState(false);
  const elem = document.querySelector("section");
  // console.log('- elem: ', elem)
  // console.log('- offsetHeight: ', elem?.offsetHeight, 'offsetTop', elem?.offsetTop)
  const h = elem?.offsetHeight || 700
  // console.log('- h(criteria): ', h)
  
  if(elem) {
    elem.addEventListener('scroll', event => {
      let st = elem.scrollTop;
      // console.log(`- st(scrollTop): ${elem.scrollTop}`)
      if(st < h) {
        showScrollBtn && (setShowScrollBtn(false))
      } else {
        setShowScrollBtn(true)
      }
    })
  }
  
  const scrollToTop = () => {
    // console.log('* scrollToTop init')
    elem.scrollTo({
      top: 0, 
      behavior: 'smooth'
    });
  };
    
  return (
    <div className="scroll-to-top-container">
      {
        showScrollBtn && (
          <IconButton
            onClick={scrollToTop}
            aria-label="Scroll to Top"
            size="xlarge"
          >
            <FaArrowCircleUp fill="green" title="Scroll to top" size="25"/>
          </IconButton>
        )
      }

    </div>
  );
}