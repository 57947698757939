import { axiosInstance as axios } from "configs/axiosConfig";
import { errorHandler } from "./errorHandler";

export const getOrders = ({ page, limit, pagination }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `/orders`;
      const response = await axios.get(url, {
        params: { page, limit, pagination },
      });
      // console.log("getOrders response", response);
      resolve(response.data);
    } catch (error) {
      console.error("getOrders error", error);
      reject(error);
    }
  });
};

export const getLocalOrder = (orderId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `/orders/get-order/${orderId}`;
      const response = await axios.get(url);
      // console.log("- getLocalOrder response: ", response);
      resolve(response.data);
    } catch (error) {
      console.error("getLocalOrder error", error);
      reject(error);
    }
  });
};

export const createOrder = (data) => {
  return new Promise((resolve, reject) => {
    const url = `/orders/create`;

    axios
      .post(url, { ...data })
      .then((res) => {
        // console.log("createOrder res", res);
        resolve(res.data);
      })
      .catch((err) => {
        // console.log("createOrder err", err);
        reject(err);
      });
  });
};

export const updateOrder = (data) => {
  return new Promise((resolve, reject) => {
    const url = `/orders/update`;

    axios
      .post(url, { ...data })
      .then((res) => {
        // console.log("updateOrder res", res);
        resolve(res.data);
      })
      .catch((err) => {
        console.log("updateOrder err", err);
        reject(err);
      });
  });
};

export const updateManyOrder = (data) => {
  return new Promise((resolve, reject) => {
    const url = `/orders/update-many`;

    axios
      .post(url, { ...data })
      .then((res) => {
        // console.log("updateOrder res", res);
        resolve(res.data);
      })
      .catch((err) => {
        console.log("updateOrder err", err);
        reject(err);
      });
  });
};

export const deleteLocalOrder = (orderId) => {
  return new Promise((resolve, reject) => {
    const url = `/orders/delete/${orderId}`;

    axios
      .delete(url)
      .then((res) => {
        // console.log("- deleteOrder res", res);
        resolve(res.data);
      })
      .catch((err) => {
        console.log("deleteOrder err", err);
        reject(err);
      });
  });
};

export const updateOrdersPickedAsOrdered = () => {
  return new Promise((resolve, reject) => {
    const url = `/orders/update-picked-ordered`;
    axios
      .post(url)
      .then((res) => {
        // console.log("updateOrdersPickedAsOrdered res", res);
        resolve(res.data);
      })
      .catch((err) => {
        console.log("updateOrdersPickedAsOrdered err", err);
        reject(err);
      });
  });
};

export const fetchShipStationOrders = (page, pageSize) => {
  console.info("* fetchOrders init");
  return new Promise((resolve, reject) => {
    // console.log("- page: ", page);
    axios
      .get(`/orders/fetch-orders`, {
        params: { page, pageSize },
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      })
      .then((res) => {
        // console.log("- res", res.data);
        resolve(res.data);
      })
      .catch((error) => {
        console.log("- error", error);
        let retval = errorHandler(error);
        console.log("- retval: ", retval);
        reject(retval);
      });
  });
};
