export default function ItemOptions({options}) {
  return (
      options.map((o, index) => {
        return (
          <div className="d-flex flex-direction-column" key={index}>
            <span className="truncate"><small>{o.name}: </small></span>
            <b>{o.value}</b>
          </div>
        ) 
      })
    )
}
