import React from 'react'
import { FaTrashAlt } from 'react-icons/fa';
import { CSVReader } from 'react-papaparse'
import Button from "@leafygreen-ui/button";

export default function BatchDelete({ batchDeleteHandler }) {
  // const [modalIsOpen, setModalIsOpen] = React.useState(false)
  const batchDeleteBtnRef = React.useRef()

  const handleOpenDialog = (e) => {
    if (batchDeleteBtnRef.current) {
      batchDeleteBtnRef.current.open(e);
    }
  };

  const handleOnFileLoad = (data) => {
    batchDeleteHandler(data);
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  return (
    <>
      <div>
        <CSVReader
          config={{ header: true, skipEmptyLines: true }}
          onError={handleOnError}
          onFileLoad={handleOnFileLoad}
          noProgressBar
          ref={batchDeleteBtnRef}
        >
          {() => (
            <Button
              variant="danger"
              className="swing-icon"
              onClick={handleOpenDialog}
              leftGlyph={<FaTrashAlt />}
            >
              Batch delete by CSV
            </Button>
          )}
        </CSVReader>
      </div>
    </>
  )
}
