import React from 'react'
import { format } from "date-fns"
import { FaUndo } from 'react-icons/fa'
import _ from "lodash"
import ReactTableContainer from 'utils/ReactTableContainer'
import IconButton from '@leafygreen-ui/icon-button'
import Tooltip from '@leafygreen-ui/tooltip'

export default function LogsList({logs, restorePickedQty}) {
  const hiddenColumns = ["_id"]
  const columns = React.useMemo(
    () => [
      { Header: "id", accessor: "_id" },
      {
        Header: "Timestamp", accessor: "timestamp",
        Cell: ({ row }) => {
          // console.log('- row: ', row)
          return (
            <span>
              {format(new Date(row.values.timestamp), "MM-dd-yyy hh:mm:ss b")}
            </span>
          )
        }
      },
      { Header: "Level", accessor: "level" },
      { Header: "Message", accessor: "message" },
      { Header: "User Id", accessor: "username" },
      { Header: "Hostname", accessor: "hostname" },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ row }) => {
          return (
            <div className="action-btn-list">
              {_.includes(row.values.message, 'sync orders') && (
                <Tooltip
                  align="top"
                  justify="start"
                  trigger={
                    <IconButton
                      onClick={() => restorePickedQty(row.values.timestamp)}
                      className="swing-icon"
                      aria-label="Restore picked quantities"
                    >
                      <FaUndo/>
                    </IconButton>}
                  triggerEvent="hover"
                >
                  Restore picked quantities
                </Tooltip>
              )}

            </div>
          );
        },
      },
      { Header: "Remark", accessor: "remark" },

    ],
    // eslint-disable-next-line
    []
  )
  return (
    <ReactTableContainer
      columns={columns}
      data={logs}
      hiddenColumns={hiddenColumns}
      sortBy={[{ id: "timestamp", desc: true }]}
    />
  
  )
}
