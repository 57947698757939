import React from 'react'
import Icon from '@leafygreen-ui/icon';
import { AuthContext } from 'contexts/AuthContext';
import { SettingsContext } from 'contexts/SettingsContext';
import IconButton from '@leafygreen-ui/icon-button';
import { FaSignOutAlt } from 'react-icons/fa';
import Tooltip from '@leafygreen-ui/tooltip';

export default function PageMainHeader({title, description}) {
  // console.log('* PageMainHeader init')
  // console.log('- title: ', title)
  // console.log('- user: ', user)
  // console.log('- description: ', description)
  const { user, logout } = React.useContext(AuthContext);
  const { settings } = React.useContext(SettingsContext);

  return (
    <div className="page-main-header">
        {(user?.username && settings?.company) && (
          <div className='user-wrapper'>
            <Icon glyph="Person" size="small" aria-label='User'/> 
            {user.username.toUpperCase()} : {settings.company}
            <Tooltip
              aria-label="Sign-out"
              trigger={
                <IconButton 
                  onClick={logout} 
                  aria-label="Sign-out"
                  className='ml-10'
                >
                  <FaSignOutAlt />
                </IconButton>
              }
            >
              Sign-out
            </Tooltip>
            
          </div>
        )}
      <div>
        <h1>{title}</h1>
        {description && (
          <p className="page-description">
            {description}
          </p>
        )}
      </div>
    </div>
  )
}

