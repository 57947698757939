import PageMainHeader from 'components/PageMainHeader';
import { AuthContext } from 'contexts/AuthContext';
import { SettingsContext } from 'contexts/SettingsContext';
import React, {useState, useContext} from 'react'
import { ToastContainer } from 'react-toastify';
import { Title } from 'utils/Title';
import Button from "@leafygreen-ui/button";
import Banner from "@leafygreen-ui/banner";
import _ from "lodash";
import socketIOClient from "socket.io-client";
import { GridLoader } from "react-spinners";
import { useEffect } from 'react';
import queryString from "query-string";
import { getS3presign } from "utils/awsS3";
// import { fetchRipEnvs } from 'utils/graphics';
import { fetchPrintedFilms, updatePrintedFilmStock } from 'utils/printedFilms';
import { FaPrint, FaQrcode } from 'react-icons/fa';
import { useCallback } from 'react';
import { useRef } from 'react';
import { ModalPreloader } from 'utils/Preloader';
import ConfirmationModal from '@leafygreen-ui/confirmation-modal';
import { filmLabelTemplate } from 'utils/filmLabelTemplate';

const title = "Queue Print by Graphic";
const socket = socketIOClient(process.env.REACT_APP_SOCKET_ENDPOINT);

const integratedAutomations = [
  { value: "KOTHARI", name: "Kothari Automation" },
  { value: "GTXCMD", name: "Brother GTX CMD Line" },
  { value: "CADLINK", name: "CADlink Digital Factory" },
];

export const QueuePrintGraphic = ({location}) => {
  const { settings, hostname, sessions } = useContext(SettingsContext);
  const { user } = useContext(AuthContext);
  const [msg, setMsg] = useState(null);
  const [loading, setLoading] = useState(false);
  // const [disableQueueBtn, setDisableQueueBtn] = useState(true);
  // const [disableCancelQueueBtn, setDisableCancelQueueBtn] = useState(true);
  const [preSignedUrl, setPreSignedUrl] = useState(null);
  const [selectedAutomation, setSelectedAutomation] = useState(null);
  const [selectedCadlinkQueue, setSelectedCadlinkQueue] = useState(null)
  // const [sessionMsg, setSessionMsg] = useState(null);
  const [loadedArtwork, setLoadedArtwork] = useState(false);
  const [ripEnvs, setRipEnvs] = useState(null)
  const [graphicFilename, setGraphicFilename] = useState(null);
  const [size, setSize] = useState({width: null, height: null});
  const [media, setMedia] = useState(null);
  const [ripEnv, setRipEnv] = useState(null);
  const [printedFilmInventoryLocation, setPrintedFilmInventoryLocation] = useState(null);
  const [printQty, setPrintQty] = useState(0);
  const [modalConfirmIsOpen, setModalConfirmIsOpen] = useState(false)

  const bannerVariant = useRef("info");
  let dataRef = useRef({
    graphicFilename: null,
    size: {width: null, height: null},
    media: null,
    ripEnv: null,
    printQty: 0
  })

  // onQueueResult
  const onQueueResult = useCallback(() => {
    //cadlink
    socket.on("on-queue-cadlink-graphic-result", async (result) => {
      console.log("* on-queue-cadlink-graphic-result init");
      console.log("- result: ", result);
      if (result) {
        if(result?.message) {
          setMsg(result.message);
          if(result.message.toLowerCase().includes('success')) {
            //update printed film stock
            // console.log(dataRef)
            const {graphicFilename, printQty} = dataRef.current
            await updatePrintedFilmStock({graphicFilename, qty: printQty})
            setModalConfirmIsOpen(true)

            
          }
        } 
        
      }
      setLoading(false);

    });
    // eslint-disable-next-line
  }, [])

  //onQueueResult
  useEffect(() => {
    onQueueResult();

    return () => {
      socket.removeAllListeners(["on-queue-cadlink-graphic-result"]);
    };
  }, [onQueueResult]);

  const onBarcodeScanCallback = useCallback(() => {
    socket.on("on-barcode-scanned", (data) => {
      console.log(`* onBarcodeScanCallback `);
      console.log('- data: ', data)
      if(data && data.includes('.')) {
        setGraphicFilename(data)
        dataRef.current = {...dataRef.current, graphicFilename: data}
      } 
    })
      
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    onBarcodeScanCallback();
    return () => {
      socket.removeAllListeners(["on-barcode-scanned"]);
    };
  }, [onBarcodeScanCallback]);

  useEffect(() => {
    console.log("* settings hook init");
    // console.log('- settings: ', settings)
    // console.log('- data: ', data)
    if (settings) {
      setSelectedAutomation(settings.defaultIntegratedAutomation || "KOTHARI");
      //media
      const defaultMedia = _.find(settings?.media, {isDefault: true})
      // console.log('- defaultMedia: ', defaultMedia);
      if(defaultMedia) {
        setMedia(defaultMedia)
        dataRef.current = {...dataRef.current, media: defaultMedia}
      }
    }
    // eslint-disable-next-line
  }, [settings]);

  useEffect(() => {
    console.log('* settings & selectedAutomation hook init')
    // console.log('- settings; ', settings)
    console.log('- selectedAutomation: ', selectedAutomation)
    console.log('- selectedCadlinkQueue: ', selectedCadlinkQueue)
    msg && setMsg(null)
    if(selectedAutomation && settings) {
      if(selectedAutomation && _.includes(selectedAutomation, 'CAD')) {
        if(!Boolean(settings?.cadLinkQueues?.length)) {
          setMsg("CADlink is selected for integrated automation but there's not found a CADlink queue")
        } else {
          if(_.isNil(selectedCadlinkQueue)) {
            setMsg('Required at least a CADlink queue method!')
          }
        }

        if(settings?.cadLinkPrintModes) {
          const envs = settings.cadLinkPrintModes.map(p => p.name)
          // console.log('- envs: ', envs)
          setRipEnvs(envs)
        }
      }
    }

  // eslint-disable-next-line
  }, [settings, selectedAutomation, hostname])

  useEffect(() => {
    // console.log(hostname)
    if(hostname && Boolean(settings?.cadLinkQueues?.length)) {
      console.log('- settings?.cadLinkQueues: ', settings?.cadLinkQueues);
      for (let index = 0; index < settings.cadLinkQueues.length; index++) {
        const q = settings.cadLinkQueues[index];
        for (let i = 0; i < q?.hostnames.length; i++) {
          const h = q?.hostnames[i];
          // console.log(index, i, h)
          if(hostname === h) {
            if(selectedAutomation !== 'CADLINK') {
              setSelectedAutomation('CADLINK');
            }

            setSelectedCadlinkQueue(q)
            dataRef.current = {...dataRef.current, selectedCadlinkQueue: q } 

            break;
          }
        }
      }
    }
  // eslint-disable-next-line
  }, [settings, sessions, hostname])

  //location hook
  useEffect(() => {
    console.log("* location hook init");
    // console.log('- location: ', location)
    let parsed = queryString.parse(location?.search);
    console.log("- parsed: ", parsed);
    // if(parsed && parsed?.printedFilms === '1') parsed.printedFilms = true
    const {graphicFilename, printQty} = parsed
    if(graphicFilename) setGraphicFilename(graphicFilename)
    if(printQty) setPrintQty(printQty);
    dataRef.current = {...dataRef.current, graphicFilename, printQty}


    // eslint-disable-next-line
  }, [location]);


  useEffect(() => {
    console.log('* graphicFilename hook init')
    if(graphicFilename && settings) {
      //fetchPrintedFilms
      fetchPrintedFilms({query: {graphicFilename}}, {pagination: false}).then(result => {
        // console.log(result)
        if(result?.docs) {
          let printedFilmInventoryLocation;
          printedFilmInventoryLocation = result.docs[0]?.areaCode;
          if(printedFilmInventoryLocation) {
            setPrintedFilmInventoryLocation(printedFilmInventoryLocation)
          }
        }
      })
      //artwork image
      let { REACT_APP_SOCKET_ENDPOINT } = process.env;
      if (/^true$/.test(settings?.useLocalRepository)) {
        console.log(
          "> in case of useLocalRepository: emit on-get-graphic-dimensions"
        );
        socket.emit("on-get-graphic-dimensions", graphicFilename);
        setPreSignedUrl(
          `${REACT_APP_SOCKET_ENDPOINT}/graphics/${graphicFilename}`
          // `https://localhost:8080/graphics/${graphicFilename}`
        );
      } else {
        const key = `${
          settings?.aws?.graphics || "graphics"
        }/${graphicFilename}`;
        getS3presign({ key }).then((response) => {
          // console.log("- getS3presign response:", response);
          setPreSignedUrl(() => response?.url);
        });
      }
    }
    // eslint-disable-next-line
  }, [graphicFilename, settings])

  const handleSelectedCadlinkQueue = (e) => {
    console.log('* handleSelectedCadlinkQueue init')
    console.log('e.target.value: ', e.target.value)
    msg && setMsg(null);

    const found = _.find(settings?.cadLinkQueues, {name: e.target.value})
    if(found) {
      setSelectedCadlinkQueue(found);
      dataRef.current = {...dataRef.current, selectedCadlinkQueue: found}
    }
  }

  const MediaName = () => {
    // console.log('* MediaName init')
    return (
      <select
        name="media"
        value={media?.code ? media.code : ""}
        id="media"
        onChange={onChangeHandler}
      >
        <option value="">-- Choose media --</option>
        {settings?.media &&
          settings?.media.map((m) => {
            return (
              <option value={m?.code} key={m.code}>
                {m.name} ({m.size.width}x{m.size.height} inch)
              </option>
            );
          })}
      </select>
    )
  }

  const onChangeHandler = (e) => {
    console.log("== onChangeHandler init");
    // console.log('- e.target: ', e.target);
    const {id, value} = e.target
    console.log('- id ', id );
    console.log('- value: ', value);
    msg && setMsg(null)

    if(id==="media") {
      const code = value
      // console.log('- code: ', code)
      const tempMedia = _.find(settings?.media, {code})
      // console.log(tempMedia)
      dataRef.current = {...dataRef.current, [id]: tempMedia}
      return
    }

    if(_.includes(id, "size")) {
      let key = id.split('.')[1]
      // console.log(key, value)
      let tempSize;
      setSize((current) => {
        tempSize = _.cloneDeep(current["size"])
        tempSize = {...tempSize, [key]: value}
        // console.log(tempSize)
        dataRef.current = {...dataRef.current, size: {...tempSize}}
        return { ...current, size: {...tempSize} }
      })
      return
    }

    if(id==='ripEnv'){
      setRipEnv(value)
      dataRef.current = {...dataRef.current, [id]: value}
      return
    }

    if(id==='printQty') {
      setPrintQty(value)
      dataRef.current = {...dataRef.current, [id]: value}
    }

    
  };

  const queuePrint = () => {
    console.log('* queuePrint init')
    //cadlink
    if(selectedAutomation.includes("CAD")) {
      console.log('- printQty: ', printQty, typeof printQty)
      if(parseInt(printQty) === 0) {
        alert('Print quantity must greater than 0.')
        return
      }
      msg && setMsg(null)
      setLoading(true)
      // dataRef.current = data
      socket.emit("on-queue-CADlink-Graphic", dataRef.current);
    }
  }

  const handlePrintLabel = async () => {
    console.log("* handlePrintLabel init");
    modalConfirmIsOpen && setModalConfirmIsOpen(false)
    const zpl = filmLabelTemplate({graphicFilename, printedFilmInventoryLocation, printQty})
    zpl && socket.emit("on-sku-print", zpl);
  };

  return (
      <>
      <Title title={title} />
      <PageMainHeader
        title={title}
        user={user}
        settings={settings}
        description={
          _.includes(selectedAutomation, "GTX")
            ? "Integrated Brother GTX Command line"
            : _.includes(selectedAutomation, "KOTHARI")
            ? "Integrated Kothari automation"
            : _.includes(selectedAutomation, "CADLINK")
            && "Integrated CADlink automation"
        }
      />
      <ToastContainer
        theme="colored"
        pauseOnHover={false}
        pauseOnFocusLoss={false}
      />
      <section className="primary queue-print">
        {/* {sessionMsg && (
          <Banner className="mb-10" variant="warning">
            {sessionMsg}
          </Banner>
        )} */}
        <div className="queue-default-values-wrapper card">
          <div className="value-wrapper">
            <label htmlFor="integratedAutomation">Integrated Automation:</label>
            <select
              value={selectedAutomation ? selectedAutomation : ""}
              onChange={(e) => setSelectedAutomation(e.target.value)}
              id="integratedAutomation"
              disabled={true}
            >
              {integratedAutomations.map((a, index) => {
                return (
                  <option value={a?.value} key={index}>
                    {a.name}
                  </option>
                );
              })}
            </select>
          </div>
          
          { (settings?.cadLinkQueues && (selectedAutomation && _.includes(selectedAutomation, 'CAD'))) 
            && (
              <div className="value-wrapper">
                <label htmlFor="selectedCadlinkQueue">CADlink Queue:</label>
                <select
                  value={selectedCadlinkQueue?.name ? selectedCadlinkQueue.name : ""}
                  onChange={(e) => handleSelectedCadlinkQueue(e)}
                  id="selectedCadlinkQueue"
                >
                  <option value="">== Select a queue ==</option>
                  {settings?.cadLinkQueues.map((c, index) => {
                    return (
                      <option value={c.name} key={index}>
                        {c.name}
                      </option>
                    );
                  })}
                </select>
              </div>

            ) 
          }

        </div>
        <div className="queue-print-info card mb-10" style={{gridTemplateColumns: "3fr 1fr", gridGap: "10px"}}>
          <div className="title-content-list">
            <div className="title-content">
                <span className="title">Graphic file name</span>
                <span className="d-block content">
                  {graphicFilename ? graphicFilename : ""}
                </span>
            </div>
            <div className="title-content">
              <span className="title">Printed film inventory location</span>
              <span className="d-block content">
                {printedFilmInventoryLocation ? printedFilmInventoryLocation : ""}
              </span>
            </div>  
            <div className="title-content">
              <span className="title">Size</span>
              <span className="d-block content">
                <input
                  type="text"
                  id="size.width"
                  defaultValue={
                    size &&
                    !_.isNil(size.width)
                      ? size.width
                      : ""
                  }
                  style={{ maxWidth: 60 }}
                  placeholder="width"
                  onChange={onChangeHandler}
                />
                <input
                  type="text"
                  id="size.height"
                  defaultValue={
                    size &&
                    !_.isNil(size.height)
                      ? size.height
                      : ""
                  }
                  style={{ maxWidth: 60, marginLeft: 10 }}
                  placeholder="height"
                  onChange={onChangeHandler}
                />
              </span>
            </div> 
            <div className="title-content">
              <span className="title">Media</span>
              <span className="d-block content">
                <MediaName />
              </span>
            </div>
            <div className="title-content">
              <span className="title">{(selectedAutomation && selectedAutomation.toLowerCase().includes('cadlink')) ? 'Print mode': 'Rip env'}</span>
              <div className="d-block content">
                <select
                  name="printMode"
                  value={ripEnv ? ripEnv : ""}
                  id="ripEnv"
                  onChange={onChangeHandler}
                >
                  <option value="">-- Choose {(selectedAutomation && selectedAutomation.toLowerCase().includes('cadlink')) ? 'Print mode': 'Rip env'} --</option>
                  {ripEnvs &&
                    ripEnvs.map((r, index) => {
                      return (
                        <option value={r} key={index}>
                          {r}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>  
            <div className="title-content">
              <span className="title">Quantity</span>
              <span className="d-block content">
                <input
                  type="text"
                  id="printQty"
                  value={printQty ? printQty : ""}
                  style={{ maxWidth: 60 }}
                  placeholder="print quantity"
                  onChange={onChangeHandler}
                />
              </span>
            </div>  
            
          </div>
          <div className="queue-print-img graphic">
            {preSignedUrl && (
              <>
                <img
                  src={`${preSignedUrl}`}
                  className="responsive-img graphic-file"
                  alt={graphicFilename ? graphicFilename : "image" }
                  key={preSignedUrl}
                  onError={(e) =>
                    (e.target.src = `${process.env.REACT_APP_S3_NOIMG}/300.png`)
                  }
                  onLoad={() => {
                    setLoadedArtwork(true);
                  }}
                  style={{ display: !loadedArtwork ? "none" : "block" }}
                />
                {!loadedArtwork && <GridLoader color={"#09804C"} />}
              </>
            )}
          </div>
        </div>
        {msg && (
          <Banner variant={bannerVariant.current} className="mb-10">
            {msg}
          </Banner>
        )}
        <div className="d-flex justify-content-space">
          <Button
            onClick={handlePrintLabel}
            leftGlyph={<FaQrcode />}
            size="large"
          >
            Print Label
          </Button>

          <div className='d-flex justify-content-end'>
            <Button
              className="ml-10"
              variant="primary"
              onClick={queuePrint}
              // disabled={disableQueueBtn}
              leftGlyph={<FaPrint />}
              size="large"
            >
              Queue Print
            </Button>
          </div>
        </div>

        <ConfirmationModal
          open={modalConfirmIsOpen}
          onConfirm={handlePrintLabel}
          onCancel={() => setModalConfirmIsOpen(false)}
          buttonText='Confirm'
          title="Confirm printing labels"
        >
          Click confirm to print labels or cancel to close
        </ConfirmationModal>

        <ModalPreloader modalPreloaderIsOpen={loading} />

        {/* <pre>dataRef.current: {JSON.stringify(dataRef.current, null, 2)}</pre> */}

      </section>
    </>      
  )
}
