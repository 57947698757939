import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import { Title } from 'utils/Title'
import PageMainHeader from 'components/PageMainHeader'
import { AuthContext } from "contexts/AuthContext"
import { SettingsContext } from 'contexts/SettingsContext'
import Banner from '@leafygreen-ui/banner'
import PageMenuHeader from 'components/PageMenuHeader'
import _ from "lodash";
import { jsonToCSV } from "react-papaparse";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { axiosInstance as axios } from 'configs/axiosConfig'
import { errorHandler } from 'utils/errorHandler'
import IconButton from '@leafygreen-ui/icon-button'
import {Table , TableHeader, Row, Cell} from "@leafygreen-ui/table"
import MenuHeaderTemplate from 'components/MenuHeaderTemplate'
import { FaCheck, FaEdit, FaSnowplow } from 'react-icons/fa'
import ModalBin from 'components/modals/ModalBin'
import { ModalPreloader } from 'utils/Preloader'
import Button from "@leafygreen-ui/button"
import ScrollTop from 'utils/ScrollTop'
import Badge from '@leafygreen-ui/badge';

const title="Bins"
const initialBin = {
  binCode: "",
  description: "",
  isActive: false,
  priority: "",
  currentOrderNumber: "",
};


const fetchBins = async () => {
  return new Promise(async (resolve, reject) => {
    const url = `/bins`;
    try {
      const result = await axios.get(url);
      // console.log("bins result", result.data);
      resolve(result.data);
    } catch (error) {
      console.log("get bins error", error);
      let retval = errorHandler(error);
      reject(retval);
    }
  });
};

export default function Bins() {
  const { settings } = React.useContext(SettingsContext);
  const { user } = React.useContext(AuthContext);
  const [loading, setLoading] = React.useState(false);
  const [mode, setMode] = React.useState("create");
  const [msg, setMsg] = React.useState(null);
  const [selectedBin, setSelectedBin] = React.useState(initialBin);
  const binCodeInputRef = React.useRef(null);
  const [modalIsOpen, setModalIsOpen] = React.useState(false)
  const queryClient = useQueryClient();
  const [binInfo, setBinInfo] = useState({totalBins: 0, activeBins: 0, availableBins: 0})

  const { data: bins, isLoading, isError, error } = useQuery("bins", fetchBins, {
    refetchOnWindowFocus: false, staleTime: 1000 * 60 * 5
  })

  useEffect(() => {
    console.log('-bins hook init');
    if(bins) {
      const availableBinsFiltered = bins.filter(bin => !bin.isActive);
      setBinInfo({
        totalBins: bins.length,
        availableBins: availableBinsFiltered.length 
      })

    }
  }, [bins])

  const showModal = (mode) => {
    // console.log("showModal mode", mode);
    setMode(mode);
    if (msg) setMsg(null);
    if (mode === "create") setSelectedBin(initialBin);
    setModalIsOpen(true)
  };

  const mutateBin = useMutation(async (payload) => {
    console.log("* mutateBin init");
    setLoading(true);
    const url = `/bins/update`;
    return await axios.post(url, payload)
  }, {
    onError: (error) => {
      let retval = errorHandler(error)
      setLoading(false);
      setMsg(retval);
    },
    onSuccess: (response) => {
      console.log("- onSuccess response: ", response)
      setLoading(false);
      queryClient.invalidateQueries("bins");
    }
  })

  const submitHandler = () => {
    console.log("* submitHandler init");
    console.log("- mode", mode);
    console.log("- selectedBin", selectedBin);
    modalIsOpen && setModalIsOpen(false)

    let condition;
    if (mode === "edit" && _.has(selectedBin, "_id")) {
      condition = { _id: selectedBin._id };
    } else {
      condition = { binCode: selectedBin.binCode };
    }

    if (selectedBin?.priority) {
      selectedBin.priority = parseInt(selectedBin.priority)
    }

    if (!_.isNil(selectedBin.binCode) && !_.isEmpty(selectedBin.binCode.trim())) {
      mutateBin.mutate({condition, update: selectedBin})
    } else {
      alert("Bin code is required");
      return
    }
  };

  const deleteBin = useMutation(async () => {
    console.log("== deleteBin init ==");
    setLoading(true);
    const url = `/bins/delete`;
    return await axios.delete(url, { data: { _id: selectedBin._id } })
  }, {
    onError: (error) => {
      let retval = errorHandler(error)
      setMsg(retval);
      setLoading(false);
    },
    onSuccess: (response) => {
      console.log("onSuccess response: ", response)
      setLoading(false);
      toast.info(response?.data?.message, {
        position: "bottom-right"
      })

      queryClient.invalidateQueries("bins");
    }
  })

  const handleDelete = () => {
    console.log("== handleDelete init ==");
    console.log("- delete selectedBin._id", selectedBin._id);
    modalIsOpen && setModalIsOpen(false)
    deleteBin.mutate()
  }

  const exportBins = async () => {
    setLoading(true);
    // console.log(selectedExportMode);
    let clonedBins = _.cloneDeep(bins);
    const result = jsonToCSV({
      fields: ["binCode", "description", "loadCapacity", "priority", "isActive"],
      data: clonedBins
    });
    // console.log(result);

    const element = document.createElement("a");
    const file = new Blob([result], {
      type: "text/csv",
    });
    element.href = URL.createObjectURL(file);
    element.download = `bins_${moment().format("YYYYMMDDHHmm")}.csv`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    setLoading(false);
  };

  const importBins = (parsedData) => {
    setLoading(true);
    console.log("parsedData", parsedData);
    let tempBins = parsedData.map((bin) => {
      let isActive = bin.data.isActive;

      /^false/i.test(isActive) ? (isActive = false) : (isActive = true);
      return { ...bin.data, isActive };
    });

    console.log("tempBins", tempBins);
    let msg = `Total number of bins: ${tempBins.length}`;
    setMsg(msg);

    let i = 0;
    let len = tempBins.length - 1;

    async function loop() {
      try {
        let bin = tempBins[i];
        let binCode = bin.binCode;
        console.log(i, bin);

        const url = `/bins/update`;

        const result = await axios.post(url, {
          condition: { binCode },
          update: { ...bin },
        });
        setMsg(`${i + 1}/${tempBins.length}: ${result.data.binCode} added`);

        i += 1;

        if (i <= len && _.has(bin, "binCode")) {
          loop();
        } else {
          setMsg("* Completed import bins, click refresh to load a page");
          setLoading(false);
          return;
        }
      } catch (error) {
        console.log("importBins error", error);
        setMsg(error.response.data.toString());
      }
    }

    if (i <= len) {
      loop();
    }
  };

  const editBin = (bin) => {
    setSelectedBin(bin);
    setMode("edit");
    setModalIsOpen(true)
  };

  const resetBins = useMutation(async () => {
    console.log('* resetBins init')
    setLoading(true);
    const url = `/bins/reset`;
    return await axios.get(url);
  }, {
    onError: (error) => {
      let retval = errorHandler(error)
      setMsg(retval);
      setLoading(false);
    },
    onSuccess: (response) => {
      console.log("onSuccess response: ", response)
      setLoading(false);
      toast.info(response?.data?.message, {
        position: "bottom-right"
      })
      queryClient.invalidateQueries("bins");
    }
  });

  const batchDelete = (parsedData) => {
    console.log('* batchDelete init')
    try {
      setLoading(true);
      let temp = parsedData.map((item) => item.data);
      let msg = `Total number of bins for deleting: ${temp.length}`;
      setMsg(msg);
      let i = 0;
      let len = temp.length - 1;

      async function loop() {
        let { binCode } = temp[i]
        try {
          await axios.delete(`/bins/delete`, { data: { binCode } })
          // console.log("deleteItemBySku result", result);
          setMsg(`${i + 1}/${temp.length}: ${binCode} deleted.`);
          i += 1;
        } catch (error) {
          console.log("batchDelete error", error);
          const retval = errorHandler(error)
          setMsg(retval);
          return;
        }

        if (i <= len && _.has(temp[i], "binCode")) {
          loop();
        } else {
          setMsg("* Completed batch deletion of the bins, click refresh to load a page.");
          setLoading(false);
          return;
        }
      }

      if (i <= len) {
        loop();
      }
    } catch (error) {
      const retval = errorHandler(error)
      console.log("- batchDelete error: ", retval);
      setMsg(retval);

    }
  }

  return <>
      <Title title={title} />
      <PageMainHeader 
        title={title} 
        user={user} 
        settings={settings} 
      />
      <ToastContainer  theme='dark'/>
      <section className="primary">
        { isLoading ? (
          <Skeleton count={20} height={50} circle={true} />
        ) : isError ? (
          <Banner variant='danger'>{error?.message ? error.message : error}</Banner>
        ) : (
          null
        )}
        <PageMenuHeader>
          <MenuHeaderTemplate 
            showModal={showModal}
            importHandler={ importBins }
            exportHandler={ exportBins}
            batchDeleteHandler={batchDelete}
            handleDelete={handleDelete}
            selectedExportMode="all"
          />
          <Button
            variant="primary"
            className="swing-icon"
            leftGlyph={<FaSnowplow />}

            onClick={() => resetBins.mutate()}
          >
            Reset
          </Button>
        </PageMenuHeader>
        {
          msg && <Banner className='mb-10'>{msg}</Banner>
        }
        {bins && Boolean(bins.length) ? (
          <>
            <Banner variant="info" className='mb-10'>
              <span className='inline-block mr-10'>Available Bins: <Badge variant="red">{binInfo.availableBins}</Badge></span>  
              <span className='inline-block mr-10'>Total Bins: <Badge variant="blue">{binInfo.totalBins}</Badge></span>
              <span className='inline-block mr-10'>Occupied(Active) Bins: <Badge variant="blue">{binInfo.totalBins - binInfo.availableBins}</Badge></span>  
            </Banner>

            <Table
              data={bins}
              columns={[
                <TableHeader label="Bin Code" sortBy={(datum) => datum.binCode}/>,
                <TableHeader label="Load Capacity" sortBy={(datum) => datum.loadCapacity}/>,
                <TableHeader label="Priority" sortBy={(datum) => datum.priority}/>,
                <TableHeader label="Description" sortBy={(datum) => datum.description}/>,
                <TableHeader label="Active" sortBy={(datum) => datum.isActive}/>,
                <TableHeader label="Action" />,
              ]}
            >
              {
                ({datum: bin}) => (
                  <Row key={bin._id}>
                    <Cell>{bin.binCode}</Cell>
                    <Cell>{bin.loadCapacity}</Cell>
                    <Cell>{bin?.priority ? bin.priority : ""}</Cell>
                    <Cell>{bin.description}</Cell>
                    <Cell>{bin.isActive ? <FaCheck /> : ""}</Cell>
                    <Cell>{
                      <IconButton
                        aria-label="edit a bin"
                        onClick={() => editBin(bin)}
                        className='swing-icon'
                      >
                        <FaEdit />
                      </IconButton>}
                    </Cell>
                  </Row>
                )
              }
            </Table>
          </>
          
        ) : (
          <Banner>Bins not found.</Banner>
        )}
        <ModalBin 
          modalIsOpen={modalIsOpen}
          setModalIsOpen={setModalIsOpen}
          title={"Bin"}
          mode={mode}
          selectedBin={selectedBin}
          setSelectedBin={setSelectedBin}
          submitHandler={submitHandler}
          handleDelete={handleDelete}
          binCodeInputRef={binCodeInputRef}
          user={user}
        />
        <ScrollTop />
      </section>
      <ModalPreloader modalPreloaderIsOpen={loading} />
    </>
}
