import Axios from "axios";
import { updateOrder } from "./orders";
import _ from 'lodash';

export const updateBin = async (data) => {
  return new Promise((resolve, reject) => {
    // console.log("data", data);
    const url = `${process.env.REACT_APP_API_ENDPOINT}/bins/update`;
    Axios.post(url, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => reject(error));
  });
};

export const assignBin = async (orderId, volumn) => {
  return new Promise(async (resolve, reject) => {
    let binCode;
    await Axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/bins/find-empty-bin`,
      { volumn }
    )
      .then(async (res) => {
        console.log("find-empty-bin", res.data);
        if(!_.isNil(res.data)){
          binCode = res.data.binCode;
        }
      })
    
    if(binCode) {
      updateOrder({
        condition: { orderId },
        update: { $set: { bin: binCode } },
      })
      .then(async () => {
        return await updateBin({
          condition: { binCode },
          update: { $set: { isActive: true } },
        })
      })
      .then((res) => {
        console.log("upate bin res", res);
        resolve(true);
      })
      .catch((error) => {
        console.log("assign bin error", error);
        reject(error);
      });
    } else {
      reject(false)
    } 


  });
};
