import React from 'react'
import { FaCaretSquareDown, FaCaretSquareUp } from 'react-icons/fa'
import { useTable, useSortBy } from 'react-table'

export default function ReactTableContainer({ columns, data, sortBy, hiddenColumns }) {
  // console.log('* ReactTableContainer init')
  // console.log('- hiddenColumns: ', hiddenColumns)
  // console.log('- sortBy: ', sortBy)
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
    initialState: {
      hiddenColumns,
      sortBy
    },
  },
    useSortBy,
  )

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? <FaCaretSquareUp color="#adadad" size="1.2em" /> : <FaCaretSquareDown color="#adadad" size="1.2em" />) : ""
  }

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                <span className="d-flex justify-content-space align-items-center">
                  {column.render("Header")}
                  {generateSortingIndicator(column)}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>

      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
