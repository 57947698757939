import React from 'react'
import ReactTableContainer from 'utils/ReactTableContainer'
import { Link } from 'react-router-dom'
import { FaEdit, FaPallet } from 'react-icons/fa'
import IconButton from '@leafygreen-ui/icon-button'
import Tooltip from '@leafygreen-ui/tooltip'

export default function ComponentsList({ items, showModal, settings, user }) {
  // console.log('* ComponentsList init')
  // console.log('- items: ', items)
  // console.log('- user: ', user)
  let hiddenColumns = ["_id"]
  const columns = React.useMemo(
    () => [
      { Header: "id", accessor: "_id" },
      { Header: "SKU", accessor: "sku" },
      { Header: "Category", accessor: "category" },
      { Header: "Inventory Area", accessor: "inventoryArea" },
      { Header: "Stock", accessor: "stock" },
      { Header: "Collection Priority", accessor: "priority" },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ row }) => {
          return (
            <div className="action-btn-list">
              {user?.role==="admin" &&
                <Tooltip
                  align="left"
                  justify="start"
                  triggerEvent="hover"
                  aria-labelledby="Edit"
                  darkMode={true}
                  className='lg-tooltip-container'
                  trigger={  
                    <IconButton 
                      className="swing-icon"
                      aria-labelledby="Edit"
                      onClick={() => showModal("update", row.original)}
                    >
                      <FaEdit />
                    </IconButton>
                  }
                >
                  Edit
                </Tooltip>
              }
              {
                settings?.useInventoryModule && (
                  <Tooltip
                    align="top"
                    justify="start"
                    triggerEvent="hover"
                    aria-labelledby="View inventory transactions"
                    darkMode={true}
                    className='lg-tooltip-container'
                    trigger={  
                      <IconButton 
                        className="swing-icon"
                        aria-labelledby="View inventory transactions icon"
                      >
                        <Link
                          to={{
                            pathname: "/inventory-transactions",
                            search: `?sku=${row?.values?.sku}`,
                          }}
                        >
                          <FaPallet color="#89979B"/>
                        </Link>
                      </IconButton>
                    }
                  >
                    View inventory transaction
                  </Tooltip>
                )  
              }
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line
    []
  )
  return (
    <ReactTableContainer
      columns={columns}
      data={items}
      hiddenColumns={hiddenColumns}
      sortBy={[{ id: "sku" }]}
    />
  
  )
}
