import { axiosInstance as axios} from "configs/axiosConfig";
import { errorHandler } from "./errorHandler";

export const fetchInventoryTransactions = (params) => {
  console.log("* fetchInventoryTransactions init")
  // console.log("- params: ", params);
  return new Promise((resovle, reject) => {
    const url = `/inventory-transaction`;
    axios.get(url, { params })
      .then((result) => {
        // console.log("getInventoryTransactions result", result);
        resovle(result.data);
      })
      .catch((error) => {
        console.log("getInventoryTransactions error", error);
        reject(error)
      });
  });
}

export const addTransaction = (payload) => {
  // console.log('* addTransaction in utils init')
  return new Promise(async (resolve, reject) => {
    try {
      const result = await axios.post(`/inventory-transaction/add`, payload);
      // console.log('-result: ', result)
      resolve(result.data);
      
    } catch (error) {
      let retval = errorHandler(error);
      console.log('-retval: ', retval)
      reject(retval);
    }
  })
}