import React from 'react'
import { FaEdit } from 'react-icons/fa';
import ReactTableContainer from 'utils/ReactTableContainer';
import IconButton from '@leafygreen-ui/icon-button'
import Tooltip from '@leafygreen-ui/tooltip'
import moment from 'moment'

export default function UsersList({users, showModal}) {
  const hiddenColumns = ["_id"]
  const columns = React.useMemo(
    () => [
      { Header: "id", accessor: "_id" },
      { Header: "User Id", accessor: "username" },
      { Header: "Role", accessor: "role" },
      { 
        Header: "Created At", 
        accessor: "createdAt",
        Cell: ({ row }) => {
          return (
            <span>{moment(row.values.createdAt).local().format("MM-DD-YYYY")}</span>
          );
        },
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ row }) => {
          return (
            <Tooltip
              align="right"
              justify="start"
              darkMode={true}
              className='lg-tooltip-container'
              trigger={
                <IconButton
                  onClick={() =>
                    showModal({ selectedUser: row.original, mode: "update" })
                  }
                  className="swing-icon"
                  aria-label="Update a user"
                >
                  <FaEdit/>
                </IconButton>}
              triggerEvent="hover"
            >
              Edit this user
            </Tooltip>
          );
        },
      },
    ],
    // eslint-disable-next-line
    []
  )
  return (
    <ReactTableContainer
      columns={columns}
      data={users}
      hiddenColumns={hiddenColumns}
      sortBy={[{ id: "role" }, {id: 'username'}]}
    />
  
  )
}
