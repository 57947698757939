import React from "react";
import { BrowserRouter } from "react-router-dom";
import { IntlProvider } from 'react-intl';
import Layout from './layout/Layout';
import messages from './messages';
import './styles/app.scss';
import AuthContextProvider from "contexts/AuthContext";
import PrintfloNotification from "components/PrintfloNotification";

function App() {
  const [locale, setLocale] = React.useState('en');
  
  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <PrintfloNotification />
      <BrowserRouter>
        <AuthContextProvider>
          <Layout setLocale={setLocale} />
        </AuthContextProvider>
      </BrowserRouter>
    </IntlProvider>
  );
}

export default App;
