import React from 'react'
import { ToastContainer } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import { Title } from 'utils/Title'
import PageMainHeader from 'components/PageMainHeader'
import { AuthContext } from "contexts/AuthContext"
import { SettingsContext } from 'contexts/SettingsContext'
import Banner from '@leafygreen-ui/banner'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { axiosInstance as axios } from 'configs/axiosConfig'
import {format, differenceInCalendarDays} from 'date-fns'
import SessionEditModal from './SessionEditModal'
import {Table, TableHeader, Row, Cell} from "@leafygreen-ui/table"
import IconButton from "@leafygreen-ui/icon-button"
import { FaEdit } from 'react-icons/fa'

const title="Sessions"

const getSessions = () => {
  return new Promise((resolve, reject) => {
    try {
      const url = `/sessions`;
      axios.get(url).then(response => {
        if (response?.data) {
          resolve(response.data);
        }
      })
    } catch (error) {
      reject(error);
    }
  })
}

export default function Sessions() {
  const { settings } = React.useContext(SettingsContext);
  const { user } = React.useContext(AuthContext);
  const [modalSessionIsOpen, setModalSessionIsOpen] = React.useState(false)
  const [session, setSession] = React.useState()

  const queryClient = useQueryClient()

  const { data: sessions, isLoading, isError, error } = useQuery(['sessions'],
    async () => { return await getSessions() },
  {
    refetchOnWindowFocus: false  
  })
  // console.log('- sessions: ', sessions)

  const updateSession = useMutation(async () => {
    console.log('* mutateSession init')
    console.log('- session: ', session)
    let { _id, dateOfExpiry, dateOfIssue, alias, usedFor, lastActivityAt } = session
    
    dateOfExpiry = new Date(dateOfExpiry)
    dateOfIssue = new Date(dateOfIssue)
    lastActivityAt = new Date()

    const url = '/sessions'
    return await axios.post(url, { _id, dateOfExpiry, dateOfIssue, alias, usedFor, lastActivityAt })
  }, {
    onSuccess: response => {
      console.log('- onSuccess response: ', response)
      queryClient.invalidateQueries('sessions')
    }
  })

   const deleteSession = useMutation(async (_id) => {
    console.log('* deleteSession init')

    const url = `/sessions/delete/${_id}`;
    return await axios.delete(url)
  }, {
    onSuccess: response => {
      console.log('- onSuccess response: ', response)
      queryClient.invalidateQueries('sessions')
    }
  })
    

  const editSession = (row) => {
    console.log('* editSession init')
    console.log('- row: ', row)
    setModalSessionIsOpen(true)
    setSession(row)
  }

  const onChangeHandler = (e) => {
    console.log('* onChangeHandler init')
    console.log('- e.target.id: ', e.target.id)
    console.log('- e.target.value: ', e.target.value)
    setSession(current => {
      console.log('-current: ', current)
      return {...current, [e.target.id]: e.target.value}
    })
  }

  const submitHanlder = (e) => {
    console.log('* submitHanlder init')
    e.preventDefault()
    setModalSessionIsOpen(false)
    updateSession.mutate()
  }

  const deleteHandler = (_id) => {
    console.log('* deleteHanlder init')
    console.log('- _id: ', _id)
    setModalSessionIsOpen(false)
    var confirm = window.confirm('Confirm to delete a session.')
    console.log('- confirm: ', confirm)
    if(confirm) deleteSession.mutate(_id)
  }

  return (
    <>
      <Title title={title} />
      <PageMainHeader 
        title={title} 
        user={user} 
        settings={settings} 
      />
        <ToastContainer theme='dark'/>
        <section className="primary">
          {isLoading ? (
            <Skeleton count={20} height={50} circle={true} />
          ) : isError ? (
            <Banner variant='danger'>{error?.message ? error.message : error}</Banner>
          ) : (
            <Table 
              data={sessions} 
              columns={
                [
                  <TableHeader label="Host Name" sortBy={(datum) => (datum.hostname)}/>,
                  <TableHeader label="IP Address" />,
                  <TableHeader label="Machine ID" />,
                  <TableHeader label="Used For" sortBy={(datum) => (datum.usedFor)}/>,
                  <TableHeader label="Date of Issue" sortBy={(datum) => (datum.dateOfIssue)}/>,
                  <TableHeader label="Date of Expiry" sortBy={(datum) => (datum.dateOfExpiry)}/>,
                  <TableHeader label="Days remaining" sortBy={(datum) => (differenceInCalendarDays(new Date(datum.dateOfExpiry), new Date()))}/>,
                  <TableHeader label="Last Activity At" sortBy={(datum) => (datum.lastActivityAt)}/>,
                  <TableHeader label="Alias" sortBy={(datum) => (datum.alias)}/>,
                  <TableHeader label="Action" />
                ]
              }
            >
              {({datum}) => (
                <Row key={datum._id}>
                  <Cell>{datum?.hostname ? datum.hostname : ""}</Cell>,
                  <Cell>{datum?.ip ? datum.ip : ""}</Cell>,
                  <Cell>{datum?.machineId ? datum.machineId : ""}</Cell>,
                  <Cell>{datum?.usedFor ? datum.usedFor : ""}</Cell>,
                  <Cell>{datum?.dateOfIssue ?  format(new Date(datum.dateOfIssue), 'MM-dd-yyyy') : ""}</Cell>,
                  <Cell>{datum?.dateOfExpiry ?  format(new Date(datum.dateOfExpiry), 'MM-dd-yyyy') : ""}</Cell>,
                  <Cell>
                    {datum.dateOfExpiry ? `${differenceInCalendarDays(new Date(datum.dateOfExpiry), new Date())} days` : ""}
                  </Cell>,
                  <Cell>
                    {datum.lastActivityAt ? format(new Date(datum.lastActivityAt), 'MM-dd-yyyy') : ""}
                  </Cell>,
                    
                  <Cell>{datum?.alias ? datum.alias : ""}</Cell>,
                  <Cell>
                    <IconButton
                      className="swing-icon" 
                      onClick={() => editSession({ ...datum })}
                      aria-label="Edit a session"
                    >
                      <FaEdit />  
                    </IconButton>
                  </Cell>,
                  
                </Row>
              )}
            </Table>
          )}
        </section>
        <SessionEditModal
          modalIsOpen={modalSessionIsOpen}
          setModalIsOpen={setModalSessionIsOpen}
          session={session}
          setSession={setSession}
          onChangeHandler={onChangeHandler}
          submitHanlder={submitHanlder}
          deleteHandler={deleteHandler}
          mode="edit"
          title="Session"
        />
    </>
    )
}
