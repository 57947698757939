import FileImportExport from "components/FileImportExport";
import React from "react";
import { FaBolt, FaPlus } from "react-icons/fa";
import Button from "@leafygreen-ui/button";
import BatchDelete from "components/BatchDelete";

const GraphicsHeader = ({showModal, importGraphics, selectedExportMode, setSelectedExportMode, exportGraphics, batchDeleteHandler}) => {
  return (
    <div className='btn-menu-list_container'>
      <div className='btn-menu-list'>
        <Button
          variant="primary"
          className="swing-icon"
          title="Reload"
          leftGlyph={<FaBolt />}
          onClick={() => {
            window.location.reload();
          }}
        >
          Refresh
        </Button>

        <Button
          variant="primary"
          className="swing-icon"
          leftGlyph={<FaPlus />}

          onClick={() => {
            showModal("create");
          }}
        >
          Add
        </Button>

        <FileImportExport
          importHandler={importGraphics}
          exportHandler={exportGraphics}
          selectedExportMode={selectedExportMode}
          setSelectedExportMode={setSelectedExportMode}
        />

        <BatchDelete batchDeleteHandler={batchDeleteHandler} />

      </div>

    </div>
    
  );
};

export default GraphicsHeader;


/*
<div className="items-header card">
        <div className="grid-wrapper card-content d-flex items-menu-wrapper">
          <div className="grid-wrapper d-flex valign-wrapper">
            <div>
              <button
                className="waves-effect waves-light btn btn-small btn-float blue-btn white-text"
                title="Reload"
                onClick={() => {
                  window.location.reload();
                }}
              >
                <i className="material-icons">refresh</i>
              </button>
            </div>
            <div>
              <button
                className="waves-effect waves-light btn btn-small btn-flat blue-btn white-text"
                onClick={() => {
                  showModal("create");
                }}
              >
                Add
              </button>
            </div>
            <div>
              <CSVReader
                config={{ header: true, skipEmptyLines: true }}
                onError={handleOnError}
                onFileLoad={handleOnFileLoad}
                noProgressBar
                ref={buttonRef}
              >
                {({ file }) => (
                  <button
                    className="waves-effect waves-light btn btn-small btn-flat blue-btn white-text"
                    onClick={handleOpenDialog}
                  >
                    Import
                  </button>
                )}
              </CSVReader>
            </div>
            <div className="d-flex export-items-wrapper">
              <div className="input-field">
                <select
                  className="browser-default"
                  value={selectedExportMode}
                  onChange={(e) => setSelectedExportMode(e.target.value)}
                >
                  <option value="retrieved">Retrieved</option>
                  <option value="all">All</option>
                </select>
              </div>
              <button
                className="waves-effect waves-light btn btn-small btn-flat blue-btn white-text"
                onClick={exportGraphics}
              >
                Export
              </button>
            </div>
          </div>
          <div className="input-field search-text valign-wrapper">
            <input
              type="text"
              name="searchSKU"
              id="searchSKU"
              value={searchSKU}
              onChange={onChangeHandler}
              onKeyDown={onKeyDownHandler}
              className="white-text"
              placeholder="Search by SKU"
            />
            <button
              className="btn-small btn-flat blue-btn white-text"
              onClick={handleSearch}
            >
              <i className="material-icons">search</i>
            </button>
          </div>
        </div>
      </div>

*/