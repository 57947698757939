import { axiosInstance as axios } from "configs/axiosConfig";
import { errorHandler } from "./errorHandler";

export const getGraphics = (data) => {
  return new Promise((resolve, reject) => {
    try {
      const url = `/graphics`;
      axios.post(url, data).then((res) => {
        resolve(res.data);
      });
    } catch (error) {
      console.log("getGraphics error:", error);
      const retval = errorHandler(error);
      reject(retval);
    }
  });
};

export const submitGraphic = (data) => {
  return new Promise((resolve, reject) => {
    const url = `/graphics/update`;
    axios
      .post(url, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        console.log("- submitGraphic error: ", error);
        reject(error);
      });
  });
};

export const deleteGraphic = (id) => {
  return new Promise((resolve, reject) => {
    try {
      const url = `/graphics/delete/${id}`;
      axios.delete(url).then((res) => {
        resolve(res.data);
      });
    } catch (error) {
      const retval = errorHandler(error);
      reject(retval);
    }
  });
};

export const deleteGraphicByCondition = ({ condition }) => {
  return new Promise((resolve, reject) => {
    try {
      const url = `/graphics/delete`;
      axios.post(url, { condition }).then((res) => {
        resolve(res.data);
      });
    } catch (error) {
      const retval = errorHandler(error);
      reject(retval);
    }
  });
};

export const searchGraphics = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `/graphics/search`;
      const results = await axios.post(url, payload);
      resolve(results.data);
    } catch (error) {
      const retval = errorHandler(error);
      reject(retval);
    }
  });
};

export const fetchRipEnvs = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const storedRipEnvs = window.localStorage.getItem("_ripenvs");
      console.log("- storedRipEnvs: ", storedRipEnvs);
      if (storedRipEnvs) {
        resolve(JSON.parse(storedRipEnvs));
        return;
      } else {
        const url = `/graphics/fetch-ripenvs`;
        const results = await axios.get(url);
        const ripEnvs = results.data;
        window.localStorage.setItem("_ripenvs", JSON.stringify(ripEnvs));
        resolve(ripEnvs);
      }
    } catch (error) {
      const retval = errorHandler(error);
      reject(retval);
    }
  });
};
