import Axios from 'axios'
// const authJWT = JSON.parse(window.localStorage.getItem("_auth"))?.jwt
// const authJWT = window.localStorage.getItem("_auth")
// console.log('- authJWT: ', authJWT)
export const axiosInstance = Axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  auth: {
    username: JSON.parse(window.localStorage.getItem("_auth"))?.username,
    // password: authJWT && Buffer.from(JSON.parse(authJWT)?.jwt, 'base64')
  },
  headers: {
    "x-hostname" : window.localStorage.getItem("_hostname") ? window.localStorage.getItem("_hostname") : undefined
  }
});


 