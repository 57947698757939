import React, { useContext, useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { Title } from "utils/Title";
import Msg from "components/Msg";
import PageMainHeader from "components/PageMainHeader";
import { AuthContext } from "contexts/AuthContext";
import { SettingsContext } from "contexts/SettingsContext";
import _ from "lodash";
import socketIOClinet from "socket.io-client";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useQuery, useQueryClient } from "react-query";
import { fetchListShipments } from "utils/shipstation";
import { startOfYesterday, endOfDay } from "date-fns";
import Pagination from "utils/Pagination";
import ShippedOrderList from "./ShippedOrderList";
import Banner from "@leafygreen-ui/banner";
import PageMenuHeader from "components/PageMenuHeader";
import DateRange from "components/DateRange";
import { ModalPreloader } from "utils/Preloader";
import Search from "components/Search";
import { Option, Select } from "@leafygreen-ui/select";
import ScrollTop from "utils/ScrollTop";

const title = "Shipped Orders";
const socket = socketIOClinet(process.env.REACT_APP_SOCKET_ENDPOINT);

const sDate = startOfYesterday(new Date());
const eDate = endOfDay(new Date());
const pageSize = 50;

export default function ShippedOrders() {
  const { settings } = useContext(SettingsContext);
  const { user } = React.useContext(AuthContext);

  const [startDate, setStartDate] = useState(sDate);
  const [endDate, setEndDate] = useState(eDate);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [selectedSearchOption, setSelectedSearchOption] =
    useState("orderNumber");
  const queryClient = useQueryClient();

  const {
    data: listShipments,
    isLoading,
    isFetching,
    isError,
    error,
    refetch,
  } = useQuery(
    ["listShipments", { page, startDate, endDate }],
    () => fetchListShipments({ page, startDate, endDate }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 1,
    }
  );

  useEffect(() => {
    console.log("* listShipments init");
    if (listShipments?.pages > page) {
      queryClient.prefetchQuery(
        ["listShipments", { page: page + 1, startDate, endDate }],
        () => fetchListShipments({ page: page + 1, startDate, endDate }),
        {
          keepPreviousData: true,
          refetchOnWindowFocus: false,
          staleTime: 1000 * 60 * 1,
        }
      );
    }
    // eslint-disable-next-line
  }, [page, listShipments, queryClient]);

  const onBarcodeScanned = async () => {
    socket.on("on-barcode-scanned", async (data) => {
      console.log("on-barcode-scanned", data);
      let dataArr = [];
      let delimiter = ";";
      if (_.includes(data, "*")) delimiter = "*";
      console.log("- delimiter: ", delimiter);
      dataArr = data.split(delimiter);
      const orderId = parseInt(dataArr[1]);
      console.log("- orderId", orderId);
      setLoading(true);
      const shipment = await fetchListShipments({ orderId });
      console.log("shipment: ", shipment);
      queryClient.setQueryData(
        ["listShipments", { page, startDate, endDate }],
        (old) => {
          return { ...old, ...shipment };
        }
      );
      setLoading(false);
    });
  };

  //onBarcodeScanned hook
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      onBarcodeScanned();
      setLoading(false);
    }, 1000);

    return () => {
      socket.removeAllListeners(["on-barcode-scanned"]);
    };
    // eslint-disable-next-line
  }, []);

  const handleOnPageChange = ({ selected }) => {
    console.log("handleOnPageChange page selected: ", selected);
    setPage(selected + 1);
  };

  const printShippingLabel = (order) => {
    // console.log(order);
    const shippingLabelName = `${order.orderNumber}.pdf`;
    socket.emit("on-shipping-label-print", shippingLabelName);
  };

  const onPrintedShipLabel = () => {
    socket.on("on-printed-shipping-label", (result) => {
      console.log("on-printed-shipping-label result", result);
      toast.info(result.message, {
        position: "bottom-right",
      });
    });
  };

  useEffect(() => {
    onPrintedShipLabel();

    return () => {
      socket.removeAllListeners(["on-printed-shipping-label"]);
    };
  }, []);

  const handleSearch = async (searchTxt) => {
    console.log("* handleSearch init");
    console.log("- searchTxt", searchTxt);
    // e.preventDefault();

    console.log("- selectedSearchOption: ", selectedSearchOption);
    if (_.isEmpty(selectedSearchOption)) {
      alert("Select a search option");
      return;
    }

    if (!_.isEmpty(searchTxt)) {
      setLoading(true);
      const shipment = await fetchListShipments({
        [selectedSearchOption]: searchTxt,
      });
      console.log("shipment: ", shipment);
      queryClient.setQueryData(
        ["listShipments", { page, startDate, endDate }],
        (old) => {
          return { ...old, ...shipment };
        }
      );
      setLoading(false);
    }
  };

  return (
    <>
      <Title title={title} />
      <PageMainHeader title={title} user={user} settings={settings} />
      <ToastContainer theme="dark" />
      <section className="primary shipped-orders">
        {isLoading ? (
          <Skeleton count={20} height={50} circle={true} />
        ) : isError ? (
          <Msg msg={error?.message ? error.message : error} />
        ) : null}

        <PageMenuHeader>
          <DateRange
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            onClickHandler={refetch}
          />
          <div className="align-right_container">
            <Select
              className="search-select"
              onChange={(value) => {
                console.log("- onChange args:", value);
                setSelectedSearchOption(value);
              }}
              value={selectedSearchOption}
              placeholder="Search Options"
              aria-labelledby="Search options"
            >
              <Option value="orderNumber">Order Number</Option>
              <Option value="recipientName">Recipient Name</Option>
            </Select>

            {/* <div className='d-flex justify-content-center'>
              <select
                onChange={(e) => {
                  console.log(e.target.value);
                  setSelectedSearchOption(e.target.value);
                }}
                value={selectedSearchOption}
              >
                <option value="">==select a search option==</option>
                <option value="orderNumber">Order Number</option>
                <option value="recipientName">Recipient Name</option>
              </select>
            </div> */}
            <Search handleSearch={handleSearch} placeholderText="Search" />
          </div>
        </PageMenuHeader>
        {listShipments?.pages > 1 && (
          <Pagination
            handleOnPageChange={handleOnPageChange}
            totalDocs={listShipments.total}
            totalPages={listShipments.pages}
            page={page}
            limit={pageSize}
          />
        )}

        {listShipments && Boolean(listShipments.shipments.length) ? (
          <>
            <Banner className="mb-10">
              The print shipment label is available only when the shipping label
              is created in Printflo.
            </Banner>
            <ShippedOrderList
              shippedOrders={listShipments?.shipments}
              printShippingLabel={printShippingLabel}
            />
          </>
        ) : (
          <Banner>Not found</Banner>
        )}
        <ScrollTop />
      </section>
      <ModalPreloader modalPreloaderIsOpen={isFetching || loading} />
    </>
  );
}
