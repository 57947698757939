import React from "react";
import {Helmet} from "react-helmet-async";

const Title = ({ title }) => {
  const websiteName = process.env.REACT_APP_NAME;
  return (
    <Helmet>
      <title>{`${title}: ${websiteName}`}</title>
    </Helmet>
  );
};

export { Title };
