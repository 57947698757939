import React from 'react'
import ModalTemplate from 'components/modals/ModalTemplate'
import { FaSave } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Button from '@leafygreen-ui/button';

const descriptions = [
  "Inventory adjustment",
  "Defect",
  "Production input",
  "Production output",
  "Component input",
  "Component output",
  "Etc"
]

export default function ModalInventoryTransaction({ title, modalIsOpen, setModalIsOpen, addTransactionHandler, transaction, setTransaction }) {
  // console.log('* ModalInventoryTransaction init')
  // console.log('- transaction: ', transaction)

  const formRef = React.useRef()

  const handleOnChange = (e) => {
    // console.log(e.target.id, e.target.value);
    setTransaction({ ...transaction, [e.target.id]: e.target.value });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    // console.log("transaction in modal after submit", transaction);
    if (parseInt(transaction.quantity) === 0) {
      return toast.warn("* Quantity should be negative or positive number.", {
        position: 'bottom-right'
      });
    }

    addTransactionHandler(transaction);
  };
  return (
    <ModalTemplate
      title={title}
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}
      modalSize={{ width: "400px", height: "auto" }}
    >
      <form className="modal-form" ref={formRef}>
        <div className="input-field">
          <label htmlFor="sku">
            Quantity
          </label>
          <input
            type="number"
            id="quantity"
            value={transaction.quantity}
            onChange={handleOnChange}
            required
            autoFocus
          />
        </div>
        <div className="input-field">
          <label htmlFor="description">Description</label>
          <select value={transaction.description} onChange={handleOnChange} id="description">
            {descriptions.map((d, index) => {
              return <option key={index} value={d}>{d}</option>
            })}
          </select>
        </div>
        <div className="input-field col s12">
          <label htmlFor="inventoryArea">Inventory Area</label>
          <input
            type="text"
            id="inventoryArea"
            value={transaction?.inventoryArea ? transaction?.inventoryArea : ""}
            onChange={handleOnChange}
            required
            placeholder="Inventory Area"
          />
        </div>
        <div className="modal-footer">
          <Button variant='primary' onClick={submitHandler} className="swing-icon" leftGlyph={<FaSave />}>
            Submit
          </Button>
        </div>
      </form>
    </ModalTemplate>
  )
}
