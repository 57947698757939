import React from "react";
import _ from "lodash";
import { Table, TableHeader, Row, Cell } from '@leafygreen-ui/table';
import { FaCheck, FaPrint, FaQrcode, FaTimes } from "react-icons/fa";
import Banner  from '@leafygreen-ui/banner'
import Tooltip from "@leafygreen-ui/tooltip";
import IconButton from "@leafygreen-ui/icon-button";
import Button from '@leafygreen-ui/button'
import PageMenuHeader from "components/PageMenuHeader";

const PickingComponentsPF = ({ components, handlePrintLabel, filmInventoryAreaCodes, TotalPickupQty, handlePrintLabelByFlag }) => {
  console.log('* PickingComponentsPF init')
  // console.log("- components", components);

  const columns = [
    <TableHeader label="SKU" />,
    <TableHeader label="Inventory Location" />,
    <TableHeader label="Position" />,
    <TableHeader label="Printed Film Inventory Location" />,
    <TableHeader label="Ordered" dataType="number"/>,
    <TableHeader label="Label Printed(Picked)" dataType="number"/>,
    <TableHeader label="Status" />,
    <TableHeader label="Category" />,
    <TableHeader label="Color" />,
    <TableHeader label="Action" />
  ]

  return (
    <div className="collection-items collection">
      {
        components && Boolean(components.length) && (
          <div className="mt-10">
              <PageMenuHeader>
                <Button
                  variant="primary"
                  className="swing-icon"
                  onClick={() => handlePrintLabelByFlag({states:components, flag: "pf" })}
                  leftGlyph={<FaPrint/>}
                >
                  Print component(printed film) labels
                </Button>
                <Banner>
                  <TotalPickupQty states={components} />
                </Banner>
            </PageMenuHeader>
          </div>
        )
      }
      {!components ? (
        <p>loading...</p>
      ) : (
        <Table
          data={components}
          columns={columns}
        >
          {({datum}) => {
            // console.log('- datum: ', datum)
            const ordered = _.sumBy(datum?.packing_docs, 'quantity')
            const picked = _.sumBy(datum?.packing_docs, 'pickedQty')
            // const isMeta =  _.filter(datum?.packing_docs, doc => doc?.meta ).length > 0
            // console.log('- isMeta ', isMeta )
            return (
              <Row key={datum.sku}>
                <Cell>{datum?.sku ? datum.sku : "undefined*"}</Cell>
                <Cell>{datum?.inventoryArea ? datum.inventoryArea : ""}</Cell>
                <Cell>{datum?.gp ? datum.gp : ""}</Cell>
                <Cell>{
                  filmInventoryAreaCodes(datum?.packing_docs).map((a, index) => {
                    return <span key={index} className="blue each-inventory-area white-text">{a?.graphicFilename}: {a?.areaCode}, {a?.quantity}</span>
                  }) 
                }
                </Cell>
                <Cell>{ordered}</Cell>
                <Cell>{ordered < picked ? ordered : picked}</Cell>
                <Cell>
                  <span className="inline-block text-center" style={{minWidth: '30px'}}>
                    {
                      ordered <= picked
                        ? <FaCheck color="#09804C"/>
                        : <FaTimes color="#B1371F"/>
                    }

                  </span>
                </Cell>
                <Cell>{datum?.category ? datum.category : ""}</Cell>
                <Cell>{datum?.color ? datum.color : ""}</Cell>
                <Cell>
                    <Tooltip
                      align="left"
                      justify="start"
                      className='lg-tooltip-container'
                      darkMode={true}
                      trigger={
                        <IconButton
                          onClick={() => handlePrintLabel({ data: datum, flag: 'pf', from: 'action' })}
                          className="swing-icon"
                          aria-label="Print a label"
                        >
                          <FaQrcode />
                        </IconButton>}
                      triggerEvent="hover"
                    >
                      Print label(s)
                    </Tooltip>
                </Cell>
                
              </Row>
            )
          }}

        </Table>       
      )}
    </div>
  );
};

export default React.memo(PickingComponentsPF);

