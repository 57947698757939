import React from 'react'
import { FaFileDownload } from 'react-icons/fa'
import ModalTemplate from './ModalTemplate'
import Button from '@leafygreen-ui/button'

export default function ModalExportDialog({ title, modalIsOpen, setModalIsOpen, message, selectedExportMode, setSelectedExportMode, onConfirm }) {
  const onChangeHandler = (e) => {
    console.log('- e.target.value: ', e.target.value)
    setSelectedExportMode(e.target.value)
  }

  const onConfirmHandler = (e) => {
    e.preventDefault()
    setModalIsOpen(false)
    onConfirm(selectedExportMode)
  }
  
  return (
    <ModalTemplate
      title={title}
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}
      message={message}
      modalSize={{ width: "400px", height: "auto" }}
    >
      <form className="modal-form">
        <p className="ph-10">{ message }</p>
        <label className="label-with-radio">
          <input type="radio" name="current" value='retrieved' checked={selectedExportMode ==='retrieved'} onChange={onChangeHandler} />
          Current Page
        </label>
        <label className="label-with-radio">
          <input type="radio" name="all" value='all' checked={selectedExportMode==='all'} onChange={onChangeHandler} />
          All Data
        </label>
        <div className="modal-footer">
          <Button variant="primary" className="swing-icon" onClick={onConfirmHandler} leftGlyph={<FaFileDownload />}>
            Confirm
          </Button>
        </div>
      </form>
      
    </ModalTemplate>
  )
}
