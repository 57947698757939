import React, { useState, useEffect, useContext } from "react";
import { SettingsContext } from "contexts/SettingsContext";
import Skeleton from "react-loading-skeleton";
import { useQuery, useMutation, useQueryClient } from "react-query";
import socketIOClient from "socket.io-client";
import { jsonToCSV } from "react-papaparse";
import moment from "moment";
import _ from "lodash";
import {
  deleteGraphic,
  deleteGraphicByCondition,
  getGraphics,
  submitGraphic,
  searchGraphics,
} from "utils/graphics";
import ModalGraphic from "components/modals/ModalGraphic";
import Pagination from "utils/Pagination";
import { Title } from "utils/Title";
import { errorHandler } from "utils/errorHandler";
import GraphicsHeader from "./GraphicsHeader";
import GraphicsList from "./GraphicsList";
import PageMainHeader from "components/PageMainHeader";
import PageMenuHeader from "components/PageMenuHeader";
import { AuthContext } from "contexts/AuthContext";
import Banner from "@leafygreen-ui/banner";
import { ModalPreloader } from "utils/Preloader";
import ScrollTop from "utils/ScrollTop";
import Search from "components/Search";
import queryString from "query-string";

const title = "Graphics";
const socket = socketIOClient(process.env.REACT_APP_SOCKET_ENDPOINT);

const limit = 50;

const initialGraphics = {
  sku: "",
  graphicFileName: "",
  graphicPosition: "",
  size: { width: null, height: null },
  platen: "",
  imageUrl: "",
};

const useQueryOptions = {
  refetchOnWindowFocus: false,
  staleTime: 1000 * 60 * 1,
};

export default function Graphics({ location }) {
  const [loading, setLoading] = useState(false);
  const [selectedGraphic, setSelectedGraphic] = useState(initialGraphics);
  const [mode, setMode] = useState("create");
  const [msg, setMsg] = useState(null);
  const [page, setPage] = useState(1);
  const [selectedExportMode, setSelectedExportMode] = useState("retrieved");
  const { platens, media } = useContext(SettingsContext);
  const queryClient = useQueryClient();
  const [modalPreloaderIsOpen, setModalPreloaderIsOpen] = useState(false);
  const [graphicModalIsOpen, setGraphicModalIsOpen] = useState(false);
  const { settings } = useContext(SettingsContext);
  const { user } = useContext(AuthContext);
  const [sku, setSKU] = useState(null);
  const [searchTxt, setSearchTxt] = useState(null);
  const useARXfileRef = React.useRef(false);
  const usePolarisRecipeRef = React.useRef(false);
  const usePrintModeRef = React.useRef(false);

  const {
    data: graphics,
    isLoading,
    isError,
    error,
    isFetching,
    refetch,
  } = useQuery(
    ["graphics", page],
    async () =>
      await getGraphics({
        query: {},
        options: { page, limit },
      }),
    {
      ...useQueryOptions,
      enabled: false,
    }
  );

  useEffect(() => {
    console.log("* page hooks init");
    // console.log('-page:', page);
    // console.log('-searchTxt:', searchTxt);
    if (_.isNil(searchTxt)) {
      refetch();
    } else {
      fetchSearchResults.mutate(searchTxt);
    }
    // eslint-disable-next-line
  }, [page, searchTxt]);

  // on packing scan event
  const onBarcodeScanned = async () => {
    socket.on("on-barcode-scanned", async (data) => {
      console.log("- on-barcode-scanned: ", data);
      let dataArr = [];
      let delimiter = ";";
      if (_.includes(data, "*")) delimiter = "*";
      console.log("- delimiter: ", delimiter);
      dataArr = data.split(delimiter);
      const code = dataArr[0];
      console.log("- code:", code);
      setPage(1);
      setSearchTxt(code);
      fetchSearchResults.mutate(code);
    });
  };

  const onExportGraphicsResult = async () => {
    socket.on(
      "on-export-graphics-result",
      async ({ result, filePath, error }) => {
        console.log("- on-export-graphics-result: ", result, filePath, error);
        if (result && filePath) {
          setMsg(`* Graphics are successfully exported at ${filePath}`);
        } else {
          setMsg("Error occured, please contact Prontflo tech support.");
        }
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    setTimeout(() => {
      onBarcodeScanned();
    }, 1000);

    return () => {
      socket.removeAllListeners(["on-barcode-scanned"]);
    };
    // eslint-disable-next-line
  }, []);

  //onExportGraphicsResult hook
  useEffect(() => {
    setTimeout(() => {
      onExportGraphicsResult();
    }, 1000);

    return () => {
      socket.removeAllListeners(["on-export-graphics-result"]);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!_.isEmpty(location?.search)) {
      console.log("-location.search: ", location.search);
      const queryParams = queryString.parse(location.search);
      let code = queryParams.sku;
      setSKU(code);
      fetchSearchResults.mutate(code);
    }
    // eslint-disable-next-line
  }, [location, sku]);

  useEffect(() => {
    if (settings) {
      if(settings?.useARXfile) {
        useARXfileRef.current = settings?.useARXfile;
      }
      if(settings?.company?.toLowerCase().includes('wallflower')) {
        usePolarisRecipeRef.current = true;
      }
      if(settings?.usePrintMode) {
        usePrintModeRef.current = settings?.usePrintMode;
      }
    } 
  }, [settings]);

  const updateGraphic = useMutation(
    async ({ condition, update }) => {
      console.log("* updateGraphic init");
      return await submitGraphic({ condition, update });
    },
    {
      onError: (error) => {
        console.log("- updateGraphic error", error);
        setMsg(error);
      },
      onSuccess: (data) => {
        console.log("- updateGraphic onSuccsess data: ", data);
        setModalPreloaderIsOpen(false);
        if (searchTxt) {
          fetchSearchResults.mutate(searchTxt);
        } else {
          refetch();
        }
      },
    }
  );

  const handleOnPageChange = ({ selected }) => {
    // console.log("handleOnPageChange page", selected);
    setPage(selected + 1);
  };

  const showGraphicModal = (mode, g) => {
    console.log("* showGraphicModal init");
    console.log("- mode: ", mode);
    // console.log('- g: ', g)
    // console.log('- selectedGraphic: ', selectedGraphic)
    setMode(mode);
    if (msg) setMsg(null);
    if (mode === "create") {
      setSelectedGraphic(initialGraphics);
    } else {
      setSelectedGraphic(g);
    }
    setGraphicModalIsOpen(true);
  };

  const submitHandler = () => {
    console.log("* submitHandler init");
    // console.log("mode", mode);

    const { sku, graphicPosition } = selectedGraphic;

    updateGraphic.mutate({
      condition: {
        sku,
        graphicPosition,
      },
      update: selectedGraphic,
    });
  };

  const fetchSearchResults = useMutation(
    async (searchSKU) => {
      // console.log("searchSKU", searchSKU);
      msg && setMsg(null);
      setLoading(true);
      return await searchGraphics({
        searchSKU,
        options: { page, limit },
      });
    },
    {
      onSuccess: (results) => {
        console.log("- fetchSearchResults onSuccess results: ", results);
        queryClient.setQueryData(["graphics", page], () => {
          return { ...results };
        });
        setLoading(false);
      },
    }
  );

  const importGraphics = (parsedData) => {
    try {
      const confirm = window.confirm('SKU and Graphic position are mandatory fields. Please confirm those fields then click OK button to proceed.')
      if(!confirm) return;

      setLoading(true);
      console.log("- useARXFileRef.current: ", useARXfileRef.current);
      let tempGraphics = parsedData.map((item) => item.data);
      // tempGraphics = tempGraphics.slice(0, tempGraphics.length - 1);
      // console.log("tempGraphics", tempGraphics);
      for (let i = 0, len = tempGraphics.length; i < len; i++) {
        let elem = tempGraphics[i];
        if (elem?.size && typeof elem?.size === "string") {
          setMsg("The size field must be divided into height and width");
          setLoading(false);
          return;
        }
      }

      let msg = `Total number of graphics: ${tempGraphics.length}`;
      setMsg(msg);

      let i = 0;
      let len = tempGraphics.length - 1;

      async function loop() {
        // console.log(i, tempGraphics[i]);
        let graphic = tempGraphics[i];
        let sku = graphic.sku;
        let graphicPosition = graphic.graphicPosition;
        // let graphicFileName = graphic?.graphicFileName;
        // let arxFileName = graphic?.arxFileName;

        // console.log(`${i}/${len}, tempGraphics:`, graphic);

        try {
          // eslint-disable-next-line
          if (!_.isEmpty(sku) && !_.isEmpty(graphicPosition)) {
            await submitGraphic({
              condition: { sku, graphicPosition },
              update: tempGraphics[i],
            });
            // console.log("submitGraphic result", result);
          }

          setMsg(`${i + 1}/${tempGraphics.length}: ${sku} updated.`);
          i += 1;
        } catch (error) {
          console.log("- submitGraphic error", error);
          console.log("- submitGraphic error?.response", error?.response);
          const retval = errorHandler(error);
          console.log("- retval: ", retval);
          setMsg(`Error: ${retval} at ${sku}`);
          setLoading(false);
          return;
        }

        if (i <= len && _.has(tempGraphics[i], "sku")) {
          loop();
        } else {
          setMsg("* Completed import graphics, click refresh to load a page.");
          setLoading(false);
          return;
        }
      }

      if (i <= len) {
        loop();
      }
    } catch (error) {
      console.log("- importGraphics error", error);
      const retval = errorHandler(error);
      console.log("- retval: ", retval);
      setMsg(`Error: ${retval}`);
      setLoading(false);
    }
  };

  const exportGraphics = async () => {
    console.log("* exportGraphics init");
    setLoading(true);
    setMsg(null);
    // console.log(selectedExportMode);
    console.log("- ?useARXfile: ", settings?.useARXFile);
    let exportGraphics = [];

    if (selectedExportMode === "all") {
      setSelectedExportMode("retrieved");
      console.log("- socket.connected: ", socket.connected);
      if (_.isNil(searchTxt)) {
        if (socket.connected) {
          let message =
            "Exporting all graphics may take some minutes to complete depending on the amount of data. Upon completion, message will appear with file path.";
          let confirm = window.confirm(message);
          if (confirm) {
            setMsg("Downloading...");
            socket.emit("on-export-graphics");
          } else {
            setLoading(false);
          }
        } else {
          alert(
            "Exporting all graphics requires a local app. It seems that your station doesn't have a local app installed."
          );
        }
        return;
      } else {
        exportGraphics = await searchGraphics({
          searchSKU: searchTxt,
          options: { pagination: false },
        });
        if (exportGraphics?.totalDocs > 1000) {
          setLoading(false);
          alert(
            "The maximum number(1000) of exports has been exceeded. Large amounts of exports can hit the server timeout limit"
          );
          return;
        }
      }
    } else {
      exportGraphics = _.cloneDeep(graphics?.docs);
    }
    // console.log("exportGraphics", exportGraphics);
    if (_.has(exportGraphics, "docs")) exportGraphics = exportGraphics.docs;

    let fields = [
      "sku",
      "imageUrl",
      "graphicFileName",
      "graphicPosition",
      "ripEnv",
      "platen",
      "media",
      "size.width",
      "size.height",
    ];

    if (settings?.useARXfile) {
      fields.push("arxFileName");
    }

    if(usePrintModeRef.current) {
      fields.push("printMode");
    }

    if(usePolarisRecipeRef.current) {
      fields.push("polarisRecipe");
    }

    const flat = (obj, concatenator = ".") =>
      Object.keys(obj).reduce((acc, key) => {
        if (typeof obj[key] !== "object" || !obj[key]) {
          return {
            ...acc,
            [key]: obj[key],
          };
        }

        const flattenedChild = flat(obj[key], concatenator);

        return {
          ...acc,
          ...Object.keys(flattenedChild).reduce(
            (childAcc, childKey) => ({
              ...childAcc,
              [`${key}${concatenator}${childKey}`]: flattenedChild[childKey],
            }),
            {}
          ),
        };
      }, {});

    let flattenGraphics = exportGraphics.map((g) => flat(g));
    // console.log('-flattenGraphics: ', flattenGraphics)

    const result = jsonToCSV({
      fields,
      data: flattenGraphics,
    });
    // console.log('-jsonToCSV result: ', result);

    const element = document.createElement("a");
    const file = new Blob([result], {
      type: "text/csv",
    });
    element.href = URL.createObjectURL(file);
    element.download = `grahics_${moment().format("YYYYMMDDHHmm")}.csv`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    setLoading(false);
  };

  const handleSearch = async (searchSKU) => {
    console.log("* handleSearch init");
    console.log("- searchSKU", searchSKU);

    if (!_.isEmpty(searchSKU)) {
      if (page > 1) {
        alert("Requried to move page 1");
        return;
      }
      setSearchTxt(searchSKU);
      fetchSearchResults.mutate(searchSKU);
    }
  };

  const deleteHandler = useMutation(
    async () => {
      return await deleteGraphic(selectedGraphic._id);
    },
    {
      onSuccess: () => {
        if (searchTxt) {
          fetchSearchResults.mutate(searchTxt);
        } else {
          refetch();
        }
      },
    }
  );

  const handleDelete = () => {
    graphicModalIsOpen && setGraphicModalIsOpen(false);
    deleteHandler.mutate();
  };

  const batchDelete = (parsedData) => {
    console.log("* batchDelete init");
    try {
      setLoading(true);
      setMsg(null);
      let temp = parsedData.map((item) => item.data);
      let msg = `Total number of graphics for deleting: ${temp.length}`;
      setMsg(msg);
      let i = 0;
      let len = temp.length - 1;

      async function loop() {
        let sku = temp[i]?.sku && temp[i].sku.trim();
        let graphicPosition = temp[i]?.graphicPosition
          ? temp[i]?.graphicPosition.trim()
          : null;

        try {
          await deleteGraphicByCondition({
            condition: { sku, graphicPosition },
          });
          // console.log("deleteItemBySku result", result);
          setMsg(`${i + 1}/${temp.length}: ${sku} deleted.`);
          i += 1;
        } catch (error) {
          console.log("deleteGraphic error", error);
          const retval = errorHandler(error);
          setMsg(retval);
          return;
        }

        if (i <= len && _.has(temp[i], "sku")) {
          loop();
        } else {
          setMsg(
            "* Completed batch deletion of the graphics, click refresh to load a page."
          );
          setLoading(false);
          return;
        }
      }

      if (i <= len) {
        loop();
      }
    } catch (error) {
      const retval = errorHandler(error);
      console.log("- batchDelete error: ", retval);
      setMsg(retval);
    }
  };

  return (
    <>
      <Title title={title} />
      <PageMainHeader title={title} user={user} settings={settings} />
      <section className="primary">
        {isLoading ? (
          <Skeleton count={20} height={50} circle={true} />
        ) : isError ? (
          <Banner variant="danger">
            {error?.message ? error.message : error}
          </Banner>
        ) : (
          <>
            <PageMenuHeader>
              <GraphicsHeader
                showModal={showGraphicModal}
                importGraphics={importGraphics}
                selectedExportMode={selectedExportMode}
                setSelectedExportMode={setSelectedExportMode}
                exportGraphics={exportGraphics}
                handleSearch={handleSearch}
                batchDeleteHandler={batchDelete}
              />
              <Search handleSearch={handleSearch} />
            </PageMenuHeader>

            {graphics && (
              <Pagination
                handleOnPageChange={handleOnPageChange}
                totalDocs={graphics?.totalDocs}
                totalPages={graphics?.totalPages}
                page={page}
                limit={limit}
              />
            )}

            {msg && <Banner className="mb-10">{msg}</Banner>}
            {graphics && Boolean(graphics?.docs.length) && (
              <GraphicsList
                graphics={graphics.docs}
                setSelectedGraphic={setSelectedGraphic}
                showGraphicModal={showGraphicModal}
                initialGraphics={initialGraphics}
                useARXfile={useARXfileRef.current}
                media={media}
                usePolarisRecipe={usePolarisRecipeRef.current}
                usePrintMode={usePrintModeRef.current}
              />
            )}

            {searchTxt && !Boolean(graphics?.docs.length) && !loading && (
              <Banner>
                <span>Graphics not found.</span>
              </Banner>
            )}
          </>
        )}

        <ModalGraphic
          modalIsOpen={graphicModalIsOpen}
          setModalIsOpen={setGraphicModalIsOpen}
          title={"Graphic"}
          mode={mode}
          selectedGraphic={selectedGraphic}
          setSelectedGraphic={setSelectedGraphic}
          submitHandler={submitHandler}
          platens={platens}
          media={media}
          handleDelete={handleDelete}
          user={user}
          useARXfile={useARXfileRef.current}
          usePolarisRecipe={usePolarisRecipeRef.current}
          usePrintMode={usePrintModeRef.current}
        />

        <ModalPreloader
          modalPreloaderIsOpen={modalPreloaderIsOpen || loading || isFetching}
        />
        <ScrollTop />
      </section>
    </>
  );
}
