import React, {useEffect, useState} from 'react'
import Skeleton from 'react-loading-skeleton'
import { Title } from 'utils/Title'
import PageMainHeader from 'components/PageMainHeader'
import { AuthContext } from "contexts/AuthContext"
import { SettingsContext } from 'contexts/SettingsContext'
import Banner from '@leafygreen-ui/banner'
import PageMenuHeader from 'components/PageMenuHeader'
import moment from "moment";
import _ from "lodash";
import { getPackingItems, updateManyPackingItem  } from 'utils/packingItems';
import { updateOrder } from 'utils/orders'
import { axiosInstance as axios } from 'configs/axiosConfig'
import { jsonToCSV } from "react-papaparse";
import "react-toastify/dist/ReactToastify.css";
import { useMutation, useQuery, useQueryClient } from "react-query";
import FileImportExport from 'components/FileImportExport'
import { Table, TableHeader, Row, Cell } from '@leafygreen-ui/table'
import { FaBold, FaCheck, FaListAlt } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { ModalPreloader } from 'utils/Preloader'
import Button from '@leafygreen-ui/button'
import PackingListPrint from './PackingListPrint'
import Pagination from 'utils/Pagination'
import ScrollTop from 'utils/ScrollTop'
import { errorHandler } from 'utils/errorHandler'
import Search from 'components/Search'
import { Option, Select } from "@leafygreen-ui/select";

const title="Packing List";

const updateBin = async (data) => {
  // console.log("data", data);
  const url = `/bins/update`;
  axios.post(url, data)
    .then((res) => {
      Promise.resolve(res.data);
    })
    .catch((error) => Promise.reject(error));
};

const assignBin = async (orderId, volumn) => {
  let binCode;
  await axios.post(
    `/bins/find-empty-bin`,
    { volumn }
  )
    .then(async (res) => {
      console.log("- find-empty-bin", res.data);
      binCode = res.data.binCode;
      return await updateOrder({
        condition: { orderId },
        update: { $set: { bin: binCode, quantity: volumn } },
      });
    })
    .then(async (res) => {
      console.log("- updated order res", res);
      return await updateBin({
        condition: { binCode },
        update: { $set: { isActive: true } },
      });
    })
    .then((res) => {
      console.log("- upate bin res", res);
      Promise.resolve(true);
    })
    .catch((error) => {
      console.log("- assign bin error", error);
      Promise.reject(error);
    });
};

const pageSize = 50

export default function PackingList() {
  const [page, setPage] = React.useState(1);
  const { settings } = React.useContext(SettingsContext);
  const { user } = React.useContext(AuthContext);
  const [loading, setLoading] = React.useState(false);
  const [msg, setMsg] = React.useState(null);
  const [selectedExportMode, setSelectedExportMode] = React.useState("retrieved");
  const queryClient = useQueryClient();
  const [printStates, setPrintStates] = React.useState(null)
  const [, setSearchTxt] = useState(null);
  const [selectedSearchOption, setSelectedSearchOption] = useState("orderNumber");
  
  const { data: packingItems, isLoading, isError, error } = useQuery(
    ["packingitems", page],
    () => getPackingItems({page, limit: pageSize})
  );
  // console.log('- packingItems: ', packingItems)

  useEffect(() => {
    packingItems && console.log("* packingItems in hook: ");
    // console.log('- page: ', page)
    if (packingItems?.totalPages > page) {
      console.log('- prefetchQuery init')
      queryClient.prefetchQuery(
        ["packingitems", page + 1],
        () => getPackingItems({ page: page + 1, limit: pageSize}),
        {
          keepPreviousData: true,
          refetchOnWindowFocus: false,
          staleTime: 1000 * 60 * 5,
        }
      );
    }
    // eslint-disable-next-line
  }, [page, packingItems, queryClient]);


  useEffect(() => {
    getPackingItems()
      .then((results) => {
        setPrintStates(results)
      })
    // eslint-disable-next-line
  }, [])

  const exportPackingItems = async () => {
    console.log("* export packing items init");
    console.log("- selectedExportMode: ", selectedExportMode);
    let temp = []

    if (selectedExportMode === "all") {
      temp = await getPackingItems()
    } else {
      temp = _.cloneDeep(packingItems.docs);
    }
    console.log('- temp: ', temp)
    if(settings?.useManualOrderCreation) {
      //get orders which order items are empty, 
      let orderItemsNotExistOrders = [];
      await axios.get('/orders/fetch-manual-orders')
        .then(result => {
          if(Boolean(result?.data.length)) {
            orderItemsNotExistOrders = result.data
          }
        })
      console.log(orderItemsNotExistOrders)
      temp = temp.concat(orderItemsNotExistOrders)
    }

    console.log('- temp: ', temp)

    const selectedKeys = [
      "orderId",
      "orderNumber",
      "sku",
      "isBundle",
      "quantity",
      "sequence",
      "count",
      "checkedOutQty",
      "pickedQty",
    ];

    console.log(temp);
    const result = jsonToCSV({
      fields: selectedKeys,
      data: temp
    });
    // console.log(result);

    const element = document.createElement("a");
    const file = new Blob([result], {
      type: "text/csv",
    });
    element.href = URL.createObjectURL(file);
    element.download = `packingitems_${moment().format("YYYYMMDDHHmm")}.csv`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    setSelectedExportMode('retrieved');
  };

  const inActivatePackingItems = (orderIds) => {
    return new Promise((resolve, reject) => {
      console.log("orderIds inActivatePackingItems", orderIds);
      let i = 0;
      let len = orderIds.length - 1;

      async function loop() {
        let orderId = orderIds[i];

        try {
          await updateManyPackingItem({
            condition: { orderId },
            update: { $set: { isActive: false } },
          }).then((result) => {
            console.log("in-activate packingItem", i, orderId, result);
          });
          i += 1;
        } catch (error) {
          console.log("deletePackingItem error", error);
          reject(`Error occured, try again from this orderId: ${orderId}`);
        }

        if (i <= len) {
          loop();
        } else {
          setLoading(false);
          resolve({ message: "done" });
        }
      }

      if (i <= len) {
        loop();
      }
    });
  };

  const importPackingItems = async (parsedData) => {
    console.log('* importPackingItems init')
    setLoading(true);
    let tempItems = parsedData.map((item) => item.data);
    // console.log("tempItems", tempItems);
    //delete packing items from orderId of tempItems
    const tempGroupByOrderId = _.groupBy(tempItems, "orderId");
    console.log("- tempGroupByOrderId", tempGroupByOrderId);
    let orderIds = [];
    for (let orderId in tempGroupByOrderId) {
      orderIds.push(orderId);
      let groupedOrder = tempGroupByOrderId[orderId];
      const sumQty = _.sumBy(groupedOrder, (order) => {
        let qty = 0
        qty = qty + parseInt(order.quantity)
        return qty 
      });
      console.log("- groupedOrder", groupedOrder);
      console.log("- groupedOrder total qty", sumQty);

      // console.log(orderId, typeof orderId);
      // checking BIN and sumQty
      const found = _.find(printStates, { orderId: parseInt(orderId) });
      console.log("- found from p/i", found);
      if (found) {
        if (_.isNil(found?._order?.bin) && sumQty > 1) {
          //assign Bin to order and save bincode to bin
          await assignBin(orderId, sumQty)
            .then((res) => {
              console.log("assignBin result", res);
              setMsg(`Assigned Bin to ${orderId}`);
            })
            .catch((error) => {
              console.log("assignBin request error", error);
              setMsg(`Assign Bin error for ${orderId}`);
            });
        }

        if (!_.isNil(found?._order?.bin) && sumQty === 1) {
          await updateOrder({
            condition: { orderId },
            update: { bin: null, quantity: sumQty },
          }).then(async () => {
            await updateBin({
              condition: { binCode: found._order.bin },
              update: { $set: { isActive: false } },
            });
          });
        } 
      } else {
        if(sumQty > 1) {
          await assignBin(orderId, sumQty)
            .then((res) => {
              console.log("assignBin result", res);
              setMsg(`Assigned Bin to ${orderId}`);
            })
            .catch((error) => {
              console.log("assignBin request error", error);
              setMsg(`Assign Bin error for ${orderId}`);
            });
        } else {
          await updateOrder({
            condition: { orderId },
            update: { bin: null, quantity: sumQty },
          })
        }
      }
    }

    console.log("- after loop orderIds", orderIds);

    await inActivatePackingItems(orderIds)
      .then((result) => {
        console.log("inActivatePackingItems result", result);
        setMsg("In-Activating related packing items...");
      })
      .catch((error) => {
        console.log("inActivate PackingItems error", error);
        const retVal = errorHandler(error)
        setMsg(retVal);
        return;
      });

    setMsg(`Total number of packing items: ${tempItems.length}`);

    let i = 0;
    let len = tempItems.length - 1;

    async function loop() {
      console.log(i, tempItems[i]);
      let itm = tempItems[i];
      itm['orderItemId'] = Math.floor(Math.random()*10000000).toString()

      console.log(i, itm);

      try {
        const url = `/packing-items/import`;
        const result = await axios.post(url, { item: itm });

        setMsg(`${i + 1}/${tempItems.length}: ${result.data.sku} updated.`);
        i += 1;
      } catch (error) {
        console.log("submit item error", error);
        setMsg(`Error occured, try again from this sku: ${itm.sku}`);
        return;
      }

      if (i <= len && _.has(tempItems[i], "sku")) {
        loop();
      } else {
        setMsg("* Completed import packing items");
        setLoading(false);
        window.location.reload();
        return;
      }
    }

    if (i <= len) {
      loop();
    }
  };

  const printPackingList = async () => {
    console.log("* printPackingList init");
    const printSectionElem = document.getElementById("print-section");
    if(!printSectionElem) return;
    setLoading(true)
    const printContent = printSectionElem.innerHTML;

    const frame1 = document.createElement("iframe");
    frame1.name = "frame3";
    frame1.style.position = "absolute";
    frame1.style.top = "-100000000px";
    document.body.appendChild(frame1);

    const frameDoc = frame1.contentWindow
      ? frame1.contentWindow
      : frame1.contentDocument.document
        ? frame1.contentDocument.document
        : frame1.contentDocument;

    frameDoc.document.open();
    frameDoc.document.write("<html><head><title>Print Packing List</title>");
    frameDoc.document.write("<style>");
    frameDoc.document.write(
      "body,table{font-family:Arial,Helvetica,sans-serif;font-size:9pt}"
    );
    frameDoc.document.write(
      ".text-center{text-align:center}.text-right{text-align:right}"
    );
    frameDoc.document.write("table th{text-align:center}");
    frameDoc.document.write(
      "table{border-collapse:collapse;border:#666 .5px solid;width:100%}"
    );
    frameDoc.document.write(
      "table thead th, table tbody td{border:#666 .5px solid;padding:5px;}"
    );
    frameDoc.document.write(
      "table tbody tr.hidden{display: none;}"
    );
    frameDoc.document.write(".print-section_items{margin-top:10px;}");
    frameDoc.document.write("</style>");
    frameDoc.document.write('</head><body onafterprint="window.close()">');
    frameDoc.document.write(printContent);
    frameDoc.document.write("</body></html>");
    frameDoc.document.close();
    setTimeout(() => {
      window.frames["frame3"].focus();
      window.frames["frame3"].print();
      document.body.removeChild(frame1);
    }, 1000);
    setLoading(false)
  };

  const handleOnPageChange = ({ selected }) => {
    console.log("- handleOnPageChange init")
    // console.log('- selected: ', selected); 
    setPage(selected + 1);
  };

   const fetchSearchResults = useMutation(
    async (searchTxt) => {
      console.log("* fetchSearchResults init");
      console.log("- searchTxt: ", searchTxt);
      console.log("- selectedSearchOption: ", selectedSearchOption);
      setLoading(true);
      let args = {
        page, 
        limit: 50,
      }
      if(selectedSearchOption==='sku') {
        args['sku'] = searchTxt
      } else if(selectedSearchOption==='orderNumber'){
        args['orderNumber'] = searchTxt
      }
      // console.log('- args: ', args)
      return await getPackingItems({...args});
    },
    {
      onError: (error) => {
        const retval = errorHandler(error);
        setMsg(retval);
        setLoading(false);
      },
      onSuccess: (result) => {
        console.log("- fetchSearchResults onSuccess result: ", result);
        queryClient.setQueryData(["packingitems", page], () => {
          return { ...result };
        });
        // setLimit(result?.limit)
        setLoading(false);
      },
    }
  );

  const handleSearch = async (searchTxt) => {
    console.log("* handleSearch init");
    console.log("searchTxt", searchTxt);
    if (!_.isEmpty(searchTxt)) {
      if (page > 1) {
        alert("Requried to move page 1");
        return;
      }
      setSearchTxt(searchTxt);
      fetchSearchResults.mutate(searchTxt);
    }
  };

  return <>
      <Title title={title} />
      <PageMainHeader 
        title={title} 
        user={user} 
        settings={settings} 
      />
      <section className="primary">
        {isLoading ? (
          <Skeleton count={20} height={50} circle={true} />
        ) : isError ? (
          <Banner variant='danger'>{error?.message ? error.message : error}</Banner>
        ) : (
          null
        )}
        <PageMenuHeader>
          <div className='d-flex'>
            <Button
              variant="primaryOutline"
              className="swing-icon"
              onClick={printPackingList}
              leftGlyph={<FaListAlt />}
            >
              Print Packing List
            </Button>
            <FileImportExport 
              importHandler={importPackingItems}
              exportHandler={exportPackingItems}
              selectedExportMode={selectedExportMode}
              setSelectedExportMode={setSelectedExportMode}
            />
          </div>
          <div className="align-right_container">
            <Select
              className="search-select"
              onChange={(value) => {
                console.log("- onChange args:", value);
                setSelectedSearchOption(value);
              }}
              value={selectedSearchOption}
              placeholder="Search Options"
              aria-labelledby="Search options"
            >
              <Option value="orderNumber">Order Number</Option>
              <Option value="sku">SKU</Option>
            </Select>
            <Search handleSearch={handleSearch} />
          </div>

        </PageMenuHeader>

        {packingItems?.totalPages > 1 && (
          <Pagination 
            handleOnPageChange={handleOnPageChange} 
            totalDocs={packingItems.totalDocs} 
            totalPages={packingItems.totalPages} 
            page={page} 
            limit={pageSize}
          />
        )}

        {msg && (
          <Banner className='mb-10'>{msg}</Banner>
        )}

        {packingItems?.docs && 
          <Table
            data={packingItems.docs}
            columns={[
              <TableHeader label="Status" />,
              <TableHeader label="Bin" />,
              <TableHeader label="Order Number" />,
              <TableHeader label="SKU" />,
              <TableHeader label="Pretreatment Barcdoe"  className={settings?.usePretreatmentBarcode ? "" : 'hidden'}/>,
              <TableHeader label="Ordered" />,
              <TableHeader label="Picked" />,
              <TableHeader label="Printed" />,
              <TableHeader label="Checked Out" />,
              <TableHeader label="Order Date" />,
              <TableHeader label="Synced At" />,
              // <TableHeader label="QR Code" />,
            ]}
          >
            {({datum}) => (
              <Row key={datum._id}>
                <Cell>
                  {datum.quantity === datum.checkedOutQty && (
                    <FaCheck />
                  )}
                </Cell>
                <Cell>
                  {datum?._order?.bin ? datum._order.bin : ""}
                </Cell>
                <Cell>
                  <Link to={`workorder-details/${datum?.orderId}`}>
                    <span className='block'>
                      {datum.orderNumber}
                      {datum.isBundle && (
                        <span><FaBold size="10px" color="#007CAD" className='ml-10' /></span>
                      )}
                    </span>
                  </Link>

                  <span className="block">
                    {datum.sequence} / {datum.count}
                  </span>

                </Cell>
                <Cell>{datum?.sku ? datum.sku : ""}</Cell>
                <Cell className={settings?.usePretreatmentBarcode ? "" : 'hidden'}>{datum?._item?.pretreatmentBarcode ? datum._item.pretreatmentBarcode : ""}</Cell>
                <Cell>{datum?.quantity ? datum.quantity : 0}</Cell>
                <Cell>{datum?.pickedQty ? datum.pickedQty : 0}</Cell>
                <Cell>{datum?.printed ? _.sumBy(datum.printed, "qty") : 0}</Cell>
                <Cell>{datum?.checkedOutQty ? datum.checkedOutQty : 0}</Cell>
                <Cell>{datum?._order?.orderDate ? moment(datum._order.orderDate).format("MM-DD-YYYY") : ""}</Cell>
                <Cell>{datum?.createdAt ? moment(datum.createdAt).format("MM-DD-YYYY") : ""}</Cell>
                {/* <Cell>{<QRCode value={datum.sku+'*'+datum.orderId} size={50} level='Q'/>}</Cell> */}
              </Row>
            )}
          </Table>
        }
        {printStates && <PackingListPrint packingItems={printStates} />}
        <ModalPreloader modalPreloaderIsOpen={loading} />
        <ScrollTop />
        {/* <pre>{JSON.stringify(printStates, null, 2)}</pre> */}

      </section>
    </>
}
